/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { GrAttachment } from "react-icons/gr";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import Vcardparser from "vcardparser";
import Skeleton from "react-loading-skeleton";
import Emoji from "react-emoji-render";
import { Lightbox } from "react-modal-image";
import moment from "moment";
import ReactPaginate from "react-paginate";
import useQuery from "../../core/useQuery";
import api from "../../service/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { GlobalState } from "../../state/state";
import { getToken } from "../../service/auth";
import decoded from "jwt-decode";
import "react-datepicker/dist/react-datepicker.css";
import el from "date-fns/locale/pt-BR";
import "./style.css";
registerLocale("el", el);

export default function Respostas({ context, history }) {
  const [loading, setLoading] = useState(true);
  const [busca, setbusca] = useState("");
  const [de, setde] = useState(moment().subtract(2, "M").toDate());
  const [ate, setate] = useState(moment().endOf("d").toDate());
  const [lista, setlista] = useState([]);
  const [zap] = GlobalState("zap");
  const query = useQuery();
  const limit = 15;
  const skip = parseInt(query.get("page") || 0) * limit;
  const [pageCount, setPageCount] = useState(0);
  const [total, settotal] = useState(0);
  const token = getToken();
  const usuario = decoded(token);
  const [modalImageVisible, setModalImageVisible] = useState(false);
  const [urlImage, setUrlImage] = useState("");

  async function getChatContato(contato, zapResposta){
    const response = await api.post('/v1/chat/getChatContato',{ zap: zapResposta , contato });
    history.push(`/chat/${response.data._id}`)
  }

  async function fetchData() {
    const response = await api.post(`/v1/respostas/list`, {
      de: moment(de).toDate(),
      ate: moment(ate).toDate(),
      busca,
      skip,
      limit,
      zap: zap && zap._id ? zap._id : null,
    });
    setPageCount(response.data.total / limit);
    setlista(response.data.data);
    settotal(response.data.total);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zap, skip]);

  function fetchPagination(data) {
    history.push(`/respostas?page=${data.selected || 0}`);
  }

  function HandleSearch() {
    fetchData();
  }

  function mensagem(el) {
    if (el.type === "vcard") {
      let vcard = "";
      Vcardparser.parseString(el.mensagem, (err, json) => {
        if (err) return console.log(err);
        console.log(json);
        vcard += `*CONTATO* ${json.fn} ${json.tel[0].value}`;
      });
      return <Emoji text={vcard} />;
    }
    if (el.type === "ptt") {
      return (
        <ReactAudioPlayer
          src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
          controls
        />
      );
    }

    if (el.type === "image") {
      if (el.quotedType === "chat")
        return (
          <div className="row">
            <div className="col-6">
              <Emoji text={el.quotedMsg} />
            </div>
            <div className="col-6">
              <Emoji text="*Resposta*" />
              <br />
              <a
                style={{ cursor: "pointer" }}
                rel="noreferrer"
                onClick={() => {
                  setUrlImage(
                    `${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`
                  );
                  setModalImageVisible(true);
                }}
                alt="img"
              >
                <img
                  style={{ height: 200 }}
                  alt="img"
                  className="img-thumbnail rounded"
                  src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
                ></img>
              </a>
            </div>
          </div>
        );
      return (
        <a
          style={{ cursor: "pointer" }}
          rel="noreferrer"
          onClick={() => {
            setUrlImage(
              `${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`
            );
            setModalImageVisible(true);
          }}
          alt="img"
        >
          <img
            style={{ height: 200 }}
            alt="img"
            className="img-thumbnail rounded"
            src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
          ></img>
        </a>
      );
    }

    if (el.type === "document") {
      return (
        <a
          rel="noreferrer"
          target="_blank"
          href={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
        >
          <span>
            <GrAttachment className="mr-1" size={18} />
          </span>
          {el.originalname ? el.originalname : el.filename}
        </a>
      );
    }

    if (el.type === "audio") {
      return (
        <ReactAudioPlayer
          src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.file ? el.file : el.filename}&folder=arquivosWhats`}
          controls
        />
      );
    }

    if (el.type === "video") {
      return (
        <ReactPlayer
          width="200px"
          controls
          url={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
        />
      );
    }

    if (el.type === "sticker") {
      return (
        <a
          rel="noreferrer"
          href={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
          target="_blank"
          alt="img"
        >
          <img
            style={{ height: 150 }}
            alt="img"
            className="img-thumbnail rounded"
            src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
          ></img>
        </a>
      );
    }

    if (el.type === "location") {
      return (
        <a
          rel="noreferrer"
          href={`https://www.google.com/maps?q=${el.lat},${el.lng}&z=17&hl=pt-BR`}
          target="_blank"
          alt="img"
        >
          <img
            style={{ height: 150 }}
            alt="img"
            className="img-thumbnail rounded"
            src={`data:image/jpeg;base64,${el.mensagem}`}
          ></img>
        </a>
      );
    }

    if ((el.type === "chat" || el.type === undefined) && el.mensagem) {
      if (el.quotedType === "image")
        return (
          <div className="row">
            <div className="col-6">
              <a
                style={{ cursor: "pointer" }}
                rel="noreferrer"
                onClick={() => {
                  setUrlImage(
                    `${process.env.REACT_APP_API_URL}/upload?mimetype=${el.quotedMimetype}&filename=${el.quotedFilename}&folder=arquivosWhats`
                  );
                  setModalImageVisible(true);
                }}
                alt="img"
                className="mr-1"
              >
                <img
                  style={{ height: 200 }}
                  alt="img"
                  className="img-thumbnail rounded"
                  src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.quotedMimetype}&filename=${el.quotedFilename}&folder=arquivosWhats`}
                ></img>
              </a>
            </div>
            <div className="col-6">
              <Emoji text={`*Resposta* ${el.mensagem}`} />
            </div>
          </div>
        );
      return (
        <>
          <Emoji text={el.mensagem} />
          {el.link && (
            <>
            <br/>
            <a href={el.link}>{el.link}</a>
            </>
          )}
        </>
      );
    }
  }

  function closeModalImage() {
    setModalImageVisible(false);
    setUrlImage("");
  }

  function handleDownload() {
    window.open(`${process.env.REACT_APP_API_URL}/v1/respostas/download/${usuario._id}?token=${token}&zap=${zap}&busca=${busca}&de=${de}&ate=${ate}`);
  }

  return (
    <>
      {modalImageVisible && urlImage && (
        <Lightbox medium={urlImage} hideZoom={true} onClose={closeModalImage} />
      )}
      <div id="respostas">
        <div className="container-fluid">
          <div className="row" title="respostas">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop texto="Respostas" title="respostas" context={context} />
              <div className="area_body">
                <div className="row" id="buscas">
                  <div className="col-sm-12 col-md-3 mt-2 mb-2">
                    <label>Contato</label>
                    <input
                      type="text"
                      value={busca}
                      onChange={(e) => setbusca(e.target.value)}
                      onKeyPress={(e) => e.key === "Enter" && HandleSearch()}
                      className="input-search-white mt-1"
                      placeholder="Pesquise um nome ou número"
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 mt-2 mb-2 customDatePickerWidth">
                    <label>A partir de</label>
                    <DatePicker
                      locale="el"
                      selected={de}
                      placeholderText="Selecione uma data"
                      className="input-search-white mt-1"
                      dateFormat="Pp"
                      showTimeSelect
                      onChange={(date) => setde(date)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 mt-2 mb-2 customDatePickerWidth">
                    <label>Até</label>
                    <DatePicker
                      locale="el"
                      selected={ate}
                      placeholderText="Selecione uma data"
                      className="input-search-white mt-1"
                      dateFormat="Pp"
                      showTimeSelect
                      onChange={(date) => setate(date)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 mt-2 mb-2 d-flex">
                    <button
                      onClick={HandleSearch}
                      className="btn-main mt-auto w-100"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-6 mt-2 mb-1">
                    <label>Mostrando todas as {total} respostas</label>
                  </div>
                  <div className="col-sm-12 col-md-6 mt-2 mb-1">
                    <button
                      className="btn-main float-right"
                      onClick={handleDownload}
                    >
                      <AiOutlineCloudDownload className="mr-2 position-relative AiOutlineCloudDownload" size={18} />
                      Fazer download de todas as respostas
                    </button>
                  </div>
                </div>
                <div className="row" id="tabela">
                  <div className="col-sm-12 col-md-12 mt-2 mb-1 ">
                    <div className="card-content table-responsive">
                      <table className="table table-hover" id="lista">
                        <thead>
                          <tr>
                            <th scope="col">DATA</th>
                            <th scope="col">NOME</th>
                            <th scope="col">TELEFONE</th>
                            <th scope="col" width="50%">
                              RESPOSTA
                            </th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {lista && lista.map(function (el, i) {
                            return (
                              <tr key={el._id}>
                                <td className="pointerTable">
                                  {el.data
                                    ? moment(el.data).format(
                                        "DD/MM/YYYY HH:mm:ss"
                                      )
                                    : "Sem Data"}
                                </td>
                                <td className="pointerTable">
                                  {el.contato && el.contato.nome
                                    ? el.contato.nome
                                    : "Sem Nome"}
                                </td>
                                <td className="pointerTable">
                                  {el.contato && el.contato.telefone
                                    ? el.contato.telefone
                                    : "Sem telefone"}
                                </td>
                                <td
                                  className="pointerTable"
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {mensagem(el)}
                                </td>
                                <td>{el.contato && (
                                  <label className="detalhes" onClick={() => getChatContato(el.contato, el.zap)}>
                                  Responder
                                  </label>
                                )} 
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                      {lista.length > 0 && (
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakClassName="page-item disabled"
                          breakLinkClassName="page-link"
                          disabledClassName="disabled"
                          pageCount={pageCount}
                          pageClassName="page-item"
                          previousClassName="page-item"
                          pageLinkClassName="page-link"
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                          activeClassName="active"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
