import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

export default function ModalConfig({
  modalVisible,
  setmodalVisible,
  config,
  setconfig,
}) {
  return (
    <Modal
      open={modalVisible}
      onClose={() => setmodalVisible(false)}
      styles={customStyles}
      center
    >
      <form>
        <div id="config">
          <div className="mr-5 mb-3">
            <h3 className="colorBackground mb-3">Configuração envio</h3>
          </div>
          <div className="container-fluid mb-3 mt-4">
            <div className="row mt-2">
              <label>Avisar com antecedência de quantos dias</label>
              <input
                type="number"
                name="aviso"
                value={config.aviso}
                onChange={(e) =>
                  setconfig({ ...config, aviso: e.target.value })
                }
                className="inputPadrao"
                placeholder="Avisar com antecedência de quantos dias"
              />
            </div>
            <div className="row mt-2">
              <label>Mensagem</label>
              <textarea
                value={config.mensagem}
                onChange={(e) =>
                  setconfig({ ...config, mensagem: e.target.value })
                }
                placeholder="Exemplo: Olá [nome] segue seu boleto"
                rows={10}
                className="textarea-form mb-3"
                name="mensagem"
              />
            </div>
          </div>
          <hr />
          <button
            type="button"
            className="btn-main float-right"
            onClick={() => setmodalVisible(false)}
          >
            Fechar
          </button>
        </div>
      </form>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    minWidth: "100px",
    width: "500px",
  },
};
