import React, { useEffect, useRef, useState } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import Select from "react-select";
import { toast } from "react-toastify";
import { GlobalState } from "../../state/state";
import TextArea from "../../components/TextArea";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { Form } from "@unform/web";
import Input from "../../components/Input";
import api from "../../service/api";
import Skeleton from "react-loading-skeleton";

export default function AddEditObras({ context, match, history }) {
  const formRef = useRef(null);
  const scroll = useRef(null);
  const [loading, setLoading] = useState(false);
  const [zaps, setzaps] = useState([]);
  const [zap, setzap] = GlobalState("zap");
  const [msg, setmsg] = useState("");
  const [contatos, setcontatos] = useState([]);
  const [listContatos, setListContatos] = useState([]);
  const [result, setresult] = useState([]);

  const [busca, setbusca] = useState("");
  const [listTags, setListTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [limit, setlimit] = useState(350);
  const [totalBusca, settotalBusca] = useState(0);
  const [obra, setobra] = useState(null);

  async function HandleSaveObra(data) {
    formRef.current.setErrors([]);
    if (!result.length) {
      toast.error("Selecione pdf para o envio!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (!contatos.length) {
      toast.error("Selecione contatos para o envio!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    data.mensagem = msg;
    const schema = Yup.object().shape({
      nome: Yup.string().required("Nome é obrigatório"),
      mensagem: Yup.string().required("Mensagem é obrigatório"),
    });

    await schema.validate(data, {
      abortEarly: false,
    });

    data.arquivos = result;
    data.contatos = contatos.map(el => el._id);
    if (obra === null) {
      const response = await api.post("/v1/obras", data);
      if (!response.data.success) {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }
    } else {
      const response = await api.put(`/v1/obras/${obra._id}`, data);
      if (!response.data.success) {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }
    }
    toast.success("Obra gerada com sucesso!", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    setresult([]);
    setcontatos([]);
    context.updateState();
    history.push("/obras");
  }

  async function uploadFiles(values) {
    const dataEnvio = new FormData();
    dataEnvio.append("folder", "obras");
    values.map((file) => {
      return dataEnvio.append("files", file);
    });

    dataEnvio.append("arquivos", JSON.stringify(values));
    const response = await api.post("/v1/upload/files", dataEnvio, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!response.data.success && !response.data.files) {
      return toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    setresult(result.concat(response.data.files));
    setLoading(false);
  }

  function selecionadosControl(el, e) {
    if (contatos.map((el) => el._id).indexOf(el._id) > -1 && !e.target.checked)
      contatos.splice(contatos.map((el) => el._id).indexOf(el._id), 1);
    else contatos.push(el);
    if (contatos.length > 149) {
      toast.error("Maximo 150 contatos", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    setcontatos([...contatos]);
  }

  async function fetchData() {
    if (context && context.usuario && context.usuario.tags) {
      let tags = context.usuario.tags.map((e) => {
        return { value: e, label: e };
      });
      tags.unshift({ value: null, label: "Nenhuma TAG" });
      setListTags(tags);
    }
    if (context && context.zaps) {
      setzaps(context.zaps);
    }

    if (match.params.id) {
      const response = await api.get(`/v1/obras/${match.params.id}`);
      setobra(response.data);
      setTimeout(function () {
        formRef.current.setData({
          nome: response.data.nome,
        });
        setmsg(response.data.mensagem);
        setresult(response.data.arquivos);
        setcontatos(response.data.contatos);
      }, 300);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    async function fetchDataContatos() {
      const responseContatos = await api.post(`/v1/contatos/list`, {
        campanha: true,
        busca,
        tags: tags ? tags.value : [],
        limit,
        zap: zap ? zap._id : null,
      });
      settotalBusca(responseContatos.data.total);
      setListContatos(responseContatos.data.data);
      setLoading(false);
    }
    fetchDataContatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, zap, busca, tags]);

  return (
    <div id="obras">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-sm-12 col-lg-2 mb-5">
            <Menu className="positionSticky" context={context} />
          </div>
          <div className="col-sm-12 col-lg-10">
            <AreaTop
              texto={obra && obra.nome ? "Editar Obra" : "Adicionar nova obra"}
              title="Obras"
              context={context}
            />
            <div className="area_body">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <label>Número vinculado</label> <br />
                  <label>
                    {zap
                      ? zap.telefone
                      : "Sem número vinculado"}
                  </label>
                </div>
                <div className="col-sm-12 col-md-6">
                  {(zaps.length > 2 || !zap) && (
                    <>
                      <label>Selecionar Zap</label>
                      <Select
                        placeholder="Selecione um número"
                        value={zap}
                        defaultValue={zap}
                        onChange={(e) => setzap(e)}
                        options={zaps}
                      />
                    </>
                  )}
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <Form
                    className="mt-3"
                    ref={formRef}
                    onSubmit={HandleSaveObra}
                  >
                    <div className="mt-2 mb-2">
                      <Input
                        type="text"
                        className="input-form"
                        name="nome"
                        placeholder="Digite o nome da obra"
                        label="Titulo"
                      />
                    </div>
                    <div className="mt-2 mb-2">
                      <TextArea
                        label="Mensagem"
                        value={msg}
                        onChange={(e) => setmsg(e.target.value)}
                        placeholder=""
                        rows={4}
                        className="textarea-form"
                        name="mensagem"
                      />
                    </div>
                    <div className="mt-2 mb-2">
                      <label>Arquivo</label>
                      <Dropzone onDrop={uploadFiles}>
                        {({ getRootProps, getInputProps }) => (
                          <section className="bordernone mb-3">
                            <div
                              className="bordernone mb-3"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div
                                id="boletos"
                                className="arrasteAqui text-center"
                              >
                                <p>Arraste ou selecione os arquivos</p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>

                      <div className="mt-2 mb-2">
                        <label>Arquivos selecionados -</label>
                        <small>
                          <b>
                            {" "}
                            Verifique os arquivos e no final da página selecione
                            o botão Salvar{" "}
                          </b>
                        </small>
                      </div>
                      <div className="mt-2 mb-2">
                        <div id="preview" className="card-content mb-2">
                          {result.map((e, i) => {
                            return (
                              <>
                                {e.mimetype === "image/jpeg" ||
                                e.mimetype === "image/png" ? (
                                  <img
                                    style={{ height: 200 }}
                                    alt="img"
                                    className="img-thumbnail rounded"
                                    src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${e.mimetype}&filename=${e.filename}&folder=obras`}
                                  />
                                ) : (
                                  <>
                                    <a
                                      href={`${process.env.REACT_APP_API_URL}/upload?mimetype=${e.mimetype}&filename=${e.filename}&folder=obras`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {e.originalname}
                                    </a>
                                    <br />
                                  </>
                                )}
                              </>
                            );
                          })}
                          {loading && <Skeleton count={10} />}
                        </div>
                      </div>
                    </div>
                    <label className="mt-2">CONTATOS</label>
                    <div className="row mb-2 mt-2">
                      <div className="col-12 col-md-7">
                        <input
                          className="input-search-white"
                          value={busca}
                          onChange={(e) => setbusca(e.target.value)}
                          placeholder="Pesquise um nome ou número"
                        />
                      </div>
                      <div className="col-12 col-md-3">
                        <Select
                          placeholder="Selecione tag"
                          className="select-menu"
                          value={tags}
                          defaultValue={tags}
                          onChange={(e) => {
                            setTags(e);
                          }}
                          options={listTags}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div
                          className="list"
                          ref={scroll}
                          onScroll={() => {
                            if (
                              scroll.current.scrollHeight -
                                scroll.current.scrollTop <
                                900 &&
                              limit < totalBusca
                            ) {
                              setlimit(limit + 150);
                            }
                          }}
                        >
                          {listContatos.map((el, i) => {
                            return (
                              <div key={i} className="form-check mt-1 mb-3">
                                <input
                                  className="form-check-input"
                                  checked={
                                    contatos
                                      .map((el) => el._id)
                                      .indexOf(el._id) > -1
                                  }
                                  onChange={(e) => selecionadosControl(el, e)}
                                  type="checkbox"
                                  id={`check-contatos-${i}`}
                                />
                                <label
                                  className="form-check-label naoSelecionavel ml-2"
                                  htmlFor={`check-contatos-${i}`}
                                >
                                  {el.seq} -{" "}
                                  {el.telefone
                                    ? el.telefone
                                    : "Sem telefone"}{" "}
                                  <small>{el.nome}</small>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="list">
                          {contatos.map((el, i) => {
                            return (
                              <div key={i} className="mt-1 mb-1">
                                <label>
                                  {el.seq} -{" "}
                                  {el.telefone
                                    ? el.telefone : "Sem telefone"}{" "}
                                  <small>{el.nome}</small>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <button type="submit" className="btn-main float-right">
                      Salvar Obra
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
