import React from "react";
import "./style.css";
export default function Preview({ preview, file, remover, removerMsg }) {
  return (
    <div className="card-content">
      <span>PRÉVIA</span>
      <span className="float-right">
        {removerMsg && (
          <button type="button" className="btn btn-sm btn-danger mr-2" onClick={() => removerMsg()}>Remover Mensagens</button>
        )}
        <b>{preview.length} mensagens</b>
      </span>
      <br /> <br />
      {preview.length > 0 && file && (
        <div className="table-responsive showpreview">
          <table className="table">
            <thead>
              <tr className="text-center">
                <th scope="col">Contato</th>
                <th scope="col">Mensagem</th>
                {remover && <th scope="col">Opções</th>}
              </tr>
            </thead>
            <tbody>
              {preview.map((el, i) => {
                return (
                  <tr key={el._id}>
                    <td className="text-center" title={el.contato.nome}>
                      {el.contato.nome ? el.contato.nome : el.contato.telefone}
                    </td>
                    <td className="text-center">
                      {el.mensagem}
                      {el.arquivo && (
                        <>
                          {el.arquivo.mimetype === "image/jpeg" ||
                          el.arquivo.mimetype === "image/png" ? (
                            <>
                              <br />
                              <img
                                style={{ height: 200 }}
                                alt="img"
                                className="img-thumbnail rounded"
                                src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.arquivo.type}&filename=${el.arquivo.filename}&folder=arquivosWhats`}
                              />
                            </>
                          ) : (
                            <>
                              <br />
                              <p>{el.arquivo.originalname}</p>
                            </>
                          )}
                        </>
                      )}
                    </td>
                    <td className="text-center">
                      {remover && (
                        <button
                          type="button"
                          onClick={() => remover(el._id)}
                          className="btn btn-sm btn-danger"
                        >
                          Remover
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
