/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState, useCallback} from 'react';
import './style.css';
import Menu from '../../components/Menu/index';
import AreaTop from '../../components/AreaTop/index';
import {
  AiOutlineCloudDownload,
} from "react-icons/ai";
import moment from "moment";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import useQuery from "../../core/useQuery";
import { confirmAlert } from "react-confirm-alert";
import api from "../../service/api";
import Skeleton from "react-loading-skeleton";
import { GlobalState } from "../../state/state";
import ModalContatosAdicionais from "./ModalContatosAdicionais";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-dropdown/style.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import el from "date-fns/locale/pt-BR";
import { getToken } from "../../service/auth";
import { Link } from 'react-router-dom';
import StatusComponent from '../../components/Status'
registerLocale("el", el);

export default function Campanhas({context, history}) {
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const [busca, setbusca] = useState('');
  const [lista, setlista] = useState([]);
  const [zap] = GlobalState('zap');
  const query = useQuery();
  const limit = 30;
  const skip = parseInt(query.get('page') || 0) * limit;
  const [pageCount, setPageCount] = useState(0);
  const [filtro, setfiltro] = useState('');
  const [selecionados, setselecionados] = useState([]);
  const [data_de, setdata_de] = useState(moment().subtract(1, 'M').startOf('D').toDate());
  const [data_ate, setdata_ate] = useState(moment().endOf('D').toDate());
  const [addcontmodal, setaddcontmodal] = useState(false);
  const [c, setc] = useState(null);

  const fetchData = useCallback(async () => {
    const initoday = moment().startOf('d').toDate();
    const endtoday = moment().endOf('d').toDate();
    const dateViaBase = moment().startOf('y').toDate();

    const response = await api.post(`/v1/campanhas/list`, {
      finalizado: filtro && filtro === 'concluidas' ? true : false,
      ready:
        filtro && (filtro === 'agendadas' || filtro === 'atrasada')
          ? true
          : filtro === 'notsync'
          ? false
          : '',
      viabase: filtro && filtro === 'viabase' ? true : '',
      totalEnviado:
        filtro &&
        (filtro === 'agendadas' || filtro === 'notsync' || filtro === 'atrasada')
          ? true
          : false,
      totalFalhas:
        filtro &&
        (filtro === 'agendadas' || filtro === 'notsync' || filtro === 'atrasada')
          ? true
          : false,
      data_de:
        filtro && (filtro === 'agendadas' || filtro === 'notsync' || filtro === 'atrasada', filtro === 'iniciado')
          ? initoday
          : filtro === 'viabase' ? dateViaBase : moment(data_de).toDate(),
      data_ate: filtro && (filtro === 'notsync' || filtro === 'atrasada', filtro === 'iniciado')
      ? endtoday
      : moment(data_ate).toDate(),
      atrasada: filtro === 'atrasada' ? true : false,
      zap: zap && zap._id ? zap._id : null,
      busca,
      skip,
      limit,
    });

    setPageCount(response.data.total / limit);
    setlista(response.data.data);
    setLoading(false);
  }, [busca,zap, skip, data_ate, data_de, limit, filtro]);

  useEffect(() => {
    const initoday = moment().startOf('d').toDate();
    const endtoday = moment().endOf('d').toDate();
    const dateViaBase = moment().startOf('y').toDate();
    if(filtro === 'notsync' || filtro === 'atrasada' || filtro === 'iniciado' ){
      setdata_de(initoday);
      setdata_ate(endtoday);
    } else if (filtro === 'viabase'){
      setdata_de(dateViaBase);
    } else if(filtro === 'agendadas'){
      setdata_de(initoday);
      setdata_ate(moment().add(3, 'M').endOf('M').toDate())
    }
  }, [filtro])

  async function excluir() {
    confirmAlert({
      title: 'Excluir Campanhas',
      message: 'Excluir todas as campanhas selecionadas',
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            const response = await api.post('/v1/campanhas/all', {
              campanhas: selecionados,
            });
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success('Sucesso!', {
              position: toast.POSITION.BOTTOM_LEFT,
            });

            fetchData();
          },
        },
        {
          label: 'Não',
        },
      ],
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zap, skip]);

  function fetchPagination(data) {
    history.push(`/campanhas?page=${data.selected || 0}`);
  }

  function returnStatus(el) {
    const today = moment().startOf('d').toDate();
    if (el.viaBase) {
      return (
        <StatusComponent className="finalizada-success" icon="AiOutlineCheck" label="Via Base Criada" />
      );
    }
    if (el.totalEnviado === 0 && el.totalFalhas === 0 && moment(el.dataHoraDisparo).toDate() < today ) {
      return (
        <StatusComponent className="finalizada-error" icon="AiOutlineClose" label="Não Enviada" />
      );
    }
    if (el.ready && el.totalEnviado === 0 && el.totalFalhas === 0 && moment(el.dataHoraDisparo).toDate() > today) {
      return (
        <StatusComponent className="agendada" icon="AiOutlineFieldTime" label="Agendada" />
      );
    }

    if (!el.finalizado && !el.ready) {
      return (
        <StatusComponent className="naoProntaEnviar" icon="AiOutlineClose" label="Contatos não
        sincronizados" />
      );
    }
    
    if ((el.totalEnviado > 0 || el.totalFalhas > 0) && el.finalizado && moment(el.dataHoraDisparo).toDate() < today) {
      return (
        <StatusComponent className="finalizada-success" icon="AiOutlineCheck" label="Concluida" />
      );
    }

    if (el.totalEnviado > 0 || el.totalFalhas > 0) {
      return (
        <StatusComponent className="finalizada-success" icon="AiOutlineCheck" label="Iniciada" />
      );
    }
  }

  function selecionadosControl(id, e) {
    if (selecionados.indexOf(id) > -1 && !e.target.checked) {
      selecionados.splice(selecionados.indexOf(id), 1);
    } else {
      selecionados.push(id);
    }
    setselecionados([...selecionados]);
  }

  function selectAll(e) {
    if(e.target.checked) setselecionados(lista.map(el => el._id));
    else setselecionados([]);
  }

  async function cancelarRepeticao(el) {
    confirmAlert({
      title: 'Cancelar repetição',
      message: `Deseja cancelar repetição ?`,
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            const response = await api.put(`/v1/campanhas/`, {
              _id: el._id,
              repetirAteFim: false,
            });
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success('Sucesso!', {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            fetchData();
          },
        },
        {
          label: 'Não',
        },
      ],
    });
  }

  async function novaCampanha() {
    const camp = await api.post('/v1/campanhas/geraCampanha', { zap: zap && !zap._id ? zap: null });
    history.push(`/campanhas/alterar/${camp.data._id}`);
  }

  function closeModal() {
    setaddcontmodal(false);
    setc(null);
    fetchData();
  }

  function download() {
    window.open(`${process.env.REACT_APP_API_URL}/v1/campanhas/download-resultado?token=${token}&data_de=${data_de}&data_ate=${data_ate}&selecionados=${selecionados}`);
  }

  return (
    <>
      <ModalContatosAdicionais
        modalVisible={addcontmodal}
        closeModal={closeModal}
        campanha={c}
      />
      <div id="campanhas">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop texto="Campanhas" title="campanhas" context={context} />
              <div className="area_body">
                <div id="campanhaPesquisa">
                  <div className="row">
                    <div className="col-sm-12 col-md-2 mt-2 mb-2">
                      <select
                        value={filtro}
                        onChange={(e) => setfiltro(e.target.value)}
                        className="select-search mt-4"
                      >
                        <option value="">Mostrar tudo</option>
                        <option value="concluidas">Concluídas</option>
                        <option value="iniciado">Iniciada</option>
                        <option value="notsync">Não sincronizada</option>
                        <option value="agendadas">Agendadas</option>
                        <option value="viabase">Via Base</option>
                        <option value="atrasada">Atrasadas</option>
                      </select>
                    </div>
                    <div className="col-sm-12 col-md-4 mt-2 mb-2">
                      <input
                        type="text"
                        value={busca}
                        onChange={(e) => setbusca(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" &&  fetchData()}
                        className="input-search-white mt-1"
                        placeholder="Pesquise uma campanha"
                      />
                    </div>
                    <div className="col-sm-12 col-md-2 mt-2 mb-2 customDatePickerWidth">
                      <DatePicker
                        locale="el"
                        selected={data_de}
                        placeholderText="A partir de"
                        className="input-search-white mt-1"
                        dateFormat="Pp"
                        showTimeSelect
                        onChange={(date) => setdata_de(date)}
                      />
                    </div>
                    <div className="col-sm-12 col-md-2 mt-2 mb-2 customDatePickerWidth">
                      <DatePicker
                        locale="el"
                        selected={data_ate}
                        placeholderText="Até"
                        className="input-search-white mt-1"
                        dateFormat="Pp"
                        showTimeSelect
                        onChange={(date) => setdata_ate(date)}
                      />
                    </div>
                    <div className="col-sm-12 col-md-2 mt-2 mb-2 d-flex">
                      <button
                        onClick={() => fetchData()}
                        className="btn-main mt-auto w-100"
                      >
                        Buscar
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-2 mt-2 mb-1">
                      <button className="btn-main w-100" onClick={novaCampanha}>
                        Nova Campanha
                      </button>
                    </div>

                    <div className="col-sm-12 col-md-2 mt-2 mb-1">
                      <button
                        className="btn-main w-100"
                        onClick={() => history.push('/campanhas/importar')}
                      >
                        Importar Campanha
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-3 mt-2 mb-1">
                      <button
                        className="btn-main float-left"
                        onClick={download}
                      >
                        <AiOutlineCloudDownload
                          className="mr-2 position-relative AiOutlineCloudDownload"
                          size={18}
                        />
                        Fazer download resultado campanhas
                      </button>
                    </div>
                    <div className="col-sm-12 col-md-5 mt-2 mb-1">
                      <button
                        className="btn-main float-right"
                        onClick={() => excluir()}
                      >
                        Remover Selecionados
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    id="campanhatabela"
                    className="col-sm-12 col-md-12 mt-2 mb-1"
                  >
                    <div className="card-content table-responsive">
                      <table className="table table-hover" id="lista">
                        <thead>
                          <tr>
                            <th scope="col">
                            <div className="float-right mb-3">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        selectAll(e)
                                      }
                                      className="form-check-input"
                                    />
                                  </div>
                            </th>
                            <th scope="col">ID</th>
                            <th scope="col">NÚMERO</th>
                            <th scope="col">ENVIADOS</th>
                            <th scope="col">FALHAS</th>
                            <th scope="col">REAIS</th>
                            <th scope="col">MATURAÇÃO</th>
                            <th scope="col">TOTAL</th>
                            <th scope="col">DISPARO</th>
                            <th scope="col">REPETIR</th>
                            <th scope="col">STATUS</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {lista.map(function (el) {
                            return (
                              <tr
                                onDoubleClick={(e) =>
                                  selecionadosControl(el._id, e)
                                }
                                key={el._id}
                                htmlFor={el._id}
                              >
                                <td className="pointerTable">
                                  <div className="float-right">
                                    <input
                                      type="checkbox"
                                      checked={
                                        selecionados.indexOf(el._id) > -1
                                      }
                                      onChange={(e) =>
                                        selecionadosControl(el._id, e)
                                      }
                                      className="form-check-input"
                                    />
                                  </div>
                                </td>
                                <td className="pointerTable">
                                  {el.nome ? el.nome : 'Sem nome'}
                                </td>
                                <td className="pointerTable">
                                  {el?.zap?.telefone
                                    ? el.zap.telefone
                                    : 'Sem telefone'}
                                </td>

                                <td className="text-success text-center">
                                  {el.totalEnviado}
                                </td>

                                <td className="text-danger text-center">
                                  {el.totalFalhas}
                                </td>

                                <td className="text-info text-center">
                                  {el.totalReal}
                                </td>

                                <td className="text-info text-center">
                                  {el.totalFake}
                                </td>

                                <td className="text-primary text-center">
                                  {el.total}
                                </td>

                                <td className="pointerTable">
                                  {el.dataHoraDisparo
                                    ? `${moment(el.dataHoraDisparo).format(
                                        'DD/MM/YYYY'
                                      )} às ${moment(el.dataHoraDisparo).format(
                                        'HH:mm'
                                      )}`
                                    : 'Sem data de disparo'}
                                </td>
                                <td className="pointerTable">
                                  {el.repetirAteFim ? 'Sim' : 'Nunca'}
                                </td>
                                {returnStatus(el)}

                                <td>
                                  { !el.api && 
                                    <Link className="detalhes" to={`/campanhas/alterar/${el._id}`}>Editar</Link>
                                  }
                                </td>

                                <td>
                                  <Link className='detalhes' to={`/campanhas/resultado/${el._id}`}>Resultado</Link>
                                </td>

                                {el.repetirAteFim && el.finalizado && moment(el.dataHoraDisparo) > moment().startOf('D') && (
                                    <>
                                      <td>
                                        Contatos Adicionais:{' '}
                                        {el.contatosAdicionais}
                                        <br />
                                        <button
                                          className="btn btn-success mb-1 mt-1"
                                          onClick={() => {
                                            setc(el);
                                            setaddcontmodal(true);
                                          }}
                                        >
                                          Contatos Adicionais
                                        </button>
                                        <button
                                          className="btn btn-danger mb-1 mt-1"
                                          onClick={(e) => cancelarRepeticao(el)}
                                        >
                                          Cancelar Repetição
                                        </button>
                                      </td>
                                    </>
                                  )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}

                      {lista.length > 0 && (
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakClassName="page-item disabled"
                          breakLinkClassName="page-link"
                          disabledClassName="disabled"
                          pageCount={pageCount}
                          pageClassName="page-item"
                          previousClassName="page-item"
                          pageLinkClassName="page-link"
                          initialPage={parseInt(query.get('page'))}
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                          activeClassName="active"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
