import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import Skeleton from "react-loading-skeleton";
import Dropzone from "react-dropzone";
import api from "../../service/api";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import "react-confirm-alert/src/react-confirm-alert.css";
import useQuery from "../../core/useQuery";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import ModalContato from "./ModalContato";
import ModalConfig from "./ModalConfig";
import "./style.css";

export default function Financeiro({ context, history }) {
  const [loading, setLoading] = useState(false);

  const [result, setresult] = useState([]);
  const [scheduledList, setScheduledList] = useState([]);
  const [search, setSearch] = useState("");
  const [contato, setcontato] = useState(null);
  const [config, setconfig] = useState({
    aviso: 7,
    mensagem: `Bom dia [nome]!\n\nAgradecemos pela preferência, caso tenha alguma dúvida pode entrar em contato conosco através de nossos e-mail’s ou WhatsApp.\n\nAnexo estou encaminhando os boletos referente as suas parcelas.\n\nPara acessar digite os 3 primeiros números do seu CPF ou CNPJ`,
  });

  const [contatos, setcontatos] = useState([]);
  const [modalvisible, setmodalvisible] = useState(false);
  const [modalConfigvisible, setmodalConfigvisible] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const query = useQuery();
  const limit = 30;
  const skip = parseInt(query.get("page") || 0) * limit;

  async function SalvarEprogramarEnvios() {
    if (!result.length) {
      toast.error("Selecione os boletos para envio", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    const response = await api.post("/v1/financeiro/programarEnviosBoletos", {
      boletos: result,
      config: config,
    });
    if (!response.data.success) {
      toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    toast.success("Boletos programados com sucesso!", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
    setresult([]);
    setcontato(null);
    context.updateState();
    await fetchData();
  }

  async function uploadFiles(values) {
    const dataEnvio = new FormData();

    values.map((file) => {
      return dataEnvio.append("files", file);
    });

    if (!contato) {
      return toast.error("Selecione um contato para continuar", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    dataEnvio.append("contato", JSON.stringify(contato));
    dataEnvio.append("arquivos", JSON.stringify(values));
    const response = await api.post("/v1/financeiro/leitorPDFs", dataEnvio, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response.data.success) {
      setresult(result.concat(response.data.data));
      toast.success(`Sucesso ao gerar ${response.data.concluidos} boletos`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
    setLoading(false);
  }

  function fetchPagination(data) {
    history.push(`/financeiro?page=${data.selected || 0}`);
  }

  async function handleRemoveCampanha(data) {
    confirmAlert({
      title: "Remover Campanha Financeira",
      message: `Essa ação irá cancelar o envio do boleto para ${data.nome}!`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.delete(`/v1/financeiro/${data._id}`);
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });

            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function fetchData() {
    const response = await api.post(`/v1/financeiro/list`, {
      search,
      skip,
      limit,
    });

    const responseContatos = await api.post(`/v1/contatos/list`, { all: true });
    responseContatos.data.data.map((el) => {
      return (
        (el.value = el._id) + "," + (el.label = `${el.nome} – ${el.telefone}`)
      );
    });
    setcontatos(responseContatos.data.data);

    setScheduledList(response.data.data);
    setPageCount(response.data.total / limit);
    setLoading(false);
  }

  async function closeModal() {
    setmodalvisible(false);
    await fetchData();
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [skip]);

  useEffect(() => {
    if (context.usuario) setconfig(context.usuario.configFinanceiro);
  }, [context]);

  return (
    <>
      <Prompt
        message="Deseja realmente sair da pagina?"
        when={result.length > 0}
      />
      <ModalContato
        modalvisible={modalvisible}
        closeModal={closeModal}
        context={context}
      />
      <ModalConfig
        modalVisible={modalConfigvisible}
        setmodalVisible={setmodalConfigvisible}
        config={config}
        setconfig={setconfig}
      />
      <div id="financeiro">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop
                texto="Financeiro"
                title="financeiro"
                context={context}
              />
              <div className="area_body">
                <div className="row mt-2">
                  <div className="col-sm-12 col-md-4 mt-2">
                    <label>Contato</label>
                    <Select
                      placeholder="Selecione um contato"
                      className="select-menu"
                      value={contato}
                      defaultValue={contato}
                      onChange={(e) => setcontato(e)}
                      options={contatos}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 d-flex align-items-end mt-2">
                    <button
                      onClick={() => setmodalvisible(true)}
                      className="btn btn-main w-100"
                    >
                      Adicionar novo contato
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-3"></div>
                  <div className="col-sm-12 col-md-2 d-flex align-items-end mt-2">
                    <button
                      onClick={() => setmodalConfigvisible(true)}
                      className="btn btn-main w-100"
                    >
                      Configuração
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 mt-2 mb-1">
                    <Dropzone onDrop={uploadFiles}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="bordernone mt-3 mb-3">
                          <div
                            className="bordernone mt-3 mb-3"
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div
                              id="boletos"
                              className="arrasteAqui text-center"
                            >
                              <p>Arraste ou selecione os boletos</p>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    <div className="row mb-1 mt-3">
                      <div className="col-sm-12 col-md-12">
                        <p>
                          <b>
                            Verifique os boletos gerados e no final da página
                            selecione o botão Salvar e programar envios dos
                            boletos{" "}
                          </b>
                        </p>
                      </div>
                    </div>
                    <div
                      id="preview-boletos"
                      className="card-content table-responsive mb-4"
                    >
                      <table className="table table-hover" id="lista">
                        <thead>
                          <tr>
                            <th scope="col">NOME - DOCUMENTO</th>
                            <th scope="col">TELEFONE</th>
                            <th scope="col">VENCIMENTO</th>
                            <th scope="col">CÓDIGO</th>
                            <th scope="col">BOLETO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.map(function (el, i) {
                            return (
                              <tr key={i} htmlFor={i}>
                                <td className="pointerTable">
                                  {el.nome ? el.nome : "Sem nome"} -{" "}
                                  {el.cpfcnpj ? el.cpfcnpj : "Sem documento"}
                                </td>
                                <td className="pointerTable">
                                  {el.telefone
                                    ? el.telefone
                                    : "Sem telefone"}
                                </td>
                                <td className="pointerTable">
                                  {moment(el.vencimento).format("DD/MM/YYYY")}
                                </td>
                                <td className="pointerTable">{el.codigo}</td>
                                <td className="pointerTable">
                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.boleto.mimetype}&filename=${el.boleto.filename}&folder=boletos`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Boleto
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-12 col-md-3">
                        {result.length > 0 && (
                          <button
                            className="btn-main w-100"
                            onClick={SalvarEprogramarEnvios}
                          >
                            Salvar e programar envios dos boletos
                          </button>
                        )}
                      </div>
                    </div>
                    <hr />
                    <h3 className="mt-3 mb-3">Boletos já agendados</h3>
                    <div
                      id="boletos-agendados"
                      className="card-content table-responsive mt-2 mb-3"
                    >
                      <div className="row">
                        <div className="col-sm-12 col-lg-5 mt-2 mb-2">
                          <input
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyPress={(e) => e.key === "Enter" && fetchData()}
                            className="input-search"
                            placeholder="Pesquise por nome, cpf, cnpj ou telefone"
                          />
                        </div>
                        <div className="col-sm-12 col-lg-2 mb-2 d-flex">
                          <button
                            onClick={() => fetchData()}
                            className="btn btn-main mt-auto w-100"
                          >
                            Buscar
                          </button>
                        </div>
                      </div>
                      <table className="table table-hover" id="lista">
                        <thead>
                          <tr>
                            <th scope="col">NOME - DOCUMENTO</th>
                            <th scope="col">NÚMERO</th>
                            <th scope="col">VENCIMENTO</th>
                            <th scope="col">CÓDIGO</th>
                            <th scope="col">BOLETO</th>
                            <th scope="col">OPÇÕES</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scheduledList.map(function (el, i) {
                            return (
                              <tr key={i} htmlFor={i}>
                                <td className="pointerTable">
                                  {el.nome ? el.nome : "Sem nome"} -{" "}
                                  {el.cpfcnpj ? el.cpfcnpj : "Sem documento"}
                                </td>
                                <td className="pointerTable">
                                  {el.telefone
                                    ? el.telefone
                                    : "Sem telefone"}
                                </td>
                                <td className="pointerTable">
                                  {moment(el.vencimento).format("DD/MM/YYYY")}
                                </td>
                                <td className="pointerTable">{el.codigo}</td>
                                <td className="pointerTable">
                                  <a
                                    href={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.boleto.mimetype}&filename=${el.boleto.filename}&folder=boletos`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Boleto
                                  </a>
                                </td>
                                <td className="pointerTable">
                                  <button
                                    className="btn btn-danger ml-1"
                                    onClick={() => handleRemoveCampanha(el)}
                                  >
                                    Remover
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                      {scheduledList.length > 0 && (
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakClassName="page-item disabled"
                          breakLinkClassName="page-link"
                          disabledClassName="disabled"
                          pageCount={pageCount}
                          pageClassName="page-item"
                          previousClassName="page-item"
                          pageLinkClassName="page-link"
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                          activeClassName="active"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
