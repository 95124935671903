
import jwt_decode from 'jwt-decode';

export const TOKEN_KEY = "TOKEN";

export const isAuthenticated = () => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('token')) localStorage.setItem(TOKEN_KEY, urlParams.get('token'));

  return localStorage.getItem(TOKEN_KEY) !== null
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getUser = async () => {
  return jwt_decode(await localStorage.getItem(TOKEN_KEY));
}

export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};