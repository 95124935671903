import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import Select from "react-select";
import { GlobalState } from "../../state/state";
import api from "../../service/api";

export default function ModalImportarContato({
  modalVisibleImportar,
  context,
  closeModal,
}) {
  const [loading, setloading] = useState(false);
  const fileInput = useRef();
  const [zaps, setzaps] = useState([]);
  const [zap, setzap] = GlobalState("zap");

  const handleUploadFile = async (event) => {
    if (zap) {
      const data = new FormData();
      data.append("file", event.target.files[0]);
      data.append("zap", zap._id);
      setloading(true);
      const result = await api.post("/v1/contatos/importar-csv", data);
      setloading(false);
      if (result.data.success) {
        toast.success("Contatos importados com sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        if(fileInput.current) fileInput.current.value = "";
        closeModal();
      } else {
        toast.error(result.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } else {
      toast.error("Selecione um número", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  async function fetchData() {
    if(context.zaps) setzaps(context.zaps);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return (
    <Modal
      open={modalVisibleImportar}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <h3 className="mb-3">Importar Contatos</h3>
            <a href="https://dashboard.bestmessage.com.br/arquivo-modelo-lembrazap.csv">
              <AiOutlineCloudDownload className="mr-2" size={18} /> Download
              arquivo modelo importação
            </a>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-5">
            <label>Número vinculado</label> <br />
            <label>
              {zap ? zap.telefone : "Sem número vinculado"}
            </label>
          </div>
          <div className="col-sm-12 col-md-7">
            <label>Selecionar Zap</label>
            <Select
              placeholder="Selecione um número"
              value={zap}
              defaultValue={zap}
              onChange={(e) => setzap(e)}
              options={zaps}
            />
          </div>
        </div>
        <div className="row mt-2">
          {!loading ? (
            <div className="col-sm-12 col-md-12">
            <button
              className="btn-primary w-100 mt-3"
              onClick={() => fileInput.current.click()}
            >
              Selecione o Arquivo
            </button>
            <input
              className="d-none"
              ref={fileInput}
              type="file"
              onChange={handleUploadFile}
            />
          </div>
          ) : (
            <div className="col-sm-12 col-md-12">
            <button
              className="btn-primary w-100 mt-3"
              onClick={() => fileInput.current.click()}
              disabled
            >
              Importando contatos...
            </button>
          </div>
          )}    
        </div>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    width: 500,
  },
};
