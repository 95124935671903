import React, { useState, useEffect, memo } from "react";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { logout } from "../../service/auth";
import "./style.css";
import { GlobalState } from "../../state/state";

/* eslint-disable import/no-anonymous-default-export */
function Menu({ context }) {
  const [visible, setvisible] = useState(false);
  const [zaps, setzaps] = useState({
    ativo: true,
    _id: null,
    label: "Todos os numeros",
  });
  const [zap, setzap] = GlobalState("zap");
  const [usuario, setusuario] = useState({});

  const [acllink, setAcllink] = useState([]);
  const links = [
    { href: "/campanhas", label: "Campanhas" },
    { href: "/respostas", label: "Respostas" },
    { href: "/contatos", label: "Contatos" },
    { href: "/contatosdb", label: "Banco de Contatos" },
    { href: "/numeros", label: "Números" },
    { href: "/relatorio", label: "Relatório" },
  ];

  useEffect(() => {
    async function fetchData() {
      setusuario(context.usuario);
      const zaps = [
        {
          ativo: true,
          _id: null,
          label: "Todos os numeros",
        },
      ].concat(context.zaps);
      setzaps(zaps);
      if(context.acl) setAcllink(context.acl);
    }
    fetchData();
  }, [context]);

  return (
    <div id="header" className="container">
      <div className="row">
        <div className="col-12 pr-0 pl-1">
          {!visible && (
            <AiOutlineMenu
              size={24}
              className="icon-menu d-xs-block d-sm-block d-md-block d-lg-none"
              onClick={() => setvisible(!visible)}
            />
          )}
          {visible && (
            <AiOutlineClose
              iOutlineMenu
              size={24}
              className="icon-menu d-xs-block d-sm-block d-md-block d-lg-none"
              onClick={() => setvisible(!visible)}
            />
          )}
          <div className={`${visible ? "" : "d-none"} d-lg-block mt-4`}>
            <p className="text-center nameAccount">
              <b>
                {usuario && usuario.nome
                  ? usuario.nome.toUpperCase()
                  : "Olá Usuário"}
              </b>
            </p>
            <Select
              placeholder="Selecione um número"
              className="select-menu"
              value={zap}
              defaultValue={zap}
              onChange={(e) => setzap(e)}
              options={zaps}
            />

            <div className="body_area">
              {links.map(({ href, label }) => {
                return (
                  <NavLink className="menu-item" key={label} to={href}>
                    {label}
                  </NavLink>
                );
              })}
              {acllink.map(({ module }) => {
                return (
                  <NavLink className="menu-item" key={module.name} to={module.url}>
                    {module.name}
                  </NavLink>
                );
              })}
              <hr />
              {/*
              {usuario && !usuario.usaMassa && (
                <NavLink className="menu-item_secondary" to="/assinatura">
                  Assinatura
                </NavLink>
              )}
              */}
              <NavLink className="menu-item_secondary" to="/configuracoes">
                Configurações
              </NavLink>
              <NavLink
                className="menu-item_secondary"
                onClick={() => {
                  setzap(null);
                  context.setauthenticated(false);
                  logout();
                }}
                to="/login"
              >
                Sair
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Menu);
