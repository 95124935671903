import React, {useState, useEffect} from 'react';
import './style.css';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const [lista, setlista] = useState([]);
  const [ativa, setativa] = useState(0);
  const [initialAplicado, setinitialAplicado] = useState(false);

  useEffect(() => {
    setlista(props.children);
    for (let i = 0; i < props.children.length; i++) {
      if (props.children[i].props.initial && initialAplicado === false) {
        setativa(i);
        props.setativa(i);
        setinitialAplicado(true);
      }
    }
    setativa(props.ativa ? props.ativa : ativa);
  }, [props.children]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    for (let i = 0; i < props.children.length; i++) {
      if (props.children[i].props.name === props.callbackName) {
        setativa(i);
        props.setativa(i);
      }
    }
  }, [props.callbackName]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="list-tabs mb-3">
        {lista.map((el, i) => {
          return (
            <div key={i}>
              <div
                onClick={() => {
                  setativa(i);
                  props.setativa(i);
                }}
                className={`tab ${i === ativa ? 'active' : ''}`}
              >
                {el.props.name}
              </div>
            </div>
          );
        })}
      </div>
      {props.children[ativa]}
    </>
  );
};
