import React, {useContext} from 'react';
import {Route, Switch, Redirect} from 'react-router';
import {isAuthenticated} from './service/auth';
import Login from './pages/auth/Login';
import Configuracoes from './pages/Configuracoes/Config';
import Respostas from './pages/Respostas/Resposta';
import Campanhas from './pages/Campanhas/Campanhas';
import AddEditCampanha from './pages/Campanhas/AddEditCampanha';
import Contatos from './pages/Contatos/Contatos';
import Register from './pages/auth/Register';
import Numeros from './pages/Numeros/Numeros';
import ImportCampanha from './pages/Campanhas/ImportCampanha';
import Resultado from './pages/Campanhas/Resultado';
import ContatosDB from './pages/ContatosDB/ContatosDB';
import Assinatura from './pages/Assinatura/Assinatura';
import Relatorio from './pages/Relatorio/Relatorio';
import Chat from './pages/Chat/Chat';
import Financeiro from './pages/Financeiro/Financeiro';
import ListLeads from './pages/Leads/ListLeads';
import Leads from "./pages/Leads/Leads";
import Obras from "./pages/Obras/Obras";
import AddEditObras from "./pages/Obras/AddEditObras";
import Grupos from "./pages/Grupos/Grupos";

const PrivateRoute = ({component: Component, context, ...rest}) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} context={context} />
      ) : (
        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
      )
    }
  />
);

export default function Routes({PagesContext}) {
  const context = useContext(PagesContext);
  return (
    <Switch>
      <Route exact path="/login" render={(props) => <Login {...props} context={context} />} />
      <Route exact path="/registrar-se" render={(props) => <Register {...props} context={context} />} />

      <PrivateRoute
        exact
        path="/configuracoes"
        component={Configuracoes}
        context={context}
      />
      <PrivateRoute
        exact
        path="/respostas"
        component={Respostas}
        context={context}
      />
      <PrivateRoute
        exact
        path="/campanhas"
        component={Campanhas}
        context={context}
      />
      <PrivateRoute
        exact
        path="/campanhas/nova"
        component={AddEditCampanha}
        context={context}
      />
      <PrivateRoute
        exact
        path="/campanhas/importar"
        component={ImportCampanha}
        context={context}
      />
      <PrivateRoute
        exact
        path="/campanhas/alterar/:id"
        component={AddEditCampanha}
        context={context}
      />
      <PrivateRoute
        exact
        path="/campanhas/resultado/:id"
        component={Resultado}
        context={context}
      />
      <PrivateRoute
        exact
        path="/contatos"
        component={Contatos}
        context={context}
      />
      <PrivateRoute
        exact
        path="/contatosdb"
        component={ContatosDB}
        context={context}
      />
      <PrivateRoute
        exact
        path="/numeros"
        component={Numeros}
        context={context}
      />
      <PrivateRoute
        exact
        path="/assinatura"
        component={Assinatura}
        context={context}
      />
      <PrivateRoute
        exact
        path="/chat"
        component={Chat}
        context={context}
      />
      <PrivateRoute
        exact
        path="/chat/:id"
        component={Chat}
        context={context}
      />
      <PrivateRoute
        exact
        path="/financeiro"
        component={Financeiro}
        context={context}
      />
      <PrivateRoute
        exact
        path="/leads"
        component={Leads}
        context={context}
      />
      <PrivateRoute
        exact
        path="/leads/list/:id"
        component={ListLeads}
        context={context}
      />
      <PrivateRoute
        exact
        path="/grupos"
        component={Grupos}
        context={context}
      />
      <PrivateRoute
        exact
        path="/obras"
        component={Obras}
        context={context}
      />
      <PrivateRoute
        exact
        path="/obras/novo"
        component={AddEditObras}
        context={context}
      />
      <PrivateRoute
        exact
        path="/obras/alterar/:id"
        component={AddEditObras}
        context={context}
      />
      <PrivateRoute
        exact
        path="/relatorio"
        component={Relatorio}
        context={context}
      />
      <PrivateRoute exact path="/" context={context}>
        <Redirect to="/campanhas" />
      </PrivateRoute>
      <PrivateRoute exact path="*" context={context}>
        <Redirect to="/campanhas" />
      </PrivateRoute>
    </Switch>
  );
}
