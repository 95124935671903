import React, {useRef, useState} from 'react';
import {AiOutlineCloudDownload} from 'react-icons/ai';
import {Modal} from 'react-responsive-modal';
import {toast} from 'react-toastify';
import api from '../../service/api';

export default function ModalImportarContato({
  modalVisibleImportar,
  closeModal,
}) {
  const fileInput = useRef();
  const [loading, setloading] = useState(false);

  const handleUploadFile = async (event) => {
    if (event.target.files[0]) {
      const data = new FormData();
      data.append('file', event.target.files[0]);
      setloading(true);
      const result = await api.post('/v1/contatosdb/importar', data);
      if (result.data.success) {
        toast.success('Contatos importados com sucesso!', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        if(fileInput.current) fileInput.current.value = '';
        closeModal();
        setloading(false);
      } else {
        toast.error(result.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        setloading(false);
      }
    } else {
      toast.error('Selecione um arquivo', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  return (
    <Modal
      open={modalVisibleImportar}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <h3 className="mb-3">Importar Contatos</h3>
            <a href="https://dashboard.bestmessage.com.br/arquivo-modelo-contatodb-lembrazap.csv">
              <AiOutlineCloudDownload className="mr-2" size={18} /> Download
              arquivo modelo importação
            </a>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12">
            {!loading ? (
              <div className="col-sm-12 col-md-12">
                <button
                  className="btn-primary w-100 mt-3"
                  onClick={() => fileInput.current.click()}
                >
                  Selecione o Arquivo
                </button>
                <input
                  className="d-none"
                  ref={fileInput}
                  type="file"
                  onChange={handleUploadFile}
                />
              </div>
            ) : (
              <div className="col-sm-12 col-md-12">
                <button
                  className="btn-primary w-100 mt-3"
                  onClick={() => fileInput.current.click()}
                  disabled
                >
                  Importando contatos...
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: '1%',
    width: 500,
  },
};
