import React, {useRef} from 'react';
import {Modal} from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import api from '../../service/api';
import {Form} from '@unform/web';
import {toast} from 'react-toastify';
import Input from '../../components/Input';

export default function ModalEditNumero({
  modalVisible,
  setmodalVisible,
  campanha,
  contatos,
  zap,
  mensagem,
  arquivo,
  tags,
  getContatosCampanha
}) {
  const formAdd = useRef(null);

  async function adicionarNum(data) {
    if (!zap || !zap._id) {
      setmodalVisible(false);
      toast.error('Selecione um número para criar a campanha', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    const response = await api.post('/v1/campanhas/addQTDContatos', {
      quantidade: data.quantidade,
      zap,
      mensagem,
      campanha,
      ultimoSelecionado: contatos.length > 0 ? contatos[contatos.length - 1] : null,
      tags: tags ? tags.map((el) => el.value) : [],
      arquivo,
    });

    if (response.data.success) {
      await getContatosCampanha();
      setmodalVisible(false);
    } else {
      toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  return (
    <Modal
      open={modalVisible}
      onClose={() => setmodalVisible(false)}
      styles={customStyles}
      center
    >
      <Form ref={formAdd} onSubmit={adicionarNum}>
        <div id="config">
          <div className="mr-5 mb-3">
            <h3 className="colorBackground mb-3">Contatos Adicionar</h3>
          </div>
          <div className="container-fluid mb-3 mt-4">
            <div className="row">
              <Input type="number" name="quantidade" className="inputPadrao" />
            </div>
          </div>
          <hr />
          <button type="submit" className="btn-main float-right">
            Adicionar Contatos
          </button>
        </div>
      </Form>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: '1%',
    minWidth: '100px',
    width: '500px',
  },
};
