import React, { useRef, useState } from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import api from "../../service/api";

export default function ModalImportarNumeros({
  modalVisibleImportar,
  context,
  closeModal,
}) {
  const fileInput = useRef();
  const [pc, setPc] = useState("");

  const handleUploadFile = async (event) => {
    const data = new FormData();
    data.append("file", event.target.files[0]);
    data.append("grupo", pc);
    const result = await api.post("/v1/zap/importar-csv", data);
    if (result.data.success) {
      toast.success("Números importados com sucesso!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      fileInput.current.value = "";
      context.updateState();
      closeModal();
    } else {
      toast.error(result.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  return (
    <Modal
      open={modalVisibleImportar}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <h3 className="mb-3">Importar Números</h3>
            <a
              href={`https://dashboard.bestmessage.com.br/arquivo-modelo-lembrazap-numeros.csv`}
            >
              <AiOutlineCloudDownload className="mr-2" size={18} /> Download
              arquivo modelo importação
            </a>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12">
            <label>Grupo</label>
            <input
              placeholder="Nome do grupo"
              value={pc}
              onChange={(e) => setPc(e.target.value)}
              className="input-search-white"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-12 col-md-12">
            <button
              className="btn-primary w-100 mt-3"
              onClick={() => {
                if (pc) fileInput.current.click();
                else
                  toast.error("Campo grupo obrigatório!", {
                    position: toast.POSITION.BOTTOM_LEFT,
                  });
              }}
            >
              Selecione o Arquivo
            </button>
            <input
              className="d-none"
              ref={fileInput}
              type="file"
              onChange={handleUploadFile}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    width: 500,
  },
};
