import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import api from "../../service/api";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import useQuery from "../../core/useQuery";
import { getToken } from "../../service/auth";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

export default function ListLeads({ context, match, history }) {
  const token = getToken();
  const [leads, setLeads] = useState([]);
  const [listLead, setListLead] = useState(null);
  const query = useQuery();
  const limit = 15;
  const [total, settotal] = useState(0);
  const [recebidos, setrecebidos] = useState(0);
  const [skip, setskip] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);

  async function novaCampanha(verificacao) {
    const mensagem = `Bom dia [nome]! \n\nSoube que recentemente você adquiriu o meu ${listLead.nome}, gostaria de receber um feedback seu, sua opinião é muito importante.\n\nSe você deseja receber mais conteúdos sobre digite *SIM*\n`;
    const camp = await api.post("/v1/campanhas/geraCampanha", {
      zap: listLead.zap,
      mensagem,
    });
    await api.post("/v1/campanhas/addContatos", {
      contatoLeads: listLead._id,
      check: verificacao ? verificacao : "",
      zap: listLead.zap,
      mensagem,
      campanha: camp.data._id,
    });
    history.push(`/campanhas/alterar/${camp.data._id}`);
  }

  async function deleteLead(lead) {
    confirmAlert({
      title: "Excluir Lead",
      message: "Essa ação irá excluir a lead selecionada, desejá continuar?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.delete(`/v1/lead/delete/${lead._id}`);
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Lead excluido com sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            context.updateState();
            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function fetchData() {
    if (match.params.id) {
      const responseLista = await api.get(
        `/v1/lead/list/get/${match.params.id}`
      );
      if (responseLista.data.success) {
        setListLead(responseLista.data.data);
      }

      const response = await api.post(`/v1/lead/list`, {
        listLead: `${match.params.id}`,
        skip,
        limit,
      });
      if (response.data.success) {
        settotal(response.data.total);
        setrecebidos(response.data.recebido);
        setPageCount(response.data.total / limit);
        setLeads(response.data.data);
        window.scrollTo(0, 0);
        setLoading(false);
      }
    }
  }

  function fetchPagination(data) {
    setskip(data.selected || 0);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, skip]);

  return (
    <>
      <div id="listLeads">
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop
                texto={`${
                  listLead && listLead.nome
                    ? `Leads de ${listLead.nome}`
                    : "Leads"
                }`}
                context={context}
              />
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="row">
                    <div className="col-sm-12 col-md-3">
                      <button
                        className="btn btn-main mb-3 w-100"
                        onClick={() => novaCampanha()}
                      >
                        Criar Campanha para todos LEADS
                      </button>
                      <label className="d-flex justify-content-center">{total ? `${total} total`: ""}</label>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <button
                        className="btn btn-primary mb-3 w-100"
                        onClick={() => novaCampanha("confirmados")}
                      >
                        Criar Campanha apenas LEADS confirmados
                      </button>
                      <label className="d-flex justify-content-center text-success">{recebidos ? `${recebidos} recebidos`: ""}</label>
                    </div>
                    <div className="col-sm-12 col-md-3">
                      <button
                        className="btn btn-red mb-3 w-100"
                        onClick={() => novaCampanha("naoconfirmados")}
                      >
                        Criar Campanha apenas LEADS não confirmados
                      </button>
                      <label className="d-flex justify-content-center text-danger">{recebidos && total ? `${total - recebidos} não recebidos`: ""}</label>
                    </div>
                    <div className="col-sm-12 col-md-3">
                    <a
                        rel='noreferrer'
                        className='pt-3 mb-2 float-right'
                        href={`${process.env.REACT_APP_API_URL}/v1/lead/download/${listLead ? listLead._id : ""}?token=${token}`}
                        target='_blank'
                      >
                        <AiOutlineCloudDownload className='mr-2' size={18} />
                        Fazer download de todos os leads
                      </a>
                    </div>
                  </div>
                  <div className="card-content h-full">
                    <div className="row">
                      <div className="col-sm-12 col-md-12 table-responsive">
                        <table className="table table-hover" id="lista">
                          <thead>
                            <tr>
                              <th scope="col">NOME</th>
                              <th scope="col">TELEFONE</th>
                              <th scope="col">RECEBIDO</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {leads.length > 0 && leads.map(
                              (el) =>
                                  (
                                  <tr key={el._id}>
                                    <td className="pointerTable">
                                      {el.nome}
                                    </td>
                                    <td className="pointerTable">
                                      {el.telefone}
                                    </td>
                                    <td className="pointerTable">
                                          {el.ok ? (
                                            <AiFillCheckCircle
                                              className="text-success"
                                              size={20}
                                            />
                                          ) : (
                                            <AiFillCloseCircle
                                              className="text-danger"
                                              size={20}
                                            />
                                          )}
                                    </td>
                                    <td className="pointerTable">
                                      <button className="btn btn-danger" onClick={() => deleteLead(el)}>Remover</button>
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                        {loading && <Skeleton count={10} />}
                      {leads && leads.length > 0 && (
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakClassName="page-item disabled"
                          breakLinkClassName="page-link"
                          disabledClassName="disabled"
                          pageCount={pageCount}
                          pageClassName="page-item"
                          previousClassName="page-item"
                          pageLinkClassName="page-link"
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                          activeClassName="active"
                        />
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
