import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import moment from "moment";
import api from "../../service/api";

let intevaloQr;

export default function ModalQRCode({ modalVisible, closeModal, phone }) {
  const [qr, setqr] = useState(false);
  const [iniciado, setiniciado] = useState(false);

  async function atualizaQr() {
    setiniciado(true);
    intevaloQr = setInterval(async () => {
      const response = await api.get(`/v1/zap/${phone._id}`);
      if (response.data.data.qrcode) setqr(response.data.data.qrcode);
    }, 3000);
  }

  async function fetchData() {
    if (phone && phone._id) {
      const response = await api.get(`/v1/zap/${phone._id}`);
      setqr(response.data.data.qrcode);

      if (!iniciado) {
        atualizaQr();
      }
    }
  }

  useEffect(() => {
    fetchData();

    return function cleanUp() {
      clearInterval(intevaloQr);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);

  return (
    <Modal
      open={modalVisible}
      onClose={() => {
        closeModal();
        clearInterval(intevaloQr);
      }}
      styles={customStyles}
      center
    >
      <div id="modal-qrcode">
        <div className="p-1">
          <h3 className="text-qrcode">
            {phone ? phone.telefone : ""}
          </h3>
          <p className="text-qrcode">
            Último update:{" "}
            {qr && qr.qr
              ? moment(qr.lastUpdate).format("DD/MM/YYYY HH:mm:ss")
              : ""}
          </p>
        </div>
        <br/>
        <br/>
        <center className="mt-5 mb-5">
          {qr && qr.qr && (
            <center>
              <img src={qr && qr.qr ? qr.qr : ""} alt="Qrcode" />
            </center>
          )}
          {(!qr || !qr.qr) && (
            <>
              <span>
                <b>QRCode indisponível</b>
              </span>
              <br />
            </>
          )}
        </center>
        <br/>
        <br/>
        <hr />
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    minWidth: "100px",
    width: "600px",
    background: '#fff'
  },
};
