import React, { useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import api from "../../service/api";
import "react-responsive-modal/styles.css";

export default function ModalZapsNoSync({ modalVisible, closeModal }) {
  const [listNotSync, setlistNotSync] = useState([]);
  const [NoSyncTotal, setNoSyncTotal] = useState(0);

  async function fetchData() {
    const responseNsync = await api.post("/v1/zap/list-unsync");
    setlistNotSync(responseNsync.data.data);
    setNoSyncTotal(responseNsync.data.total);
  }

  useEffect(() => {
    if (modalVisible === true) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  return (
    <Modal open={modalVisible} onClose={closeModal} center>
      <div className="mr-5">
        <h3 className="colorBackground text-center">
          Números não sincronizados
        </h3>
      </div>
      <div className="container p-4">
        <div className="row">
          {NoSyncTotal > 0 ? (
            <>
              <div className="col-12 text-center mb-3">
                <span className="text-danger">
                  {NoSyncTotal} Número{NoSyncTotal > 1 ? "s" : ""} com contatos
                  não sincronizados!
                </span>
              </div>

              {listNotSync.map((el) => {
                return (
                  <div
                    key={el._id}
                    value={el._id}
                    className="col-md-3 text-center"
                  >
                    <span className="text-dark">{el.zap.telefone}</span>
                  </div>
                );
              })}
            </>
          ) : (
            <p className="text-center text-success h5 col-md-12 ">
              Nenhum número dessincronizado!
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
}
