import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import ReactPaginate from "react-paginate";
import useQuery from "../../core/useQuery";
import { AiOutlineCloudDownload } from "react-icons/ai";
import api from "../../service/api";
import { getToken } from "../../service/auth";
import { confirmAlert } from "react-confirm-alert";
import Skeleton from "react-loading-skeleton";
import { GlobalState } from "../../state/state";
import ModalAddEditContato from "./ModalAddEditContato";
import { toast } from "react-toastify";
import ModalImportarContatos from "./ModalImportarContatos";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "./style.css";
import avatar from "../../assets/imgs/avatar.png"

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Contatos({ context, history }) {
  const [loading, setLoading] = useState(true);
  const token = getToken();
  const [busca, setbusca] = useState("");
  const [tag, setTag] = useState(null);
  const [listTags, setListTags] = useState([]);

  const [lista, setlista] = useState([]);
  const [total, settotal] = useState(0);
  const [zap] = GlobalState("zap");

  const query = useQuery();
  const limit = 15;
  const skip = parseInt(query.get("page") || 0) * limit;

  const [pageCount, setPageCount] = useState(0);
  const [contato, setcontato] = useState(null);

  const [selecionados, setselecionados] = useState([]);

  const [modalVisibleAddEdit, setmodalVisibleAddEdit] = useState(false);
  const [modalVisibleImportar, setmodalVisibleImportar] = useState(false);

  async function fetchData() {
    const response = await api.post(`/v1/contatos/list`, {
      busca,
      skip,
      limit,
      tag: tag ? tag.value : null,
      zap: zap && zap._id ? zap._id : null,
    });
    if (context.usuario && context.usuario.tags) {
      const tags = context.usuario.tags.map((el) => {
        return { value: el, label: el}
      });
      tags.unshift({ value: "", label: "Mostrar todos"});
      setListTags(tags); 
    }
    setPageCount(response.data.total / limit);
    settotal(response.data.total);
    setlista(response.data.data);
    setLoading(false);
  }

  async function excluirSelect() {
    confirmAlert({
      title: "Excluir Contatos",
      message: `Excluir todos os contatos selecionados ?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/contatos/array", {
              contatos: selecionados,
            });

            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zap, skip]);

  function fetchPagination(data) {
    history.push(`/contatos?page=${data.selected || 0}`);
  }

  function selecionadosControl(id, e) {
    if (selecionados.indexOf(id) > -1 && !e.target.checked) {
      selecionados.splice(selecionados.indexOf(id), 1);
    } else {
      selecionados.push(id);
    }
    setselecionados([...selecionados]);
  }

  function closeModal() {
    setmodalVisibleAddEdit(false);
    setmodalVisibleImportar(false);
    setcontato(null);
    fetchData();
  }

  function tagsAllign(el) {
    const tamanho = el.tags.length - 1;
    return el.tags.map((el, i) => (
      <span key={i}>
        {el}
        {i >= tamanho ? " " : " | "}
      </span>
    ));
  }

  return (
    <>
      <ModalAddEditContato
        modalVisibleAddEdit={modalVisibleAddEdit}
        contato={contato}
        closeModal={closeModal}
        context={context}
      />
      <ModalImportarContatos
        modalVisibleImportar={modalVisibleImportar}
        closeModal={closeModal}
        context={context}
      />
      <div id='contatos'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-lg-2 mb-5'>
              <Menu context={context} />
            </div>
            <div className='col-sm-12 col-lg-10'>
              <AreaTop texto='Contatos' title='contatos' context={context} />
              <div className='area_body'>
                <div id='busca'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        className='btn-main mt-auto w-100'
                        onClick={() => setmodalVisibleAddEdit(true)}
                      >
                        Novo Contato
                      </button>
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        className='btn-main mt-auto w-100'
                        onClick={() => setmodalVisibleImportar(true)}
                      >
                        Importar Contatos
                      </button>
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2'>
                      <Select
                        placeholder="Selecione uma tag"
                        className="select-menu"
                        value={tag}
                        defaultValue={tag}
                        onChange={(e) => setTag(e)}
                        options={listTags}
                      />
                    </div>
                    <div className='col-sm-12 col-md-4 mt-2 mb-2'>
                      <input
                        type='text'
                        value={busca}
                        onChange={(e) => setbusca(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" &&  fetchData()}
                        className='input-search-white mt-1'
                        placeholder='Pesquise por nome, telefone ou tags' />
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        onClick={() => fetchData()}
                        className='btn-main mt-auto w-100'
                      >
                        Buscar
                      </button>
                    </div>
                    <div className='col-sm-12 col-md-3 mt-2 mb-1'>
                      <a
                        className='btn-main float-left'
                        href={`${process.env.REACT_APP_API_URL}/v1/contatos/exportar/csv?token=${token}`}
                      >
                        <AiOutlineCloudDownload className='mr-2 position-relative AiOutlineCloudDownload' size={18} />
                        Fazer download de todos os contatos
                      </a>
                    </div>
                    <div className='col-sm-12 col-md-9 mt-2 mb-1'>
                      <button
                        className='btn-main float-right'
                        onClick={() => excluirSelect()}
                      >
                        Remover Selecionados
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-2 mt-2 mb-1'>
                      <label>Total de {total} contatos </label>
                    </div>
                  </div>
                </div>
                <div className='row' id='tabelaContatos'>
                  <div className='col-sm-12 col-md-12  mt-2 mb-1'>
                    <div className='card-content table-responsive'>
                      <table className='table table-hover' id='lista'>
                        <thead>
                          <tr>
                            <th scope='col'></th>
                            <th scope='col'>SEQ</th>
                            <th scope='col'>FOTO</th>
                            <th scope='col'>NOME</th>
                            <th scope='col'>SINCRONIZADO</th>
                            <th scope='col'>TELEFONE</th>
                            <th scope='col'>TAGS</th>
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {lista.map((el) => {
                            return (
                              <tr
                                onDoubleClick={(e) =>
                                  selecionadosControl(el._id, e)
                                }
                                key={el._id}
                              >
                                <td className='pointerTable'>
                                  <div className='float-right'>
                                    <input
                                      type='checkbox'
                                      checked={
                                        selecionados.indexOf(el._id) > -1
                                      }
                                      onChange={(e) =>
                                        selecionadosControl(el._id, e)
                                      }
                                      className='form-check-input'
                                    />
                                  </div>
                                </td>
                                <td className='pointerTable'>
                                  {el.seq}
                                </td>
                                <td className='pointerTable'>
                                  {el.profilePic ? (
                                    <img
                                    style={{height: 60}}
                                    alt="img"
                                    className="img-thumbnail rounded avatar"
                                    src={`${process.env.REACT_APP_API_URL}/upload?mimetype=image/jpeg&filename=${el.profilePic}&folder=arquivosWhats`}
                                  />
                                  ) : (
                                    <img
                                    style={{height: 60}}
                                    alt="img"
                                    className="img-thumbnail rounded avatar"
                                    src={avatar}
                                  />
                                  )}
                                  
                                </td>
                                <td className='pointerTable'>
                                  {el.nome ? el.nome : "Sem nome"}
                                </td>
                                <td className='pointerTable'>
                                  {el.sincronizado ? "SIM" : "NÃO"}
                                </td>
                                <td className='pointerTable'>
                                  {el.telefone
                                    ? el.telefone
                                    : "Sem telefone"}
                                </td>
                                <td className='pointerTable'>
                                  {tagsAllign(el)}
                                </td>
                                <td
                                  className='detalhes'
                                  onClick={() => {
                                    setcontato(el);
                                    setmodalVisibleAddEdit(true);
                                  }}
                                >
                                  Editar
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                      {lista.length > 0 && (
                        <ReactPaginate
                          previousLabel='<'
                          nextLabel='>'
                          breakLabel='...'
                          breakClassName='page-item disabled'
                          breakLinkClassName='page-link'
                          disabledClassName='disabled'
                          pageCount={pageCount}
                          pageClassName='page-item'
                          previousClassName='page-item'
                          pageLinkClassName='page-link'
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName='pagination'
                          subContainerClassName='pages'
                          activeClassName='active'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
