import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import api from "../../service/api";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import el from "date-fns/locale/pt-BR";
import "./style.css";
registerLocale("el", el);


/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Contatos({ context, history }) {
    const [de, setde] = useState(moment().startOf('M').toDate());
    const [ate, setate] = useState(moment().endOf("D").toDate());
    const [quantidades,setquantidades] = useState({ enviado: 0, falhas: 0, respostas: 0 }) 

  async function fetchData() {
    const response = await api.post(`/v1/campanhas/relatorio`, {
      de,
      ate
    });
    console.log("response.data.data", response.data);
    setquantidades(response.data);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div id='resultado'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-lg-2 mb-5'>
              <Menu context={context} />
            </div>
            <div className='col-sm-12 col-lg-10'>
              <AreaTop texto='Relatório' title='Relatorio' context={context} />
              <div className='area_body'>
              <div className="row" id="buscas">
                  <div className="col-sm-12 col-md-3 mt-2 mb-2 customDatePickerWidth">
                    <label>A partir de</label>
                    <DatePicker
                      locale="el"
                      selected={de}
                      placeholderText="Selecione uma data"
                      className="input-search-white mt-1"
                      dateFormat="Pp"
                      showTimeSelect
                      onChange={(date) => setde(date)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 mt-2 mb-2 customDatePickerWidth">
                    <label>Até</label>
                    <DatePicker
                      locale="el"
                      selected={ate}
                      placeholderText="Selecione uma data"
                      className="input-search-white mt-1"
                      dateFormat="Pp"
                      showTimeSelect
                      onChange={(date) => setate(date)}
                    />
                  </div>
                  <div className="col-sm-12 col-md-3 mt-2 mb-2 d-flex">
                    <button
                      onClick={fetchData}
                      className="btn-main mt-auto w-100"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-sm-12 col-md-6">
                    <div className='card-content'>
                      <table className='table table-hover' id='lista'>
                        <thead>
                          <tr>
                            <th scope='col'>RESPOSTAS</th>
                            <th scope='col'>ENVIADO</th>
                            <th scope='col'>FALHAS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='pointerTable'>
                              <label className="text-relatorio text-info mr-4">{quantidades.respostas}</label> 
                            </td>
                            <td className='pointerTable'>
                              <label className="text-relatorio text-success mr-4">{quantidades.enviado}</label> 
                            </td>
                            <td className='pointerTable'>
                              <label className="text-relatorio text-danger">{quantidades.falhas}</label> 
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
