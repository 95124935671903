import React, {createContext, useEffect, useState} from 'react';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/index';
import Routes from './routes';
import {ToastContainer} from 'react-toastify';
import {loadProgressBar} from 'axios-progress-bar';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './assets/fonts/fonts.css';
import './style.css';
import './themes/dark.css';
import {GlobalState} from './state/state';
import {isAuthenticated} from './service/auth';
import api from './service/api';

export default function App() {
  const [authenticated, setauthenticated] = useState(isAuthenticated());
  const [state, setstate] = useState({
    setauthenticated,
    updateState,
  });
  const PagesContext = createContext(state);
  const [zap, setzap] = GlobalState('zap');

  async function updateState() {
    await fetchData();
  }

  async function fetchData() {
    if (authenticated) {
      const responseUsuario = await api.post('v1/users/me');
      let limite = 0;
      let limiteMensal = 0;
      const responseZaps = await api.post(`/v1/zap/list`, {all: true});
      responseZaps.data.data.map((el) => {
        limite += el.limite;
        limiteMensal += el.limiteMensal;
        return (el.value = el._id) + ',' + (el.label = el.telefone);
      });

      const responseNotificacoes = await api.post(
        '/v1/notificacao/countUnread'
      );

      const responseAcl = await api.post("/v1/acl/list-acl-user");

      setstate({
        usuario: responseUsuario.data,
        zaps: responseZaps.data.data,
        limite,
        limiteMensal,
        notificacoes: responseNotificacoes.data.total,
        zap,
        acl: responseAcl.data.data,
        setauthenticated,
        updateState,
      });

      if (responseZaps.data.total === 1) {
        setzap(responseZaps.data.data[0]);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);
  
  return (
    <BrowserRouter>
      {loadProgressBar()}
      <Routes PagesContext={PagesContext} />
      <ToastContainer />
      <ScrollToTop />
    </BrowserRouter>
  );
}
