import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import ReactPaginate from "react-paginate";
import useQuery from "../../core/useQuery";
import api from "../../service/api";
import { confirmAlert } from "react-confirm-alert";
import Skeleton from "react-loading-skeleton";
import ModalAddEditGrupo from "./ModalAddEditGrupo";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

/* eslint-disable jsx-a11y/anchor-is-valid */
export default function Grupos({ context, history }) {
  const [loading, setLoading] = useState(true);
  const [busca, setbusca] = useState("");

  const [lista, setlista] = useState([]);
  const [total, settotal] = useState(0);

  const query = useQuery();
  const limit = 15;
  const skip = parseInt(query.get("page") || 0) * limit;

  const [pageCount, setPageCount] = useState(0);
  const [grupo, setgrupo] = useState(null);

  const [selecionados, setselecionados] = useState([]);

  const [modalVisibleAddEdit, setmodalVisibleAddEdit] = useState(false);
  
  async function fetchData() {
    const response = await api.post(`/v1/grupo/list`, {
      busca,
      skip,
      limit,
    });
    setPageCount(response.data.total / limit);
    settotal(response.data.total);
    setlista(response.data.data);
    setLoading(false);
  }

  async function excluirSelect() {
    confirmAlert({
      title: "Excluir Grupos",
      message: `Excluir ${selecionados.length > 1 ? "todos os grupos selecionados": "o grupo selecionado"} ?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/grupo/array", {
              grupos: selecionados,
            });

            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  function fetchPagination(data) {
    history.push(`/grupos?page=${data.selected || 0}`);
  }

  function selecionadosControl(id, e) {
    if (selecionados.indexOf(id) > -1 && !e.target.checked) {
      selecionados.splice(selecionados.indexOf(id), 1);
    } else {
      selecionados.push(id);
    }
    setselecionados([...selecionados]);
  }

  function closeModal() {
    setmodalVisibleAddEdit(false);
    setgrupo(null);
    fetchData();
  }

  return (
    <>
      <ModalAddEditGrupo
        modalVisibleAddEdit={modalVisibleAddEdit}
        grupo={grupo}
        closeModal={closeModal}
        context={context}
      />
      <div id='grupos'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-lg-2 mb-5'>
              <Menu context={context} />
            </div>
            <div className='col-sm-12 col-lg-10'>
              <AreaTop texto='Grupos' title='grupos' context={context} />
              <div className='area_body'>
                <div id='busca'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        className='btn-main mt-auto w-100'
                        onClick={() => setmodalVisibleAddEdit(true)}
                      >
                        Novo Grupo
                      </button>
                    </div>
                    <div className='col-sm-12 col-md-6 mt-2 mb-2'>
                      <input
                        type='text'
                        value={busca}
                        onChange={(e) => setbusca(e.target.value)}
                        onKeyPress={(e) => e.key === "Enter" &&  fetchData()}
                        className='input-search-white mt-1'
                        placeholder='Pesquise por grupo' />
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        onClick={() => fetchData()}
                        className='btn-main mt-auto w-100'
                      >
                        Buscar
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-2 mt-2 mb-1'>
                      <label>Total de {total} {total > 1 ? "grupos": "grupo"} </label>
                    </div>
                    <div className='col-sm-12 col-md-10 mt-2 mb-1'>
                      <label
                        className='action float-right'
                        role='button'
                        onClick={() => excluirSelect()}
                      >
                        Remover Selecionados
                      </label>
                    </div>
                  </div>
                </div>
                <div className='row' id='tabelaGrupos'>
                  <div className='col-sm-12 col-md-12  mt-2 mb-1'>
                    <div className='card-content table-responsive'>
                      <table className='table table-hover' id='lista'>
                        <thead>
                          <tr>
                            <th scope='col'></th>
                            <th scope='col'>Titulo</th>
                            <th scope='col'>Zap</th>
                            <th scope='col'>Somente Leitura</th>
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {lista.map((el) => {
                            return (
                              <tr
                                onDoubleClick={(e) =>
                                  selecionadosControl(el._id, e)
                                }
                                key={el._id}
                              >
                                <td className='pointerTable'>
                                  <div className='float-right'>
                                    <input
                                      type='checkbox'
                                      checked={
                                        selecionados.indexOf(el._id) > -1
                                      }
                                      onChange={(e) =>
                                        selecionadosControl(el._id, e)
                                      }
                                      className='form-check-input'
                                    />
                                  </div>
                                </td>
                                <td className='pointerTable'>
                                  {el.formattedTitle ? el.formattedTitle : "Sem titulo"}
                                </td>
                                <td className='pointerTable'>
                                  {el.zap && el.zap.telefone ? el.zap.telefone : "Sem Telefone"}
                                </td>
                                <td className='pointerTable'>
                                  {el.isReadOnly ? "SIM" : "NÃO"}
                                </td>
                                <td
                                  className='detalhes'
                                  onClick={() => {
                                    setgrupo(el);
                                    setmodalVisibleAddEdit(true);
                                  }}
                                >
                                  Editar
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                      {lista.length > 0 && (
                        <ReactPaginate
                          previousLabel='<'
                          nextLabel='>'
                          breakLabel='...'
                          breakClassName='page-item disabled'
                          breakLinkClassName='page-link'
                          disabledClassName='disabled'
                          pageCount={pageCount}
                          pageClassName='page-item'
                          previousClassName='page-item'
                          pageLinkClassName='page-link'
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName='pagination'
                          subContainerClassName='pages'
                          activeClassName='active'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
