import React, { useRef, useEffect, useState } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import api from "../../service/api";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import Input from "../../components/Input";

export default function ModalEditNumero({
  modalVisible,
  closeModal,
  fetchData,
  phone,
  context
}) {
  const formAdd = useRef(null);

  const [usuario, setusuario] = useState({});

  async function adicionarNum(data) {
    data.usuario = usuario._id;
    formAdd.current.setErrors({});
    try {
      let schema = Yup.object().shape({
        telefone: Yup.string().required("telefone é obrigatório"),
      });
      data.telefone = data.telefone.replace(/\D/gim, '');
      await schema.validate(data, { abortEarly: false });
      if (phone) {
        data._id = phone._id;
        const response = await api.put(`/v1/zap`, data);
        if (!response.data.success) {
          toast.error(response.data.err, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return;
        }
        toast.success("Sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        const response = await api.post(`/v1/zap`, data);
        if (!response.data.success) {
          toast.error(response.data.err, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return;
        }
        toast.success("Sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
      context.updateState();
      closeModal();
      fetchData();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAdd.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchDatas() {
    if (context.usuario) setusuario(context.usuario);

    if (phone) {
      setTimeout(function () {
        if (formAdd.current && formAdd.current.setData)
          formAdd.current.setData({
            telefone: phone.telefone,
          });
      }, 100);
    }
  }

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line
  }, [context.usuario, phone, modalVisible]);

  return (
    <Modal
      open={modalVisible}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <Form ref={formAdd} onSubmit={adicionarNum}>
        <div id="config">
          <div className="mr-5 mb-3">
            <h3 className="colorBackground mb-3">
              {phone ? "Editar" : "Novo"} Número
            </h3>
          </div>
          <div className="container-fluid mb-3 mt-4">
            <div className="row">
              <Input
                type="text"
                name="telefone"
                placeholder="99999999999 ou +99999999999"
                id="modal"
                className="inputPadrao"
              />
              <small className="mt-1 mb-1">Para números internacionais coloque + codigo do pais</small>
            </div>
          </div>
          <hr />
          <button type="submit" className="btn-main float-right">
            Salvar
          </button>
        </div>
      </Form>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    minWidth: "100px",
    width: "500px",
  },
};
