import React from 'react';
import {Modal} from 'react-responsive-modal';
import {toast} from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import api from '../../service/api';

export default function ModalPagamento({
  modalVisiblePagamento,
  plan,
  closeModal,
}) {
  async function checkoutPagame() {
    const checkout = new window.PagarMeCheckout.Checkout({
      encryption_key: 'ek_test_lBxq70uY4iIogN6TymYGCfnRion829',
      success: async function (data) {
        const response = await api.post('/v1/pagarme/assinar', {data, plan});
        if (response.data.success) {
          toast.success('Sucesso!', {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          if (response.data && response.data.boleto_url) {
            window.location.href = response.data.boleto_url;
          }
          closeModal();
        }
      },
      error: function (err) {
        console.log(err);
      },
      close: function () {
        console.log('The modal has been closed.');
      },
    });

    checkout.open({
      amount: plan.amount,
      buttonText: 'Pagar',
      buttonClass: 'button',
      customerData: 'true',
      createToken: 'false',
      paymentMethods: 'credit_card, boleto',
    });
  }

  return (
    <Modal
      open={modalVisiblePagamento}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <h3 className="mb-3">Pagamento do Plano</h3>
            <h5 className="mb-3">Plano: {plan.name}</h5>
            <h5>
              Preço:{' '}
              <CurrencyFormat
                className="h5"
                value={(plan.amount / 100) || 0}
                displayType={'text'}
                thousandSeparator="."
                decimalSeparator={','}
                prefix={'R$ '}
                decimalScale={2}
                fixedDecimalScale={true}
                renderText={(value) => (
                  <label className="label-normal">{value}</label>
                )}
              />
            </h5>

            <button
              id="pay-button"
              className="btn-main mt-2"
              onClick={checkoutPagame}
            >
              Ir para Pagamento
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: '1%',
    width: 800,
  },
};
