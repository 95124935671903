import firebase from 'firebase/app';
import 'firebase/messaging';   // for cloud messaging

const config = {
  apiKey: "AIzaSyCXWdyvJDEQGG0p1C3oUnivbBdQo-uiDTg",
  authDomain: "lembrazap.firebaseapp.com",
  databaseURL: "https://lembrazap.firebaseio.com",
  projectId: "lembrazap",
  storageBucket: "lembrazap.appspot.com",
  messagingSenderId: "251005711770",
  appId: "1:251005711770:web:7cf9318889a9b1aad17623",
  measurementId: "G-3CPNDGCL89"
};

let messaging;

if (firebase.messaging.isSupported()) {
  const initializedFirebaseApp = firebase.initializeApp(config);
  messaging = initializedFirebaseApp.messaging();

  messaging.usePublicVapidKey(
    "BD-6q6LuiRlvSsCUa0NUjqeSXNTjjkpkE76qrxnOhOvTZDPHM3y66ggbnOEjker_OMJPVrH-sCcCdWQh42FmIig"
  );
} else {
  messaging = false;
}

export { messaging };