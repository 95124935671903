/* eslint-disable no-sequences */
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { GlobalState } from "../../state/state";
import * as Yup from "yup";
import { Form } from "@unform/web";
import Input from "../../components/Input";
import Switch from 'react-switch';
import api from "../../service/api";
import "./style.css"

export default function ModalAddEditGrupo({
  modalVisibleAddEdit,
  grupo,
  closeModal,
  context,
}) {
  const formRef = useRef(null);
  const [zaps, setzaps] = useState([]);
  const [zap, setzap] = GlobalState("zap");
  const [isReadOnly, setIsReadOnly] = useState(false);
 
  async function handleSalvar(data) {
    formRef.current.setErrors([]);
    try {
      const schema = Yup.object().shape({
        formattedTitle: Yup.string().required("Titulo é obrigatório"),
        id: Yup.string().required("Id é obrigatório")
      });
      await schema.validate(data, { abortEarly: false });

      if (!zap) {
        toast.error("Selecione um número", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }

      data.zap = zap._id;
      data.usuario = context.usuario._id;
      data.isReadOnly = isReadOnly;

      let response;
      if (grupo) {
        response = await api.put(`/v1/grupo/${grupo._id}`, data);
      } else {
        response = await api.post("/v1/grupo", data);
      }

      if (response.data.success) {
        toast.success("Grupo salvo com sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        fecharModal();
      } else {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchData() {
    if (context && context.usuario && context.zaps) {
      setzaps(context.zaps);
    }

    if (grupo !== null) {
      console.log(grupo);
      setTimeout(function () {
        formRef.current.setData({
          formattedTitle: grupo && grupo.formattedTitle ? grupo.formattedTitle : "" ,
          id: grupo && grupo.id ?  grupo.id : "" ,
        });
      }, 300);
      if(grupo && grupo.isReadOnly) setIsReadOnly(grupo.isReadOnly);
    }
  }

  function fecharModal() {
    setIsReadOnly(false);
    closeModal();
  }

  function handleChangeChecked(checked) {
    setIsReadOnly(checked);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, grupo]);

  return (
    <Modal
      open={modalVisibleAddEdit}
      onClose={fecharModal}
      styles={customStyles}
      center
    >
      <div className='container'>
        <div className='row'>
          <div className='col-9 col-md-9'>
            {grupo && grupo.formattedTitle ? (
              <h3>Editar grupo</h3>
            ) : (
              <h3>Adicionar novo grupo</h3>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-6'>
            <label>Número vinculado</label> <br />
            <label>
              {zap ? zap.telefone : "Sem número vinculado"}
            </label>
          </div>
          <div className='col-sm-12 col-md-6'>
            <label>Selecionar Zap</label>
            <Select
              placeholder='Selecione um número'
              value={zap}
              defaultValue={zap}
              onChange={(e) => setzap(e)}
              options={zaps}
            />
          </div>
        </div>
        <hr />
        <Form className='mt-3' ref={formRef} onSubmit={handleSalvar}>
          <div className='row'>
            <div className='col-sm-12 col-md-4 mt-2 mb-2'>
              <Input
                type='text'
                className='input-form'
                name='formattedTitle'
                placeholder='Digite o titulo do grupo'
                label='Titulo do grupo'
              />
            </div>
            <div className='col-sm-12 col-md-4 mt-2 mb-2'>
            <Input
                type='text'
                className='input-form'
                name='id'
                placeholder='Digite o id do grupo'
                label='Id do grupo'
              />
            </div>
            <div className='col-sm-12 col-md-4 mt-2 mb-2'>
              <label>Somente leitura</label>
              <br />
              <Switch
                onChange={handleChangeChecked}
                checked={isReadOnly}
              />
            </div>
          </div>
          <hr />
          <button type='submit' className='btn-main float-right'>
            Salvar
          </button>
        </Form>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
  },
};
