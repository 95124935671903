/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import socket from "../../service/socketio";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";
import { Lightbox } from "react-modal-image";
import { toast } from "react-toastify";
import api from "../../service/api";
import Avatar from "react-avatar";
import Picker from "emoji-picker-react";
import { MdAttachFile, MdInsertEmoticon, MdAccessTime } from "react-icons/md";
import "./style.css";
import "../../themes/dark.css";

import img_avatar from "../../assets/imgs/avatar.png";

export default function Chat({ context, history, match }) {
  const [listchats, setlistchats] = useState([]);
  const [total, settotal] = useState(0);
  const [chat, setchat] = useState(null);
  const [mensagens, setmensagens] = useState([]);
  const [limit, setlimit] = useState(15);
  const [mensagem, setmensagem] = useState("");
  const [visibleMobile, setvisibleMobile] = useState(true);
  const [contato, setcontato] = useState(null);
  const [busca, setbusca] = useState("");
  const [modalImageVisible, setModalImageVisible] = useState(false);
  const [urlImage, setUrlImage] = useState("");
  const [usuario, setusuario] = useState(null);

  const [visibleEmojis, setvisibleEmojis] = useState(false);

  const scroll = useRef(null);
  const fileInput = useRef(null);

  async function sendMensagem() {
    if (!chat || !contato) {
      toast.error("Selecione uma conversa", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (!mensagem) {
      toast.error("Digite uma mensagem", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    const response = await api.post("/v1/chat/send", { chat, mensagem });
    if (response.data.success) {
      setmensagem("");
      await selecionandoConversa(chat);
      await fetchData();
      toast.success("Mensagem enviada com sucesso!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  async function handleUploadFile(event) {
    if (!chat || !contato) {
      toast.error("Selecione uma conversa", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (!event.target.files || !event.target.files[0]) {
      toast.error("Selecione um arquivo", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    let fd = new FormData();
    fd.append("folder", "arquivosWhats");
    fd.append("file", event.target.files[0]);

    const responseFile = await api.post("/upload/", fd, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (!responseFile.data.success && !responseFile.data.file) {
      return toast.error(responseFile.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }

    const response = await api.post("/v1/chat/send", {
      chat,
      arquivo: responseFile.data.file,
    });

    if (response.data.success) {
      await selecionandoConversa(chat);
      toast.success("Mensagem enviada com sucesso!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  async function selecionandoConversa(chat) {
    console.log("get mensagem")
    setvisibleMobile(false);
    const response = await api.get(`/v1/chat/getMensagens/${chat}`);
    setchat(chat);
    setcontato(response.data.contato);
    setmensagens(response.data.mensagens || []);
  }

  async function fetchData() {
    console.log("start");
    const response = await api.post(`/v1/chat/list`, {
      busca,
      limit,
    });
    settotal(response.data.total);
    setlistchats(response.data.data || []);
    setusuario(context.usuario);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, busca, context]);

  useEffect(() => {
    async function fetchMatchData() {
      if (!match.params.id) return;
      await selecionandoConversa(match.params.id);
    }

    fetchMatchData();
  }, [match.params.id]);

  async function chatSocket(data) {

    if (usuario && data.usuario._id.toString() === usuario._id.toString()) {
      if (chat && data.chat._id.toString() === chat.toString()) {
        setmensagens([...mensagens, data]);
      }
      await fetchData();
    }
  }

  useEffect(() => {
    if(usuario) {
      socket.on("msg", chatSocket);
      return () => {
        socket.off("msg");
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario, chat, mensagens]);

  function closeModalImage() {
    setModalImageVisible(false);
    setUrlImage("");
  }

  return (
    <>
      {modalImageVisible && urlImage && (
        <Lightbox medium={urlImage} hideZoom={true} onClose={closeModalImage} />
      )}
      <div id="chat">
        {visibleEmojis && (
          <Picker
            pickerStyle={{
              position: "absolute",
              bottom: 40,
              right: "41%",
              zIndex: 9999,
            }}
            onEmojiClick={(event, emojiObject) => {
              setmensagem(`${mensagem}${emojiObject.emoji} `);
              setvisibleEmojis(false);
            }}
          />
        )}

        <div className="container-fluid">
          <div className="pt-3 pb-3">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-xl-3 ajust-space-list">
                <div
                  ref={scroll}
                  onScroll={() => {
                    if (
                      scroll.current.scrollHeight - scroll.current.scrollTop <
                        900 &&
                      limit < total
                    ) {
                      setlimit(limit + 15);
                    }
                  }}
                  className={`list-chats ${
                    visibleMobile ? "d-block" : "d-none d-md-block"
                  }`}
                >
                  <div className="filter">
                    <input
                      type="text"
                      value={busca}
                      onChange={(e) => setbusca(e.target.value)}
                      className="input-search-white mt-1"
                      placeholder="Pesquise por nome ou telefone"
                    />
                  </div>
                  {listchats &&
                    listchats.map((el, i) => {
                      return (
                        <div
                          className={el._id === chat ? "chat chat-focus": "chat"}
                          onClick={async () => {
                            await selecionandoConversa(el._id);
                            setcontato(el.contato);
                            el.naoLidas = 0;
                          }}
                          key={i}
                        >
                          <div className="d-flex w-100">
                            <div className="pr-3">
                              <Avatar
                                size="30"
                                round={true}
                                name={
                                  el.contato && el.contato.nome
                                    ? el.contato.nome
                                    : ""
                                }
                                color="#0275d8"
                                src={
                                  el.contato && el.contato.profilePic
                                    ? `${process.env.REACT_APP_API_URL}/upload?mimetype=image/jpeg&filename=${el.contato.profilePic}&folder=arquivosWhats`
                                    : img_avatar
                                }
                              />
                            </div>
                            <div>
                              <label className="labeltext">
                                {el && el.contato && el.contato.nome
                                  ? el.contato.nome
                                  : "Sem Nome"}
                              </label>
                              <br />
                              <span className="labeltext">
                                {el.ultimaMensagem &&
                                el.ultimaMensagem.length < 1000 &&
                                !el.ultimaMensagem.match("/9j/")
                                  ? `${el.ultimaMensagem.substr(0, 35)}${
                                      el.ultimaMensagem.length > 35 ? "..." : ""
                                    }`
                                  : ""}
                              </span>
                            </div>
                            <div className="justify-control w-100">
                              <span className="labeltext pt-1 pr-1">
                                {el.dataMensagem
                                  ? moment(el.dataMensagem).format(
                                      "DD/MM HH:mm"
                                    )
                                  : "Sem Data"}
                              </span>
                              {el.naoLidas > 0 && (
                                <div>
                                  <small className="badge badge-success badge-pill">
                                    {el.naoLidas}
                                  </small>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {!visibleMobile && (
                  <div className="d-block d-sm-none">
                    <label
                      className="list-conversas-button"
                      role="button"
                      onClick={() => setvisibleMobile(true)}
                    >
                      Listar conversas
                    </label>
                  </div>
                )}
              </div>
              <div
                className={`col-sm-12 col-md-8 col-xl-9 ajust-space-chat ${
                  visibleMobile ? "d-none d-md-block" : "d-block"
                }`}
              >
                <div className="card-chat">
                  <div className="header">
                    <div className="row">
                      <div className="col-sm-12 col-md-8">
                        <span
                          style={{ cursor: "pointer" }}
                          rel="noreferrer"
                          alt="img"
                          className="mr-1"
                          onClick={() => {
                            setUrlImage(
                              contato && contato.profilePic
                                ? `${process.env.REACT_APP_API_URL}/upload?mimetype=image/jpeg&filename=${contato.profilePic}&folder=arquivosWhats`
                                : img_avatar
                            );
                            setModalImageVisible(true);
                          }}
                        >
                          <Avatar
                            size="35"
                            round={true}
                            name={contato && contato.nome ? contato.nome : ""}
                            color="#0275d8"
                            src={
                              contato && contato.profilePic
                                ? `${process.env.REACT_APP_API_URL}/upload?mimetype=image/jpeg&filename=${contato.profilePic}&folder=arquivosWhats`
                                : img_avatar
                            }
                          />
                        </span>
                        <label className="ml-2">
                          {contato
                            ? `${contato.nome.substr(0, 15)} - ${
                                contato.telefone
                              }`
                            : ``}
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <button
                          className={`btn btn-sm btn-danger float-right ${
                            visibleMobile ? "d-block" : "d-none d-md-block"
                          }`}
                          onClick={() => history.goBack()}
                        >
                          Fechar chat e voltar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="body">
                    <div>
                      {mensagens &&
                        mensagens.map((el, i) => {
                          if (el.type === "image") {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? "card-file-me float-right"
                                        : "card-file-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <img
                                            style={{ height: 75 }}
                                            alt="quotedmsg-imagechat"
                                            src={el.quotedMsg}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <span
                                      style={{ cursor: "pointer" }}
                                      rel="noreferrer"
                                      alt="img"
                                      className="mr-1"
                                      onClick={() => {
                                        setUrlImage(
                                          `${
                                            process.env.REACT_APP_API_URL
                                          }/upload?mimetype=${
                                            el.mimetype
                                          }&filename=${el.filename}&folder=${
                                            el.folder
                                              ? el.folder
                                              : "arquivosWhats"
                                          }`
                                        );
                                        setModalImageVisible(true);
                                      }}
                                    >
                                      <img
                                        style={{ height: 250 }}
                                        alt="img-fileimg"
                                        className="rounded mb-2"
                                        src={`${
                                          process.env.REACT_APP_API_URL
                                        }/upload?mimetype=${
                                          el.mimetype
                                        }&filename=${el.filename}&folder=${
                                          el.folder
                                            ? el.folder
                                            : "arquivosWhats"
                                        }`}
                                      />
                                    </span>
                                    {el.mensagem &&
                                      el.mensagem.length < 1000 &&
                                      !el.mensagem.match("/9j/") && (
                                        <label className="labeltext">{el.mensagem}</label>
                                      )}
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime
                                        className="mt-1"
                                        size={22}
                                        />
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (el.type === "video") {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? "card-file-me float-right"
                                        : "card-file-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <img
                                            style={{ height: 75 }}
                                            alt="quotedmsg-videochat"
                                            src={el.quotedMsg}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <ReactPlayer
                                      width="200px"
                                      controls
                                      url={`${
                                        process.env.REACT_APP_API_URL
                                      }/upload?mimetype=${
                                        el.mimetype
                                      }&filename=${el.filename}&folder=${
                                        el.folder ? el.folder : "arquivosWhats"
                                      }`}
                                    />
                                    {el.mensagem &&
                                      el.mensagem.length < 1000 &&
                                      !el.mensagem.match("/9j/") && (
                                        <label className="labeltext">{el.mensagem}</label>
                                      )}
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime
                                          className="mt-1"
                                          size={22}
                                        />
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (el.type === "document") {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? "card-file-me float-right"
                                        : "card-file-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <img
                                            style={{ height: 75 }}
                                            alt="quotedmsg-videodoc"
                                            src={el.quotedMsg}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <a
                                      rel="noreferrer"
                                      target="_blank"
                                      href={`${
                                        process.env.REACT_APP_API_URL
                                      }/upload?mimetype=${
                                        el.mimetype
                                      }&filename=${el.filename}&folder=${
                                        el.folder ? el.folder : "arquivosWhats"
                                      }`}
                                    >
                                      {el.originalname ? el.originalname : el.filename ? el.filename : 'Arquivo'}
                                    </a>
                                    {el.mensagem &&
                                      el.mensagem.length < 1000 &&
                                      !el.mensagem.match("/9j/") && (
                                        <label className="labeltext">{el.mensagem}</label>
                                      )}
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime className="mt-1" size={22}/>
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (el.type === "ptt" || el.type === "audio") {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? "card-file-me float-right"
                                        : "card-file-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <img
                                            style={{ height: 75 }}
                                            alt="quotedmsg-videoptt"
                                            src={el.quotedMsg}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <ReactAudioPlayer
                                      src={`${
                                        process.env.REACT_APP_API_URL
                                      }/upload?mimetype=${
                                        el.mimetype
                                      }&filename=${el.filename}&folder=${
                                        el.folder ? el.folder : "arquivosWhats"
                                      }`}
                                      controls
                                    />
                                    {el.mensagem &&
                                      el.mensagem.length < 1000 &&
                                      !el.mensagem.match("/9j/") && (
                                        <label className="labeltext">{el.mensagem}</label>
                                      )}
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime className="mt-1" size={22}/>
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (el.type === "sticker") {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? "card-file-me float-right"
                                        : "card-file-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <img
                                            style={{ height: 75 }}
                                            alt="quotedmsg-videosticker"
                                            src={el.quotedMsg}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <a
                                      target="blank"
                                      href={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
                                    >
                                      <img
                                        style={{ height: 150 }}
                                        alt="img-filesticker"
                                        className="rounded mb-2"
                                        src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.mimetype}&filename=${el.filename}&folder=arquivosWhats`}
                                      />
                                    </a>
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime className="mt-1" size={22}/>
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (el.type === "location") {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? el.ativo
                                          ? "card-file-me float-right"
                                          : "card-file-he float-right"
                                        : "card-file-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <img
                                            style={{ height: 75 }}
                                            alt="quotedmsg-videolocation"
                                            src={el.quotedMsg}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <a
                                      target="blank"
                                      href={`https://www.google.com/maps?q=${el.lat},${el.lng}&z=17&hl=pt-BR`}
                                    >
                                      <img
                                        style={{ height: 150 }}
                                        alt="img-filelocation"
                                        className="rounded mb-2"
                                        src={`data:image/jpeg;base64,${el.mensagem}`}
                                      />
                                    </a>
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime className="mt-1" size={22}/>
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                          if (
                            (el.type === "chat" || el.type === undefined) &&
                            el.mensagem
                          ) {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? "card-file-me float-right"
                                        : "card-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <>
                                            {el.quotedMsg.match(
                                              "data:image"
                                            ) && (
                                              <img
                                                style={{ height: 75 }}
                                                alt="quotedmsg-chat"
                                                src={el.quotedMsg}
                                              />
                                            )}
                                            {el.quotedMsg.match(
                                              "data:audio"
                                            ) && (
                                              <ReactAudioPlayer
                                                src={el.quotedMsg}
                                                controls
                                              />
                                            )}
                                          </>
                                        )}
                                      </div>
                                    )}
                                    <label className="labeltext">
                                      {el.mensagem &&
                                      el.mensagem.length < 1000 &&
                                      !el.mensagem.match("/9j/")
                                        ? el.mensagem
                                        : ""}
                                      {el.link && (
                                        <>
                                          <br />
                                          <a href={el.link}>{el.link}</a>
                                        </>
                                      )}
                                    </label>
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime className="mt-1" size={22}/>
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="row mt-2 mb-2" key={i}>
                                <div className="col-12">
                                  <div
                                    className={
                                      el.me
                                        ? "card-file-me float-right"
                                        : "card-file-he float-left"
                                    }
                                  >
                                    {el.quotedMsg && (
                                      <div className="card-resp">
                                        {el.quotedType === "chat" ? (
                                          <label className="labeltext">{el.quotedMsg}</label>
                                        ) : (
                                          <img
                                            style={{ height: 75 }}
                                            alt="quotedmsg-videodoc"
                                            src={el.quotedMsg}
                                          />
                                        )}
                                      </div>
                                    )}
                                    <a
                                      rel="noreferrer"
                                      target="_blank"
                                      href={`${
                                        process.env.REACT_APP_API_URL
                                      }/upload?mimetype=${
                                        el.mimetype
                                      }&filename=${el.filename}&folder=${
                                        el.folder ? el.folder : "arquivosWhats"
                                      }`}
                                    >
                                      {el.originalname ? el.originalname : el.filename ? el.filename : 'Arquivo'}
                                    </a>
                                    {el.mensagem &&
                                      el.mensagem.length < 1000 &&
                                      !el.mensagem.match("/9j/") && (
                                        <label className="labeltext">{el.mensagem}</label>
                                      )}
                                    <br />
                                    <span className="float-right labeltext">
                                      {!el.ativo ? (
                                        <MdAccessTime className="mt-1" size={22}/>
                                      ) : (
                                        <>
                                          {moment
                                            .unix(el.t)
                                            .format("DD/MM/YYYY HH:mm")}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                  <div className="footer">
                    <div className="d-flex">
                      <div className="w-10 pl-1">
                        <MdInsertEmoticon
                          onClick={() => setvisibleEmojis(!visibleEmojis)}
                          className="ml-1 mr-1"
                          size={22}
                        />
                        <MdAttachFile
                          className="mt-1"
                          size={22}
                          onClick={() => fileInput.current.click()}
                        />
                        <input
                          className="d-none"
                          ref={fileInput}
                          type="file"
                          onChange={handleUploadFile}
                        />
                      </div>
                      <div className="w-100">
                        <textarea
                          value={mensagem}
                          onChange={(e) => setmensagem(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") sendMensagem();
                          }}
                          placeholder="Digite uma mensagem"
                          className="input_chat"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
