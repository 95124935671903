/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import moment from "moment";
import api from "../../service/api";
import { AiOutlineCloudDownload } from "react-icons/ai";
import { GrClose } from "react-icons/gr";
import { GlobalState } from "../../state/state";
import { Form } from "@unform/web";
import Input from "../../components/Input";
import * as Yup from "yup";
import InputMask from "../../components/InputMaks";
import { toast } from "react-toastify";
import Checkbox from "../../components/CheckBox";
import Radio from "../../components/Radio";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Dropzone from "react-dropzone";
import "./style.css";
import Preview from "../../components/Preview/index";
import { ModalFalhas } from "./ModalFalhas";

export default function ImportCampanha({ context, history }) {
  const formRef = useRef(null);

  const [zap] = GlobalState("zap");
  const [zaps, setzaps] = useState([]);
  const [selecionados, setselecionados] = useState([]);

  const [preview, setpreview] = useState([]);
  const [file, setfile] = useState(false);
  const [particionarCampanha, setparticionarCampanha] = useState(true);
  const [repetirCampanha, setrepetirCampanha] = useState(false);
  const [usuario, setusuario] = useState(null);
  const [filtro, setfiltro] = useState("");
  const [falhas, setFalhas] = useState([]);
  const [modalFalhasVisible, setModalFalhasVisible] = useState(false);
  const [arquivo, setarquivo] = useState("");
  const [escolheMetodo] = useState(true);

  async function confirmSalvar(data) {
    confirmAlert({
      title: "Importar Campanha",
      message: `Salvar e criar campanha importada`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            await salvar(data);
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function salvar(data) {
    if (!file) {
      toast.error("Selecione um arquivo", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    if (!zap && !particionarCampanha && !repetirCampanha) {
      toast.error("Selecione um número no canto superior esquerdo", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (particionarCampanha && selecionados.length === 0) {
      toast.error("Selecione algum número ou selecionar todos", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    if (!usuario.permitirEnvio) {
      toast.error(
        "Envios estão indisponíveis no momento, logo mais volta a normalidade.",
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      return;
    }

    formRef.current.setErrors([]);
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required("Nome é obrigatório"),
        dataDisparo: Yup.string().required("Data do disparo é obrigatório"),
        horaDisparo: Yup.string().required("Hora do disparo é obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const dataEnvio = new FormData();

      const dataHoraDisparo = moment(
        `${data.dataDisparo} ${data.horaDisparo}`,
        "DD/MM/YYYY HH:mm"
      ).toDate();

      dataEnvio.append("file", file);
      dataEnvio.append("arquivo", JSON.stringify(arquivo));
      dataEnvio.append("dataHoraDisparo", dataHoraDisparo);
      dataEnvio.append("ignoraTrava", data.ignoraTrava);
      dataEnvio.append("permitirDuplicados", data.permitirDuplicados);
      dataEnvio.append("envioEmLote", data.envioEmLote);
      dataEnvio.append("metodo", data.metodo);
      dataEnvio.append("nome", data.nome);

      if (!particionarCampanha && !repetirCampanha) {
        dataEnvio.append("zap", zap._id);
      }
      
      dataEnvio.append("zaps", selecionados);

      const result = await api.post(particionarCampanha ? "/v1/campanhas/particionar-campanha" : "/v1/campanhas/gerar-campanha-xlsx",
        dataEnvio
      );

      if (!result.data.success) {
        toast.error(result.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }

      history.push("/campanhas");

      toast.success("Sucesso!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      if (result.data.falhas && result.data.falhas.length > 0) {
        setFalhas(result.data.falhas);
        setModalFalhasVisible(true);
        return;
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchData() {
    if (context.usuario) setusuario(context.usuario);
    if (context.zaps) setzaps(context.zaps);

    if (formRef.current && formRef.current.setData) {
      formRef.current.setData({
        nome: `${moment().format("x")} ${moment().format("DD/MM/YYYY HH:mm")}`,
        dataDisparo: moment().format("DD/MM/YYYY"),
        horaDisparo: moment().add(5, "m").format("HH:mm"),
        permitirDuplicados: true,
        envioEmLote: false,
        ignoraTrava: true,
      });
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [context.usuario]);

  function verdeOuVermelho(time) {
    const diff = moment().diff(moment(time), "seconds");
    if (diff > 160 || !time) return "danger";
    return "success";
  }

  function selecionadosControl(id, e) {
    if (selecionados.indexOf(id) > -1 && !e.target.checked) {
      selecionados.splice(selecionados.indexOf(id), 1);
    } else {
      selecionados.push(id);
    }
    setselecionados([...selecionados]);
  }

  async function previewCampanhaXLSX() {
    const data = new FormData();
    data.append("file", file);

    const response = await api.post(
      "/v1/campanhas/preview-campanha-xlsx",
      data
    );
    setpreview(response.data.data);
  }

  function selecionarOnlines() {
    if (selecionados.length !== 0) {
      setselecionados([]);
      return;
    }
    for (let i = 0; i < zaps.length; i++) {
      const zap = zaps[i];
      if (verdeOuVermelho(zap.lastPingWeb) === 'success') {
        selecionados.push(zap._id);
      }
    }
    setselecionados([...selecionados]);

    toast.success("Onlines Selecionados", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }

  function selecionarTodos() {
    if (selecionados.length >= zaps.length) {
      setselecionados([]);
      return;
    }
    for (let i = 0; i < zaps.length; i++) {
      const zap = zaps[i];
      selecionados.push(zap._id);
    }
    setselecionados([...selecionados]);

    toast.success("Todos Selecionados", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }

  function selectMaturados() {
    if (selecionados.length !== 0) {
      setselecionados([]);
      return;
    }
    for (let i = 0; i < zaps.length; i++) {
      const zap = zaps[i];
      if (!zap.maturando) {
        selecionados.push(zap._id);
      }
    }
    setselecionados([...selecionados]);

    toast.success("Maturados Selecionados", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }

  function selectMaturandos() {
    if (selecionados.length !== 0) {
      setselecionados([]);
      return;
    }
    for (let i = 0; i < zaps.length; i++) {
      const zap = zaps[i];
      if (zap.maturando) {
        selecionados.push(zap._id);
      }
    }
    setselecionados([...selecionados]);

    toast.success("Maturandos Selecionados", {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }

  function closeModal() {
    setModalFalhasVisible(false);
    history.push("/campanhas");
  }

  useEffect(() => {
    if (file) previewCampanhaXLSX();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if(particionarCampanha) {
      setrepetirCampanha(false);
    }
  }, [particionarCampanha]);

  useEffect(() => {
    if(repetirCampanha) {
      setparticionarCampanha(false);
    }
  }, [repetirCampanha]);

  return (
    <>
      <ModalFalhas
        closeModal={closeModal}
        modalVisible={modalFalhasVisible}
        falhas={falhas}
      />
      <div id="campanhas">
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop
                texto="Importar Campanha"
                title="importar-campanha"
                context={context}
              />
              <Form ref={formRef} onSubmit={confirmSalvar}>
                <div className="row">
                  <div className="col-sm-12 col-md-6">
                    <div className="card-content">
                      <div id="info-campanha">
                        <div className="row">
                          <div className="col-sm-12 col-md-6 mb-3">
                            <span>INFORMAÇÕES GERAIS</span>
                          </div>
                          <div className="col-sm-12 col-md-6 mb-3">
                            <div className="float-right">
                              <a href="https://dashboard.bestmessage.com.br/arquivo-modelo-lembrazap-campanha.xlsx">
                                <AiOutlineCloudDownload
                                  className="mr-2"
                                  size={18}
                                />{" "}
                                Download arquivo modelo importação
                              </a>
                            </div>
                          </div>
                        </div>
                        <label>Número vinculado</label>
                        <br />
                        <label>
                          {zap ? zap.telefone : "Sem número vinculado"}
                        </label>{" "}
                        <br />
                        <Input
                          type="text"
                          className="input-form mb-3"
                          name="nome"
                          placeholder="Dê um título para a campanha"
                          label="Título da campanhas"
                        />
                        <InputMask
                          mask="99/99/9999"
                          className="input-form mb-3"
                          name="dataDisparo"
                          placeholder="01/01/2021"
                          label="Data de disparo"
                        />
                        <InputMask
                          mask="99:99"
                          className="input-form mb-3"
                          name="horaDisparo"
                          placeholder="12:00"
                          label="Horário de disparo"
                        />
                        <label>Arquivo de anexo</label>
                        <Dropzone
                          onDrop={async (values) => {
                            let fd = new FormData();

                            fd.append("folder", "arquivosWhats");

                            values.map((file) => {
                              return fd.append("file", file);
                            });

                            const response = await api.post("/v1/upload", fd, {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            });
                            setarquivo(response.data.file);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <button type="button" className="btn-secondary">
                                  Enviar um arquivo
                                </button>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                        {arquivo && (
                          <>
                            <br></br>
                            {arquivo.mimetype === "image/jpeg" ||
                            arquivo.mimetype === "image/png" ? (
                              <img
                                style={{ height: 200 }}
                                alt="img"
                                className="img-thumbnail rounded"
                                src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${arquivo.mimetype}&filename=${arquivo.filename}&folder=arquivosWhats`}
                              />
                            ) : (
                              <label className="label-arquivo">
                                {arquivo &&
                                  `${arquivo.originalname} - pronto para envio`}{" "}
                              </label>
                            )}
                          </>
                        )}
                      </div>
                      <br />
                      <br />
                      <br />
                      <div id="importa-campanha">
                        <label>Arquivo da Campanha</label>
                        <Dropzone
                          onDrop={async (values) => {
                            setfile(values[0]);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className="bordernone mt-3 mb-3">
                              {file && (
                                <div className="arrasteAqui sucess-file text-center">
                                  <GrClose
                                    className="float-right pr-2 pt-2 h4"
                                    onClick={() => setfile(null)}
                                  />
                                  <p>{file.name}</p>
                                </div>
                              )}
                              {!file && (
                                <div
                                  className="bordernone mt-3 mb-3"
                                  {...getRootProps()}
                                >
                                  <input
                                    className="bordernone"
                                    {...getInputProps()}
                                    onChange={(e) => {
                                      setfile(e.target.files[0]);
                                    }}
                                  />
                                  <div className="arrasteAqui text-center">
                                    <p>Arraste ou selecione arquivo xlsx</p>
                                  </div>
                                </div>
                              )}
                            </section>
                          )}
                        </Dropzone>
                      </div>

                      <input
                        type="checkbox"
                        id="particionar"
                        checked={particionarCampanha}
                        onChange={() =>
                          setparticionarCampanha(!particionarCampanha)
                        }
                      />
                      <label htmlFor="particionar" className="ml-2">
                        Particionar campanha entre seus números
                      </label>
                      
                      <br/>

                      <input
                        type="checkbox"
                        id="repetir"
                        checked={repetirCampanha}
                        onChange={() =>
                          setrepetirCampanha(!repetirCampanha)
                        }
                      />
                      <label htmlFor="repetir" className="ml-2">
                        Repetir campanha entre seus números
                      </label>

                      {(particionarCampanha || repetirCampanha) && (
                        <p>
                          <b>Selecionados: { selecionados.length ? selecionados.length : 0 }</b>
                        </p>
                      )}

                      {(particionarCampanha || repetirCampanha) && (
                        <>
                          <div className="row">
                            <div className="col-sm-12 col-md-3">
                              <label
                                className="action naoSelecionavel mt-2"
                                onClick={() => selecionarTodos()}
                              >
                                Selecionar todos
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-3">
                              <label
                                className="action naoSelecionavel mt-2"
                                onClick={() => selecionarOnlines()}
                              >
                                Selecionar onlines
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-3">
                              <label
                                className="action naoSelecionavel mt-2"
                                onClick={() => selectMaturados()}
                              >
                                Selecionar Maturados
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-3">
                              <label
                                className="action naoSelecionavel mt-2"
                                onClick={() => selectMaturandos()}
                              >
                                Selecionar Maturandos
                              </label>
                            </div>
                            <div className="col-sm-12 col-md-12 mt-2 mb-2">
                              <input
                                className="inputPadrao"
                                onChange={(e) => setfiltro(e.target.value)}
                                placeholder="Pesquise número"
                              />
                            </div>
                          </div>
                          <div className="hiddenOverflow">
                            <table
                              className="table table-hover naoSelecionavel hiddenOverflow"
                              id="lista"
                            >
                              <thead>
                                <tr>
                                  <th scope="col"></th>
                                  <th scope="col">TELEFONE</th>
                                  <th scope="col">MATURANDO</th>
                                  <th scope="col">WEB</th>
                                </tr>
                              </thead>
                              <tbody>
                                {zaps
                                  .filter(
                                    (zap) =>
                                      zap.telefone &&
                                      zap.telefone.includes(filtro)
                                  )
                                  .map(function (el, i) {
                                    return (
                                      <tr
                                        onDoubleClick={(e) =>
                                          selecionadosControl(el._id, e)
                                        }
                                        key={i}
                                      >
                                        <td className="pointerTable">
                                          <div className="float-right">
                                            <input
                                              type="checkbox"
                                              checked={
                                                selecionados.indexOf(el._id) >
                                                -1
                                              }
                                              onChange={(e) =>
                                                selecionadosControl(el._id, e)
                                              }
                                              className="form-check-input"
                                            />
                                          </div>
                                        </td>
                                        <td className="pointerTable">
                                          {el.telefone
                                            ? el.telefone
                                            : "Sem telefone"}
                                        </td>

                                        <td className="pointerTable">
                                          {!el.maturando
                                            ? "MADURO"
                                            : "MATURANDO"}
                                        </td>
                                        <td className="pointerTable">
                                          <b
                                            className={`badge text-light bg-${verdeOuVermelho(
                                              el.lastPingWeb
                                            )}`}
                                          >
                                            {verdeOuVermelho(el.lastPingWeb) ===
                                            "danger"
                                              ? "OFFLINE"
                                              : "ONLINE"}
                                          </b>
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div id="previa-campanha">
                      <Preview preview={preview} file={file} />
                    </div>

                    {/* <div className="form-check mt-2">
                      <Checkbox
                        label="Repetir diariamente com novos contatos até o fim"
                        className="form-check-input"
                        name="repetirAteFim"
                      />
                    </div> */}

                    <div
                      className={
                        usuario && usuario.usaMassa
                          ? "form-check mt-2"
                          : "d-none"
                      }
                    >
                      <Checkbox
                        name="ignoraTrava"
                        className="form-check-input"
                        label="Ignorar Trava"
                      />
                    </div>

                    <div
                      className={
                        usuario && usuario.usaMassa
                          ? "form-check mt-2"
                          : "d-none"
                      }
                    >
                      <Checkbox
                        name="permitirDuplicados"
                        className="form-check-input"
                        label="Permitir duplicados (envio mesmo número)"
                      />
                    </div>
                    
                    <div
                      className={
                        usuario && usuario.usaMassa ? 'form-check' : 'd-none'
                      }
                    >
                      <Checkbox
                        name="envioEmLote"
                        className="form-check-input"
                        label="Envio imediato em lote"
                      />
                    </div>

                    {/* <div
                      className={
                        usuario && usuario.usaMassa ? 'form-check' : 'd-none'
                      }
                    >
                      <Checkbox
                        onClick={() =>
                          setescolheMetodo(
                            !formRef.current.getFieldValue('ignoraMassa')
                          )
                        }
                        name="ignoraMassa"
                        className="form-check-input"
                        label="Ignorar massa de contatos"
                      />
                    </div> */}

                    <div
                      className={
                        usuario && usuario.usaMassa && escolheMetodo
                          ? ""
                          : "d-none"
                      }
                    >
                      <br />
                      <label htmlFor="">Tipo de massa {escolheMetodo}</label>
                      <Radio
                        radioclass="form-check"
                        options={[{ id: "zap", label: "Zap Nossos" }]}
                        name="metodo"
                      ></Radio>
                    </div>

                    <button
                      id="salvar-campanha"
                      type="submit"
                      className="btn-main mt-2"
                    >
                      Salvar Campanha
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
