import React, { useRef } from "react";
import { Link } from "react-router-dom";
import api from "../../service/api";
import { login } from "../../service/auth";
import Logo from "../../assets/imgs/NEWlogo-preto.png";
import LogoBranca from "../../assets/imgs/NEWlogo-branco.png";
import Input from "../../components/Input";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import "./styles.css";

export default function Register({ context, history }) {
  const formRef = useRef(null);

  async function registrar(data) {
    try {
      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Email inválido")
          .required("Email é obrigatório"),
        password: Yup.string().required("Senha é obrigatório"),
        nome: Yup.string().required("Nome é obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      if (data.confirm_password !== data.password) {
        toast.error("As senhas não correspondem", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }
      const response = await api.post("/novo", data);
      if (!response.data.success) {
        toast.error(response.data.err, { position: toast.POSITION.TOP_CENTER });
        return;
      }
      toast.success("Registro feito com sucesso!", {
        position: toast.POSITION.TOP_CENTER,
      });
      login(response.data.token);
      context.setauthenticated(true);
      history.push("/");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  return (
    <div className="login-container" id="register">
      <Form ref={formRef} onSubmit={registrar}>
        <div className="col-12">
          <div className="text-center">
            <img src={Logo} alt="logo" className="w-100 authDisplayLogoPreta" />
            <img
              src={LogoBranca}
              alt="logo"
              className="w-100 authDisplayLogoBranca"
            />
          </div>
        </div>
        <Input name="nome" className="inputPadrao" autoComplete="name" type="text" label="Nome" />
        <Input
          name="email"
          className="inputPadrao"
          autoComplete="email"
          type="email"
          label="Email"
        />
        <Input
          type="password"
          className="inputPadrao"
          autoComplete="current-password"
          name="password"
          label="Senha"
        />
        <Input
          type="password"
          className="inputPadrao"
          autoComplete="off"
          name="confirm_password"
          label="Confirmar senha"
        />
        <button type="submit">Registrar</button>
        <Link to="/login" className="mt-5 labelSmall">
          {" "}
          Faça Login
        </Link>
      </Form>
    </div>
  );
}
