import React from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

export function ModalFalhas({ modalVisible, closeModal, falhas }) {
  return (
    <Modal
      open={modalVisible}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div id="config">
        <div className="mb-3">
          <h3 className="colorBackground mb-3 ml-2 mr-2">
            Falhas na distruibuição
          </h3>
        </div>
        <div className="container-fluid mb-3">
          <div className="row">
              {falhas.length > 0 && (
                <>
                  <div className="col-12 text-center mb-3">
                    <span className="text-danger">
                      {falhas.length} falha{falhas.length > 1 ? "s" : ""} ao distribuir campanha, verifique as etapas abaixo!
                    </span>
                  </div>

                  {falhas.map((el, i) => {
                    return (
                      <div
                        key={i}
                        className="col-md-4 text-center"
                      >
                        <span className="text-dark">{el.zap.telefone} - envios hoje: TODO</span>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        <hr />
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "0%",
    minWidth: "150px",
  },
};





