import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import ReactPaginate from "react-paginate";
import useQuery from "../../core/useQuery";
import { AiOutlineCloudDownload } from "react-icons/ai";
import api from "../../service/api";
import { getToken } from "../../service/auth";
import { confirmAlert } from "react-confirm-alert";
import Skeleton from "react-loading-skeleton";
import ModalImportarContatosDB from "./ModalImportarContatosDB";
import ModalAddEditContatoDB from "./ModalAddEditContatoDB";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

export default function ContatosDB({ context, history }) {
  const [loading, setLoading] = useState(true);
  const token = getToken();
  const [busca, setbusca] = useState("");
  const [usados, setusados] = useState(false);

  const [lista, setlista] = useState([]);
  const [total, settotal] = useState(0);
  const query = useQuery();
  const limit = 15;
  const skip = parseInt(query.get("page") || 0) * limit;

  const [pageCount, setPageCount] = useState(0);
  const [contato, setcontato] = useState(null);

  const [selecionados, setselecionados] = useState([]);

  const [modalVisibleAddEdit, setmodalVisibleAddEdit] = useState(false);
  const [modalVisibleImportar, setmodalVisibleImportar] = useState(false);

  async function fetchData() {
    const response = await api.post(`/v1/contatosdb/list`, {
      usados,
      busca,
      skip,
      limit,
    });
    setPageCount(response.data.total / limit);
    settotal(response.data.total);
    setlista(response.data.data);
    setLoading(false);
  }

  async function excluirSelect() {
    confirmAlert({
      title: "Excluir Contatos",
      message: `Excluir todos os contatos selecionados ?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/contatosdb/array", {
              contatos: selecionados,
            });

            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function excluirAll() {
    confirmAlert({
      title: "Excluir Contatos Via Base",
      message: `Excluir todos os contatos viabase?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/contatosdb/all");
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [busca, usados, skip]);

  async function markAsUnsent() {
    confirmAlert({
      title: "Marca todos como não enviados",
      message: `Marcar todos como não enviados?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/contatosdb/mark-as-unsent");
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  function fetchPagination(data) {
    history.push(`/contatosdb?page=${data.selected || 0}`);
  }

  function selecionadosControl(id, e) {
    if (selecionados.indexOf(id) > -1 && !e.target.checked) {
      selecionados.splice(selecionados.indexOf(id), 1);
    } else {
      selecionados.push(id);
    }
    setselecionados([...selecionados]);
  }

  function closeModal() {
    setmodalVisibleAddEdit(false);
    setmodalVisibleImportar(false);
    setcontato(null);
    fetchData();
  }

  return (
    <>
      <ModalAddEditContatoDB
        modalVisibleAddEdit={modalVisibleAddEdit}
        contato={contato}
        closeModal={closeModal}
      />
      <ModalImportarContatosDB
        modalVisibleImportar={modalVisibleImportar}
        closeModal={closeModal}
      />
      <div id='contatos'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-sm-12 col-lg-2 mb-5'>
              <Menu context={context} />
            </div>
            <div className='col-sm-12 col-lg-10'>
              <AreaTop
                texto='Base Automática'
                title='contatos'
                context={context}
              />
              <div className='area_body'>
                <div id='busca'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        className='btn-main mt-auto w-100'
                        onClick={() => setmodalVisibleAddEdit(true)}
                      >
                        Novo Contato
                      </button>
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        className='btn-main mt-auto w-100'
                        onClick={() => setmodalVisibleImportar(true)}
                      >
                        Importar Banco
                      </button>
                    </div>
                    <div className='col-sm-12 col-md-5 mt-2 mb-2'>
                      <input
                        type='text'
                        value={busca}
                        onChange={(e) => setbusca(e.target.value)}
                        className='input-search-white mt-1'
                        placeholder='Pesquise um contato no banco'
                      />
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-2 d-flex'>
                      <button
                        onClick={() => fetchData()}
                        className='btn-main mt-auto w-100'
                      >
                        Buscar
                      </button>
                    </div>
                    <div className='col-sm-12 col-md-3 mt-2 mb-2'>
                      <a
                        rel='noreferrer'
                        className='pt-3'
                        href={`${process.env.REACT_APP_API_URL}/v1/contatosdb/exportar/csv?token=${token}`}
                        target='_blank'
                      >
                        <AiOutlineCloudDownload className='mr-2' size={18} />
                        Fazer download de todos os contatos
                      </a>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-2 mt-2 mb-1'>
                      <label>Total de {total} contatos </label>
                    </div>
                    <div className='col-sm-12 col-md-6 mt-2 mb-1'>
                      <div className='form-check'>
                        <input
                          type='checkbox'
                          checked={usados}
                          onChange={() => setusados(!usados)}
                          className='form-check-input'
                        />
                        <label
                          className='form-check-label'
                          onClick={() => setusados(!usados)}
                        >
                          Enviados
                        </label>
                      </div>
                      {usados &&( 
                        <div className='mb-1'>
                          <label
                            className='action float-left'
                            role='button'
                            onClick={() => markAsUnsent()}
                          >
                            Marcar todos como não enviados
                          </label>
                        </div>
                    )}
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-1'>
                      <label
                        className='action float-right'
                        role='button'
                        onClick={() => excluirSelect()}
                      >
                        Remover Selecionados
                      </label>
                    </div>
                    <div className='col-sm-12 col-md-2 mt-2 mb-1'>
                      <label
                        className='action text-danger float-right'
                        role='button'
                        onClick={() => excluirAll()}
                      >
                        Remover Todos os contatos
                      </label>
                    </div>
                  </div>
                </div>
                <div className='row' id='tabelaContatos'>
                  <div className='col-sm-12 col-md-12  mt-2 mb-1'>
                    <div className='card-content table-responsive'>
                      <table className='table table-hover' id='lista'>
                        <thead>
                          <tr>
                            <th scope='col'></th>
                            <th scope='col'>NOME</th>
                            <th scope='col'>TELEFONE</th>
                            <th scope='col'>ENVIADO</th>
                            <th scope='col'></th>
                          </tr>
                        </thead>
                        <tbody>
                          {lista.map((el) => {
                            return (
                              <tr
                                onDoubleClick={(e) =>
                                  selecionadosControl(el._id, e)
                                }
                                key={el._id}
                              >
                                <td className='pointerTable'>
                                  <div className='float-right'>
                                    <input
                                      type='checkbox'
                                      checked={
                                        selecionados.indexOf(el._id) > -1
                                      }
                                      onChange={(e) =>
                                        selecionadosControl(el._id, e)
                                      }
                                      className='form-check-input'
                                    />
                                  </div>
                                </td>
                                <td className='pointerTable'>
                                  {el.nome ? el.nome : "Sem nome"}
                                </td>
                                <td className='pointerTable'>
                                  {el.telefone ? el.telefone : "Sem telefone"}
                                </td>
                                <td className='pointerTable'>
                                  {el.usado ? "SIM" : "NÃO"}
                                </td>
                                <td
                                  className='detalhes'
                                  onClick={() => {
                                    setcontato(el);
                                    setmodalVisibleAddEdit(true);
                                  }}
                                >
                                  Editar
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                      {lista.length > 0 && (
                        <ReactPaginate
                          previousLabel='<'
                          nextLabel='>'
                          breakLabel='...'
                          breakClassName='page-item disabled'
                          breakLinkClassName='page-link'
                          disabledClassName='disabled'
                          pageCount={pageCount}
                          pageClassName='page-item'
                          previousClassName='page-item'
                          pageLinkClassName='page-link'
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName='page-link'
                          nextLinkClassName='page-link'
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName='pagination'
                          subContainerClassName='pages'
                          activeClassName='active'
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
