/* eslint-disable no-sequences */
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { GlobalState } from "../../state/state";
import { GrClose } from "react-icons/gr";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { Form } from "@unform/web";
import Input from "../../components/Input";
import api from "../../service/api";

export default function ModalAddEditLead({
  modalvisible,
  setmodalvisible,
  listLead,
  setListLead,
  context,
  fetchData,
}) {
  const formRef = useRef(null);
  const [zaps, setzaps] = useState([]);
  const [arquivo, setarquivo] = useState(null);
  const [zap, setzap] = GlobalState("zap");
  const [mensagemRecepcao, setmensagemRecepcao] = useState("");
  const [mensagemCaptura, setmensagemCaptura] = useState("");
  const [mensagemFinal, setmensagemFinal] = useState("");
  const [mensagemFind, setmensagemFind] = useState('QUERO RECEBER');

  async function handleSalvar(data) {
    formRef.current.setErrors([]);
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required("Nome é obrigatório"),
      });
      await schema.validate(data, { abortEarly: false });

      if (!zap) {
        toast.error("Selecione um número", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }

      data.zap = zap._id;
      data.arquivo = arquivo;
      data.usuario = context.usuario._id;
      data.mensagemRecepcao = mensagemRecepcao;
      data.mensagemCaptura = mensagemCaptura;
      data.mensagemFinal = mensagemFinal;
      data.mensagemFind = mensagemFind;
      
      if (listLead === null) {
        const response = await api.post("/v1/lead/list/new", data);

        if (response.data.success) {
          toast.success("Captura salva com sucesso!", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          closeModal();
        } else {
          toast.error(response.data.err, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      } else {
        data.qtdLead = listLead.qtdLead;
        const response = await api.put(
          `/v1/lead/list/edit/${listLead._id}`,
          data
        );

        if (response.data.success) {
          toast.success("Captura salva com sucesso!", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          context.updateState();
          closeModal();
        } else {
          toast.error(response.data.err, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchDataModal() {
    if (context && context.zaps) {
      setzaps(context.zaps);
    }
  }

  useEffect(() => {
    fetchDataModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  useEffect(() => {
    function fetchData() {
      if (context.usuario) {
        setmensagemRecepcao(context.usuario.configLead.mensagemRecepcao);
        setmensagemCaptura(context.usuario.configLead.mensagemCaptura);
        setmensagemFinal(context.usuario.configLead.mensagemFinal);
        setmensagemFind(context.usuario.configLead.mensagemFind);
      }
      if (listLead !== null) {
        setTimeout(function () {
          formRef.current.setData({
            nome: listLead.nome,
          });
        }, 500);
        setmensagemRecepcao(listLead.mensagemRecepcao);
        setmensagemCaptura(listLead.mensagemCaptura);
        setmensagemFinal(listLead.mensagemFinal);
        setmensagemFind(listLead.mensagemFind);
        setarquivo(listLead.arquivo);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, listLead]);

  function closeModal() {
    setmodalvisible(false);
    setListLead(null);
    setarquivo(null);
    fetchData();
  }

  return (
    <Modal
      open={modalvisible}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div className="container">
        <div className="row">
          <div className="col-9 col-md-9">
            <h3>
              {listLead !== null
                ? "Editar lista de leads"
                : "Adicionar uma nova lista"}
            </h3>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-6">
            <label>Número vinculado</label> <br />
            <label>
              {zap ? zap.telefone : "Sem número vinculado"}
            </label>
          </div>
          <div className="col-sm-12 col-md-6">
            <label>Selecionar Zap</label>
            <Select
              placeholder="Selecione um número"
              value={zap}
              defaultValue={zap}
              onChange={(e) => setzap(e)}
              options={zaps}
            />
          </div>
        </div>
        <hr />
        <Form className="mt-3" ref={formRef} onSubmit={handleSalvar}>
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-2 mb-2">
              <Input
                type="text"
                className="input-form"
                name="nome"
                placeholder="Ebook Emagreça já"
                label="Nome da lista de captura"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-2 mb-2">
              <label>Mensagem recepção lead</label>
              <textarea
                label="Mensagem recepção lead"
                placeholder="Exemplo: Otimo ver que vem tem interesse em receber mais sobre meu conteúdo, adicione esse contato {#zap#} na sua agenda e digite *OK* que o ebook será encaminhado para você"
                rows={4}
                className="textarea-form mb-3"
                value={mensagemRecepcao}
                onChange={(e) => setmensagemRecepcao(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-2 mb-2">
              <label>Mensagem captação lead</label>
              <textarea
                placeholder={`Exemplo: Bom dia [nome]!\n\nSegue em anexo seu ebook, que ele agrege muito em seu aprendizado e não deixe de enviar seu review.\n\nBons estudos!!`}
                rows={4}
                className="textarea-form mb-3"
                value={mensagemCaptura}
                onChange={(e) => setmensagemCaptura(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-2 mb-2">
              <label>Mensagem Final</label>
              <textarea
                placeholder={`Exemplo: Caso tenha interesse em receber novos conteúdos sobre digite *SIM*`}
                rows={4}
                className="textarea-form mb-3"
                value={mensagemFinal}
                onChange={(e) => setmensagemFinal(e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 mt-2 mb-2">
              <label>Mensagem Whatsapp</label>
              <select className="select-find-lead" value={mensagemFind} onChange={(e) => setmensagemFind(e.target.value)}>
                <option value="QUERO RECEBER">QUERO RECEBER</option>
                <option value="QUERO PARTICIPAR">QUERO PARTICIPAR</option>
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <label>Arquivo ou Ebook de resposta automática</label>
              <Dropzone
                onDrop={async (values) => {
                  let fd = new FormData();

                  fd.append("folder", "arquivosWhats");

                  values.map((file) => {
                    return fd.append("file", file);
                  });

                  const response = await api.post("/v1/upload", fd, {
                    headers: { "Content-Type": "multipart/form-data" },
                  });

                  setarquivo(response.data.file);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="bordernone mt-1 mb-3">
                    {arquivo && (
                      <div className="arrasteAqui sucess-file text-center">
                        <GrClose
                          className="float-right pr-2 pt-2 h4"
                          onClick={() => setarquivo(null)}
                        />
                        <p>{arquivo.originalname}</p>
                      </div>
                    )}
                    {!arquivo && (
                      <div className="bordernone mt-1 mb-3" {...getRootProps()}>
                        <input className="bordernone" {...getInputProps()} />
                        <div className="arrasteAqui text-center">
                          <p>Arraste ou selecione arquivo ou ebook</p>
                        </div>
                      </div>
                    )}
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
          <button type="submit" className="btn-main float-right">
            Salvar lista
          </button>
        </Form>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    width: "700px",
  },
};
