import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Darkmode from 'darkmode-js';
import './themes/dark.css';
import {registerServiceWorker} from './utils/serviceWorker';
import api from './service/api';
import {messaging} from './utils/firebaseUtils';
import {isAuthenticated, getUser} from './service/auth';

const options = {
  bottom: '5px',
  left: '5px', 
  backgroundColor: '#f7f7fa',
  label: '🌓',
};

export const darkmode = new Darkmode(options);
darkmode.showWidget();

if (isAuthenticated() && messaging) {
  messaging
    .requestPermission()
    .then(async function () {
      try {
        const token = await messaging.getToken();
        const usuario = await getUser();
        var tokenFire = usuario.token_firebase;
        if(tokenFire){
          var tokenTrue = tokenFire.indexOf(token);
          if (tokenTrue < 0) {
            tokenFire.push(token);
            await api.put(`v1/users/`, {token_firebase: tokenFire});
          }
        }
      } catch (error) {}
    })
    .catch(function (err) {});
}

if (messaging) {
  messaging.onMessage((payload) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
    };

    if (Notification.permission === 'granted') {
      var notification = new Notification(
        notificationTitle,
        notificationOptions
      );
      notification.onclick = function (event) {
        event.preventDefault();
        window.open(payload.notification.click_action, '_blank');
        notification.close();
      };
    }
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

registerServiceWorker();
