import React, { useRef, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import api from "../../service/api";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import Input from "../../components/Input";

export default function ModalEditNumero({
  modalVisible,
  closeModal,
  campanha,
}) {
  const formAdd = useRef(null);

  async function adicionarNum(data) {

    formAdd.current.setErrors({});
    try {
      let schema = Yup.object().shape({
        contatosAdicionais: Yup.string().required("Contatos Adicionais é obrigatório"),
      });
      await schema.validate(data, { abortEarly: false });
      if (campanha) {
        data._id = campanha._id;
        const response = await api.put(`/v1/campanhas`, data);
        if (!response.data.success) {
          toast.error(response.data.err, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          return;
        }
        toast.success("Sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        closeModal();
      } else {
        toast.error("Selecione uma campanha", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formAdd.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchDatas() {

    if (campanha) {
      setTimeout(function () {
        if (formAdd.current && formAdd.current.setData)
          formAdd.current.setData({
            contatosAdicionais: campanha.contatosAdicionais,
          });
      }, 100);
    }
  }

  useEffect(() => {
    fetchDatas();
    // eslint-disable-next-line
  }, [modalVisible]);

  return (
    <Modal
      open={modalVisible}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <Form ref={formAdd} onSubmit={adicionarNum}>
        <div id="config">
          <div className="mr-5 mb-3">
            <h3 className="colorBackground mb-3">
              Contatos Adicionais
            </h3>
          </div>
          <div className="container-fluid mb-3 mt-4">
            <div className="row">
              <Input
                type="number"
                name="contatosAdicionais"
                className="inputPadrao"
              />
            </div>
          </div>
          <hr />
          <button type="submit" className="btn-main float-right">
            Salvar
          </button>
        </div>
      </Form>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
    minWidth: "100px",
    width: "500px",
  },
};
