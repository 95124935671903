import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import "./styles.css";
import CurrencyFormat from "react-currency-format";
import ModalPagamento from "./ModalPagamento";
import api from "../../service/api";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";

export default function Assinatura({ context, history }) {
  const [assinatura, setAssinatura] = useState(null);
  const [plan, setPlan] = useState({});
  const [plans, setPlans] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchData = useCallback(async () =>  {
    const response = await api.get("/v1/pagarme/assinatura");
    if (response.data.success) {
      setAssinatura(response.data.data);
    } else {
      setAssinatura(false);
      const responsePlan = await api.get(`/v1/pagarme/plans`);
      if (responsePlan.data.success) {
        let plans = [];
        responsePlan.data.data.map((el) => {
          if (el.name && el.name.match("LZ")) plans.push(el);
          return el
        });
        setPlans(plans);
      }
    }
  }, [setAssinatura, setPlans]);

  async function handleCanceledPlan() {
    confirmAlert({
      title: "Cancelar Assinatura",
      message: `Deseja cancelar assinatura, esse processo é irreversível?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/pagarme/cancelar");

            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            context.updateState();
            await fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  function setStatus(status) {
    let newStatus = status;
    switch (status) {
      case "paid":
        newStatus = "Pago";
        break;
      case "waiting_payment":
        newStatus = "Esperando Pagamento";
        break;
      case "processing":
        newStatus = "Processando";
        break;
      case "authorized":
        newStatus = "Autorizado";
        break;
      default:
        break;
    }
    return newStatus;
  }

  function selectPlan(el) {
    setPlan(el);
    setModalVisible(true);
  }

  function closeModal() {
    fetchData();
    setModalVisible(false);
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://assets.pagar.me/checkout/1.1.0/checkout.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ModalPagamento
        closeModal={closeModal}
        modalVisiblePagamento={modalVisible}
        plan={plan}
        context={context}
      />
      <div id="Planos">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop texto="Planos" title="planos" context={context} />
              <div className="area_body">
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div id="plano-selecionado" className="card-content">
                      <div id="atual">
                        <h3>Assinatura</h3>
                        {assinatura && assinatura.plan ? (
                          <div>
                            <label className="label-normal">
                              Plano: {assinatura.plan.name.replace("LZ_", "")}
                            </label>
                            <br />
                            <label className="label-normal">
                              Status:{" "}
                              {setStatus(assinatura.current_transaction.status)}
                            </label>
                            {assinatura.current_transaction.boleto_url && (
                              <>
                                <br />
                                <label className="label-normal">
                                  Boleto:{" "}
                                  <a
                                    href={
                                      assinatura.current_transaction.boleto_url
                                    }
                                  >
                                    {assinatura.current_transaction.boleto_url}
                                  </a>
                                </label>
                              </>
                            )}
                            {assinatura.current_period_end && (
                              <>
                                <br />
                                <label className="label-normal">
                                  Data de vencimento:
                                  {moment(assinatura.current_period_end).format(
                                    "DD/MM/YYYY"
                                  )}
                                </label>
                              </>
                            )}
                          </div>
                        ) : (
                          <>
                            <br />
                            <label className="label-normal">
                              <b>
                                Nenhuma assinatura encontrada, selecione um
                                plano para fazer uso de nossos serviços!
                              </b>
                            </label>
                          </>
                        )}
                        {assinatura && (
                          <button
                            className="btn btn-main"
                            onClick={() => handleCanceledPlan()}
                          >
                            Cancelar Plano
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!assinatura && (
                  <div className="row mt-2">
                    <div className="col-sm-12 col-md-12">
                      <div id="assinatura" className="card-content">
                        <h3>Planos Disponiveis</h3>
                        <div className="row">
                          {plans &&
                            plans.map((el, i) => {
                              if (i < 5)
                                return (
                                  <div
                                    key={i}
                                    className="col-sm-12 col-md-6 mt-1 mb-1"
                                  >
                                    <div className="card-options">
                                      <label className="label-normal">
                                        {el.name.replace("LZ_", "")}
                                      </label>
                                      <br />
                                      <CurrencyFormat
                                        className="h5"
                                        value={el.amount / 100 || 0}
                                        displayType={"text"}
                                        thousandSeparator="."
                                        decimalSeparator={","}
                                        prefix={"R$ "}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        renderText={(value) => (
                                          <label className="label-normal">
                                            {value}
                                          </label>
                                        )}
                                      />
                                      <br />
                                      <button
                                        onClick={() => selectPlan(el)}
                                        className="btn-main mt-2"
                                      >
                                        Selecionar
                                      </button>
                                    </div>
                                  </div>
                                );
                              return el
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
