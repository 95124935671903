import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import Skeleton from "react-loading-skeleton";
import { GlobalState } from "../../state/state";
import api from "../../service/api";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import useQuery from "../../core/useQuery";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert";
import "./style.css";
import moment from "moment";

export default function Obras({ context, history }) {
  const [loading, setLoading] = useState(false);
  const [zap] = GlobalState("zap");
  const [scheduledList, setScheduledList] = useState([]);
  const [search, setSearch] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const query = useQuery();
  const limit = 30;
  const skip = parseInt(query.get("page") || 0) * limit;

  function fetchPagination(data) {
    history.push(`/obras?page=${data.selected || 0}`);
  }

  async function handleRemoveCampanha(data) {
    confirmAlert({
      title: "Remover Campanha Obra",
      message: `Essa ação irá cancelar o envio da obra ${data.nome}!`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.delete(`/v1/obras/${data._id}`);
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });

            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function handleGeraCampanha(obra) {
    confirmAlert({
      title: "Gerar Campanha Obra",
      message: `Essa ação irá enviar obra ${obra.nome}! Deseja continuar?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post(
              `/v1/obras/gerar-envio/${obra._id}`,
              {
                zap,
              }
            );
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });

            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function fetchData() {
    const response = await api.post(`/v1/obras/list`, {
      search,
      skip,
      limit,
    });

    setScheduledList(response.data.data);
    setPageCount(response.data.total / limit);
    setLoading(false);
  }


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [skip]);

  return (
    <>
      <div id="obras">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop texto="Obras" title="obras" context={context} />
              <div className="area_body">
                <div className="row mb-2">
                  <div className="col-sm-12 col-lg-2 mb-2 d-flex">
                    <button
                      onClick={() => history.push(`/obras/novo`)}
                      className="btn btn-main mt-auto w-100"
                    >
                      Criar Obra
                    </button>
                  </div>
                  <div className="col-sm-12 col-lg-5 mt-2 mb-2">
                    <input
                      type="text"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={(e) => e.key === "Enter" && fetchData()}
                      className="input-search"
                      placeholder="Pesquise pelo nome da obra"
                    />
                  </div>
                  <div className="col-sm-12 col-lg-2 mb-2 d-flex">
                    <button
                      onClick={() => fetchData()}
                      className="btn btn-main mt-auto w-100"
                    >
                      Buscar
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12">
                    <div
                      id="obras-enviadas"
                      className="card-content table-responsive"
                    >
                      <table className="table table-hover" id="lista">
                        <thead>
                          <tr>
                            <th scope="col">NOME</th>
                            <th scope="col">CONTATOS</th>
                            <th scope="col">DATA</th>
                            <th scope="col">OPÇÕES</th>
                          </tr>
                        </thead>
                        <tbody>
                          {scheduledList.map(function (el, i) {
                            return (
                              <tr key={i} htmlFor={i}>
                                <td className="pointerTable">
                                  {el.nome ? el.nome : "Sem nome"}
                                </td>
                                <td className="pointerTable">
                                  {el.contatos ? el.contatos.length : 0}
                                </td>
                                <td className="pointerTable">
                                  {el.data
                                    ? moment(el.data).format(
                                        "DD/MM/YYYY HH:mm"
                                      )
                                    : "Sem Data"}
                                </td>
                                <td className="pointerTable">
                                  <button
                                    className="btn btn-danger ml-1"
                                    onClick={() => handleRemoveCampanha(el)}
                                  >
                                    Remover
                                  </button>
                                  <button
                                    className="btn btn-info ml-1"
                                    onClick={() => {
                                      history.push(`/obras/alterar/${el._id}`);
                                    }}
                                  >
                                    Editar
                                  </button>
                                  <button
                                    className="btn btn-warning ml-1"
                                    onClick={() => handleGeraCampanha(el)}
                                  >
                                    Gerar Envio
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                      {scheduledList.length > 0 && (
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakClassName="page-item disabled"
                          breakLinkClassName="page-link"
                          disabledClassName="disabled"
                          pageCount={pageCount}
                          pageClassName="page-item"
                          previousClassName="page-item"
                          pageLinkClassName="page-link"
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                          activeClassName="active"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
