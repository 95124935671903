import React, {useEffect, useRef, useState} from 'react';
import Menu from '../../components/Menu/index';
import AreaTop from '../../components/AreaTop/index';
import {Form} from '@unform/web';
import Input from '../../components/Input';
import * as Yup from 'yup';
import './style.css';
import {toast} from 'react-toastify';
import { GlobalState } from "../../state/state";
import ModalEditBlacklist from './ModalEditBlacklist';
import ModalSenha from './ModalSenha';
import api from '../../service/api';
import ReactPaginate from 'react-paginate';
import Tab from '../../components/Tab';
import Tabs from '../../components/Tabs/index';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import {confirmAlert} from 'react-confirm-alert';
import TagsInput from 'react-tagsinput';
import Switch from 'react-switch';
import 'react-tagsinput/react-tagsinput.css';
import Highlight from 'react-highlight';
import InputMask from 'react-input-mask';
import {AiOutlineCloudDownload} from 'react-icons/ai';

let intevaloQr;

export default function Configuracoes({context, history}) {
  const formConta = useRef(null);
  const fileInput = useRef();
  const [zap, ] = GlobalState("zap");
  const [blacklist, setblacklist] = useState([]);
  const [ativa, setativa] = useState(0);
  const [selecionados, setselecionados] = useState([]);
  const [numeroedit, setnumeroedit] = useState('');
  const [permissaoNotif, setpermissaoNotif] = useState(false);
  const [sincronizacaoAuto, setSincronizacaoAuto] = useState(false);
  const [token, setToken] = useState('');

  const [iniciado, setiniciado] = useState(false);
  const [qr, setqr] = useState({qr: '', lastUpdate: false});

  const [tags, settags] = useState([]);
  const [encaminharRespostas, setencaminharRespostas] = useState([]);

  //pagination
  const limit = 25;
  const [skip, setSkip] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  // modals
  const [modalEditBlacklistVisible, setmodalEditBlacklistVisible] =
    useState(false);
  const [modalSenha, setmodalSenha] = useState(false);

  async function salvar(data) {
    formConta.current.setErrors({});
    try {
      let schema = Yup.object().shape({
        dddpadrao: Yup.string().required('DDD é obrigatório'),
        intervaloEntreMsgs: Yup.string().required('Um intervalo é obrigatório'),
        nome: Yup.string().required('nome é obrigatório'),
        email: Yup.string().required('email é obrigatório'),
      });
      await schema.validate(data, {abortEarly: false});

      if(data.dddpadrao.substring(0, 1) === '0'){
        data.dddpadrao = data.dddpadrao.substring(1);
      }

      const response = await api.put(`v1/users/`, {
        nome: data.nome,
        encaminharRespostas: encaminharRespostas,
        sincronizacaoAuto,
        email: data.email,
        dddpadrao: data.dddpadrao.toString().replace(/\D/gim, ''),
        intervaloEntreMsgs: parseInt(data.intervaloEntreMsgs),
        tags,
        permissaoNotif: permissaoNotif,
      });

      if (!response.data.success) {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }
      toast.success('Sucesso!', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      await context.updateState();
      window.scrollTo(0, 0);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formConta.current.setErrors([]);
        formConta.current.setErrors(validationErrors);
      }
    }
  }

  const handleUploadFile = async (event) => {
    const data = new FormData();
    data.append('file', event.target.files[0]);

    const result = await api.post('/v1/numeros-proibidos/importar', data);
    if (result.data.success) {
      toast.success('Números importados com sucesso!', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      fileInput.current.value = '';
      context.updateState();
      closeModal();
    } else {
      toast.error(result.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  async function excluir(todos) {
    confirmAlert({
      title: 'Excluir Números',
      message: `Excluir todos os números ${
        todos ? 'da lista' : 'selecionados'
      } ?`,
      buttons: [
        {
          label: 'Sim',
          onClick: async () => {
            if (todos) {
              const response = await api.delete('/v1/numeros-proibidos/');
              if (!response.data.success) {
                toast.error(response.data.err, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
                return;
              }
              toast.success('Sucesso!', {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            } else {
              const response = await api.post('/v1/numeros-proibidos/array', {
                numero: selecionados,
              });

              if (!response.data.success) {
                toast.error(response.data.err, {
                  position: toast.POSITION.BOTTOM_LEFT,
                });
                return;
              }
              toast.success('Sucesso!', {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
            fetchData();
          },
        },
        {
          label: 'Não',
        },
      ],
    });
  }

  function closeModal() {
    setmodalEditBlacklistVisible(false);
    setmodalSenha(false);
  }

  function blacklistmodal(phone) {
    setmodalEditBlacklistVisible(true);
    setnumeroedit(phone);
  }

  function selecionadosControl(id, e) {
    if (selecionados.indexOf(id) > -1 && !e.target.checked) {
      selecionados.splice(selecionados.indexOf(id), 1);
    } else {
      selecionados.push(id);
    }
    setselecionados([...selecionados]);
  }

  async function atualizaQr() {
    setiniciado(true);
    intevaloQr = setInterval(async () => {
      const responsezaps = await api.post(`/v1/zap/list`, {});
      if (responsezaps.data.data.length > 0) {
        for (let i = 0; i < responsezaps.data.data.length; i++) {
          if (responsezaps.data.data[i].qrcode)
            setqr(responsezaps.data.data[i].qrcode);
        }
      }
    }, 10000);
  }

  async function fetchData() {
    setToken(localStorage.getItem('TOKEN'));
    const responseProibidos = await api.post('/v1/numeros-proibidos/list', {
      limit,
      skip,
    });
    setPageCount(responseProibidos.data.total / limit);
    setblacklist(responseProibidos.data);

    if (context.usuario) {
      if (formConta.current && formConta.current.setData) {
        formConta.current.setData({
          nome: context.usuario.nome,
          encaminharRespostas: context.usuario.encaminharRespostas,
          email: context.usuario.email,
          telefoneWhats: context.usuario.telefoneWhats,
          dddpadrao: context.usuario.dddpadrao,
          intervaloEntreMsgs: context.usuario.intervaloEntreMsgs,
        });
      }
      setencaminharRespostas(context.usuario.encaminharRespostas || []);
      settags(context.usuario.tags);
      setSincronizacaoAuto(context.usuario.sincronizacaoAuto || false);
      setpermissaoNotif(context.usuario.permissaoNotif);
    }

    const responsezaps = await api.post(`/v1/zap/list`, {});
    if (responsezaps.data.data.length > 0) {
      console.log(responsezaps.data);
      if (responsezaps.data.data[0].qrcode)
        setqr(responsezaps.data.data[0].qrcode);
    }

    if (!iniciado) {
      atualizaQr();
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.usuario, skip, ativa]);

  useEffect(() => {
    return function cleanUp() {
      clearInterval(intevaloQr);
    };
  }, []);

  function fetchPagination(data) {
    const skip = data.selected * limit;
    window.scrollTo(0, 0);
    setSkip(skip);
  }

  function handleChange(checked) {
    setpermissaoNotif(checked);
  }

  function handleChangeChecked(checked) {
    setSincronizacaoAuto(checked);
  }

  return (
    <>
      <ModalEditBlacklist
        closeModal={closeModal}
        modalVisible={modalEditBlacklistVisible}
        fetchData={fetchData}
        phone={numeroedit}
      />
      <ModalSenha
        closeModal={closeModal}
        modalVisible={modalSenha}
        history={history}
        fetchData={fetchData}
      />
      <div id="config">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop
                texto="Configurações"
                title="config"
                setativa={setativa}
                context={context}
              />
              <div className="area_body">
                <Tab ativa={ativa} setativa={setativa}>
                  <Tabs name="Configurações" initial>
                    <Form ref={formConta} onSubmit={salvar}>
                      <div className="row">
                        <div className="col-sm-12 col-md-6">
                          <div className="row">
                            <div
                              className="col-sm-12 col-md-12 mr-3 card-content"
                              id="conta"
                            >
                              <span>CONTA</span>
                              <br />
                              <br />
                              <Input
                                name="nome"
                                label="Nome"
                                className="inputPadrao mb-4"
                              />
                              <Input
                                name="email"
                                label="Email"
                                className="inputPadrao mb-4"
                              />
                              <div className="col-md-12 pl-0">
                                <label>Sincronização Automática</label>
                                <br />
                                <Switch
                                  onChange={handleChangeChecked}
                                  checked={sincronizacaoAuto}
                                />
                              </div>
                              <div className="col-md-12 pl-0">
                                <label>Quero Receber Notificações</label>
                                <br />
                                <Switch
                                  onChange={handleChange}
                                  checked={permissaoNotif}
                                />
                              </div>
                              <br />

                              <button
                                type="button"
                                className="btn-main mt-3"
                                onClick={() => setmodalSenha(true)}
                              >
                                Alterar senha
                              </button>
                            </div>

                            <div
                              className="col-12 mt-3 mb-3 card-content"
                              id="tags"
                            >
                              <span>TAGS</span>
                              <br />
                              <br />
                              <TagsInput
                                value={tags}
                                inputProps={{
                                  placeholder: 'Adicionar tag',
                                }}
                                onChange={(tag) => settags([...tag])}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 d">
                          <div className="col-12 card-content" id="envio">
                            <span>ENVIO</span>
                            <br />
                            <br />
                            <p>Conecte ao Whatsapp Web</p>
                            {qr && qr.qr && (
                              <>
                                <img
                                  src={qr && qr.qr ? qr.qr : ''}
                                  className="mb-2 qrcode"
                                  alt="Qrcode"
                                />
                                <p>
                                  Último update QRCode:{' '}
                                  {qr && qr.qr
                                    ? moment(qr.lastUpdate).format(
                                        'DD/MM/YYYY HH:mm:ss'
                                      )
                                    : ''}
                                </p>
                              </>
                            )}
                            {qr && !qr.qr && (
                              <>
                                <span>
                                  <b>QRCode indisponível</b>
                                </span>
                                <br />
                              </>
                            )}
                            <div className="form-group mt-4">
                              <Input
                                name="dddpadrao"
                                label="DDD"
                                className="inputPadrao mb-4"
                              />
                              <label className="label">
                                Encaminhar respostas para
                              </label>
                              <br />
                              <button
                                className="btn-main mb-3"
                                onClick={() => {
                                  encaminharRespostas.push('');
                                  setencaminharRespostas([
                                    ...encaminharRespostas,
                                  ]);
                                }}
                              >
                                + Adicionar número
                              </button>
                              <br />
                              {encaminharRespostas.map((el, i) => {
                                return (
                                  <div className="row mb-2" key={i}>
                                    <div className="col-6">
                                      <InputMask
                                        mask="(99) 99999-9999"
                                        value={el}
                                        className="inputPadrao"
                                        onChange={(e) => {
                                          encaminharRespostas[i] =
                                            e.target.value
                                              .toString()
                                              .replace(/\D/gim, '');
                                          setencaminharRespostas([
                                            ...encaminharRespostas,
                                          ]);
                                        }}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <label
                                        className="action text-danger mt-2"
                                        onClick={() => {
                                          encaminharRespostas.splice(i, 1);
                                          setencaminharRespostas([
                                            ...encaminharRespostas,
                                          ]);
                                        }}
                                      >
                                        Remover
                                      </label>
                                    </div>
                                  </div>
                                );
                              })}
                              <br />
                              <Input
                                name="intervaloEntreMsgs"
                                label="Intervalo entre mensagens"
                                className="inputPadrao"
                              />
                              <div className="input-group-append float-right">
                                <span className="input-group-text">
                                  Minutos
                                </span>
                              </div>
                            </div>
                          </div>
                          <button className="btn-main mt-3">
                            Salvar Alterações
                          </button>
                        </div>
                      </div>
                    </Form>
                  </Tabs>
                  <Tabs name="Números Bloqueados">
                    <div className="row" id="opcoesBlack">
                      <div className="col-sm-12 col-md-2 mt-2 mb-1">
                        <label
                          className="action"
                          onClick={() => excluir(false)}
                        >
                          Remover Selecionados
                        </label>
                      </div>
                      <div className="col-sm-12 col-md-2 mt-2 mb-1">
                        <label
                          className="action"
                          onClick={() => fileInput.current.click()}
                        >
                          Importar Contatos
                        </label>
                        <input
                          className="d-none"
                          ref={fileInput}
                          type="file"
                          onChange={handleUploadFile}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4" />
                      <div className="col-sm-12 col-md-2 mt-1">
                        <a
                          rel="noreferrer"
                          href={`${process.env.REACT_APP_API_URL}/v1/numeros-proibidos/exportar?token=${token}`}
                          target="_blank"
                        >
                          <AiOutlineCloudDownload className="mr-2" size={18} />
                          Fazer download de todos os contatos
                        </a>
                      </div>

                      <div className="col-sm-12 col-md-2 mt-2 mb-1">
                        <label
                          className="actionRemoveAll"
                          onClick={() => excluir(true)}
                        >
                          Remover Todos os números
                        </label>
                      </div>

                      <div className="col-sm-12 col-md-2 mt-2 mb-1">
                        <label
                          className="action float-right mr-4"
                          onClick={() => blacklistmodal()}
                        >
                          + Adicionar novo
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-12 col-lg-12 ">
                        <div
                          className="card-content table-responsive"
                          id="tabelaBlack"
                        >
                          <table className="table table-hover" id="lista">
                            <thead>
                              <tr>
                                <th scope="col" />
                                <th scope="col">NÚMERO</th>
                                <th scope="col">DATA DE INCLUSÃO</th>
                                <th scope="col" />
                              </tr>
                            </thead>
                            {blacklist.total > 0 ? (
                              <tbody>
                                {blacklist.data.map((el, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <tr
                                        onDoubleClick={(e) =>
                                          selecionadosControl(el?._id, e)
                                        }
                                      >
                                        <td className="pointerTable">
                                          <div className="float-right">
                                            <input
                                              type="checkbox"
                                              checked={
                                                selecionados.indexOf(el?._id) >
                                                -1
                                              }
                                              onChange={(e) =>
                                                selecionadosControl(el?._id, e)
                                              }
                                              className="form-check-input"
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          <CurrencyFormat
                                            displayType={'text'}
                                            value={el?.numero}
                                            className="pointerTable"
                                            format="(##) #########"
                                          />
                                        </td>
                                        <td className="pointerTable">
                                          {moment(el?.dataInclusao).format(
                                            'DD/MM/YYYY'
                                          )}
                                        </td>
                                        <td
                                          className="editTable"
                                          onClick={() => blacklistmodal(el)}
                                        >
                                          Editar
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  );
                                })}
                              </tbody>
                            ) : (
                              <></>
                            )}
                          </table>
                          {blacklist.total > 0 && (
                            <ReactPaginate
                              previousLabel="<"
                              nextLabel=">"
                              breakLabel="..."
                              breakClassName="page-item disabled"
                              breakLinkClassName="page-link"
                              disabledClassName="disabled"
                              pageCount={pageCount}
                              pageClassName="page-item"
                              previousClassName="page-item"
                              pageLinkClassName="page-link"
                              initialPage={0}
                              previousLinkClassName="page-link"
                              nextLinkClassName="page-link"
                              marginPagesDisplayed="0"
                              pageRangeDisplayed="6"
                              onPageChange={fetchPagination}
                              containerClassName="pagination"
                              subContainerClassName="pages"
                              activeClassName="active"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </Tabs>
                  <Tabs name="Desenvolvedor">
                    <div className="row">
                      <div className="col-sm-12 col-md-7">
                        <h3 className="mt-3 text-primary">
                          Listar seus números de envio
                        </h3>
                        <Highlight language="javascript">
                          {`GET | https://dashboard.bestmessage.com.br/api/v1/services/zaps?token=TOKEN-INTEGRAÇÃO`}
                        </Highlight>
                        <b>Resposta Requisição</b>
                        <pre>
                          {`{
  success: true,
  zaps: [
    {
      _id: "60da79686ce60316d8651f53", // ZAP_ID
      telefone: "11999123456"
    },
    {
      _id: "60dcdc7fcf471a7cecc5e825", 
      telefone: "119996564321"
    }                     
  ]
}`}
                        </pre>

                        <h3 className="mt-3 text-primary">Envio avulso</h3>
                        <Highlight language="javascript">
                          {`POST | https://dashboard.bestmessage.com.br/api/v1/services/avulso?token=TOKEN-INTEGRAÇÃO
{
    nome: "João",
    telefone: 11999111111,
    mensagem: "Olá [nome], tudo bem ?",
    zap: ZAP_ID,
    ms: 1,
}
`}
                        </Highlight>
                        <b>Resposta Requisição</b>
                        <pre>
                          {`{
  success: true,
  campanha: "60e5b5b00029a15ac251c3b9" // CAMPANHA_ID
}
`}
                        </pre>
                        <h3 className="mt-3 text-primary">Envio lote</h3>
                        <Highlight language="javascript">
                          {`POST | https://dashboard.bestmessage.com.br/api/v1/services/avulsoLote?token=TOKEN-INTEGRAÇÃO
[
  {
    nome: "João",
    telefone: 11999111111,
    mensagem: "Olá [nome], tudo bem ?",
    zap: ZAP_ID,
    ms: 1,
  }
]`}
                        </Highlight>
                        <b>Resposta Requisição</b>
                        <pre>
                          {`{
  success: true,
  campanha: "60e5b5b00029a15ac251c3b9"
}
`}
                        </pre>
                        <h3 className="mt-3 text-primary">Envio com arquivo</h3>
                        <p>
                          Para envios com arquivo, tanto lote quanto avulso, é
                          necessário adicionar nos headers multipart/form-data
                        </p>
                        <pre>
                          {`file: {
  filename: "img1.jpg",
  mimetype: "image/jpeg",
  originalname: "img1.jpg",
  size: 155699,
  encoding: "7bit",
}`}
                        </pre>
                        <pre>
                          {`headers: {
  "Content-Type": "multipart/form-data"
}`}
                        </pre>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="card-content mt-3">
                          <span>TOKEN-INTEGRAÇÃO</span>
                          <button
                            type="button"
                            className="float-right btn btn-light mb-2"
                            onClick={(e) => {
                              navigator.clipboard.writeText(token);
                              e.target.focus();
                              toast.success('Copiado!', {
                                position: toast.POSITION.BOTTOM_LEFT,
                              });
                            }}
                          >
                            copy
                          </button>
                          <br />
                          <textarea
                            disabled
                            rows="10"
                            className="form-control mt-2"
                            value={token}
                          >
                            {token}
                          </textarea>
                          <br />
                          <span>ID-ZAP {zap ? zap.telefone : ""}</span>
                          <textarea
                            disabled
                            rows="1"
                            className="form-control mt-2"
                            value={zap ? zap._id : ""}
                          >
                            {zap ? zap._id : ""}
                          </textarea>
                          <br />
                          <span>AUTENTICAÇÃO</span>
                          <p className="mt-2">
                            Você pode mandar seu TOKEN-INTEGRAÇÃO direto na URL
                            da requisição, desta forma:{' '}
                          </p>
                          <Highlight language="javascript">
                            {` .../services/zaps?token=TOKEN-INTEGRAÇÃO`}
                          </Highlight>
                          <p>
                            Porém também tem a opção de enviar no header da
                            requisição:
                          </p>
                          <Highlight language="javascript">
                            {` Authorization: TOKEN-INTEGRAÇÃO`}
                          </Highlight>
                        </div>
                        <h3 className="mt-3 text-primary">
                          Listar respostas campanhas
                        </h3>
                        <Highlight language="javascript">
                          {`GET | https://dashboard.bestmessage.com.br/api/v1/services/campanhas/CAMPANHA_ID?token=TOKEN-INTEGRAÇÃO`}
                        </Highlight>
                        <b>Resposta Requisição</b>
                        <pre>
                          {`{
  success: true,
  campanha: {
    ativo: true,
    total: 25,
    totalEnviado: 17,
    totalFalhas: 0,
    _id: "60e5b5b00029a15ac251c3b9",
    usuario: "60ba5e71575c3700f9aefb3d",
    zap: "60da79686ce60316d8651f53",
    nome: "API-CHAT",
    ms: 1,
    dataHoraDisparo: "2021-07-07T03:00:00.000Z"
  },
  envios: [
    {
      repetiu: 0,
      _id: "60e5bcb77499927a8c831118",
      campanha: "60e5b5b00029a15ac251c3b9",
      contato: "60e5b5afca4df46124f16674",
      temzap: true,
      enviou: true,
      mensagem: "Olá Ana, tudo bom?",
      zap: "60da79686ce60316d8651f53",
      dataHoraDisparo: "2021-07-07T03:00:40.000Z",
      dataFila: "2021-07-07T14:39:51.684Z",
      processado: true,
      data: "2021-07-07T14:39:57.313Z",
      result: true
    },
  ]
}
`}
                        </pre>
                      </div>
                    </div>
                  </Tabs>
                </Tab>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
