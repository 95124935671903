import React, { useEffect, useState } from 'react';
import Menu from '../../components/Menu/';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import moment from 'moment';
import Select from "react-select";
import ReactPaginate from 'react-paginate';
import useQuery from '../../core/useQuery';
import Skeleton from 'react-loading-skeleton';
import api from '../../service/api';
import { getToken } from '../../service/auth';
import AreaTop from '../../components/AreaTop/index';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import ModalQRCode from './QRcode';
import ModalEditNumero from './EditNumero';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ModalImportarNumeros from './ModalImportarNumeros';

export default function Numeros({ context, history }) {
    const [loading, setLoading] = useState(true);
    const token = getToken();
    const [busca, setbusca] = useState('');
    const [lista, setlista] = useState([]);
    const [grupo, setgrupo] = useState(null);
    const [grupos, setgrupos] = useState([]);
    const [numero, setnumero] = useState('');
    const [modalEditNumeroVisible, setmodalEditNumeroVisible] = useState(false);
    const [modalQRcodeVisible, setmodalQRcodeVisible] = useState(false);
    const [etapa, setetapa] = useState({
        disponivelHoje: 0,
        etapaTotal: 0,
        etapaMaturados: 0,
        etapaMaturando: 0,
        enviosReais: 0,
    });

    const [usuario, setusuario] = useState({ usaMassa: false });
    // PAGINATION
    const [total, settotal] = useState(0);
    const [online, setonline] = useState(0);
    const query = useQuery();
    const limit = 15;
    const skip = parseInt(query.get('page') || 0) * limit;
    const [pageCount, setPageCount] = useState(0);

    const [modalVisibleImportar, setmodalVisibleImportar] = useState(false);

    const [selecionados, setselecionados] = useState([]);

    async function excluir() {
        confirmAlert({
            title: 'Excluir Números',
            message: 'Excluir todos os números selecionados',
            buttons: [
                {
                    label: 'Sim',
                    onClick: async () => {
                        const response = await api.post('/v1/zap/array', {
                            zaps: selecionados,
                        });
                        if (!response.data.success) {
                            toast.error(response.data.err, {
                                position: toast.POSITION.BOTTOM_LEFT,
                            });
                            return;
                        }
                        toast.success('Sucesso!', {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                        context.updateState();
                        fetchData();
                    },
                },
                {
                    label: 'Não',
                },
            ],
        });
    }

    async function fetchData() {
        if (context.usuario) setusuario(context.usuario);
        const response = await api.post(`/v1/zap/list`, {
            busca,
            skip,
            limit,
            grupo,
            sort: "data",
        });
        setetapa(response.data.etapa);
        setPageCount(response.data.total / limit);
        settotal(response.data.total);
        setlista(response.data.data);
        setLoading(false);

        const resp = await api.post(`/v1/zap/get_onlines`);
        setonline(resp.data.online);

        const respGrupos = await api.post('/v1/zap/grupo/list', { all: true });
        if(respGrupos.data && respGrupos.data.data){
             respGrupos.data.data.map((el) => {
                return (el.value = el._id) + ',' + (el.label = el.nome);
            });
            setgrupos(respGrupos.data.data);
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.usuario, skip]);

    function fetchPagination(data) {
        history.push(`/numeros?page=${data.selected || 0}`);
    }

    function selecionadosControl(id, e) {
        if (selecionados.indexOf(id) > -1 && !e.target.checked) {
            selecionados.splice(selecionados.indexOf(id), 1);
        } else {
            selecionados.push(id);
        }
        setselecionados([...selecionados]);
    }

    function verdeOuVermelho(time) {
        const diff = moment().diff(moment(time), 'seconds');
        if (diff > 160 || !time) return 'danger';
        return 'success';
    }

    function numeroModal(phone) {
        setmodalEditNumeroVisible(true);
        setnumero(phone);
    }

    function closeModal() {
        fetchData();
        setmodalVisibleImportar(false);
        setmodalEditNumeroVisible(false);
        setmodalQRcodeVisible(false);
    }

    function getCountDays(data) {
        return moment().diff(moment(data), 'd');
    }

    function getInfoDate(data) {
        const diff = getCountDays(data);

        return `há ${diff} dia${diff > 1 ? 's' : ''} ativo`;
    }

    return (
        <>
            <ModalImportarNumeros
                modalVisibleImportar={modalVisibleImportar}
                closeModal={closeModal}
                context={context}
            />
            <ModalEditNumero
                closeModal={closeModal}
                modalVisible={modalEditNumeroVisible}
                fetchData={fetchData}
                phone={numero}
                context={context}
            />
            <ModalQRCode
                closeModal={closeModal}
                modalVisible={modalQRcodeVisible}
                phone={numero}
            />
            <div id="numeros">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12 col-lg-2 mb-5">
                            <Menu context={context} />
                        </div>
                        <div className="col-sm-12 col-lg-10">
                            <AreaTop texto="Números" title="numeros" context={context} />
                            <div className="area_body">
                                <div id="buscaNumeros">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-2 mt-2 mb-2">
                                            <button
                                                className="btn-main w-100"
                                                onClick={() => numeroModal()}
                                            >
                                                Novo Número
                                            </button>
                                        </div>
                                        {usuario.usaMassa && (
                                            <div className="col-sm-12 col-md-2 mt-2 mb-2">
                                                <button
                                                    className="btn-main w-100"
                                                    onClick={() => setmodalVisibleImportar(true)}
                                                >
                                                    Importar Números
                                                </button>
                                            </div>
                                        )}
                                        <div className="col-sm-12 col-md-3 mt-2 mb-2">
                                            <input
                                                type="text"
                                                value={busca}
                                                onChange={(e) => setbusca(e.target.value)}
                                                onKeyPress={(e) => e.key === "Enter" && fetchData()}
                                                className="input-search-white"
                                                placeholder="Pesquise um número"
                                            />
                                        </div>
                                        <div className="col-sm-12 col-md-3 mt-2 mb-2">
                                        <Select
                                            placeholder="Selecione um grupo"
                                            className="select-menu"
                                            value={grupo}
                                            defaultValue={grupo}
                                            onChange={(e) => setgrupo(e)}
                                            options={grupos}
                                        />
                                        </div>
                                        <div className="col-sm-12 col-md-2 mt-2 mb-2">
                                            <button
                                                className="btn-main w-100"
                                                onClick={() => fetchData()}
                                            >
                                                Buscar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-md-2 mt-2 mb-1">
                                            <label>Onlines {online} / total de {total} números </label>
                                        </div>
                                        <div className="col-sm-12 col-md-2 mt-2 mb-1">
                                            <label
                                                className="action"
                                                role="button"
                                                onClick={() => excluir()}
                                            >
                                                Remover Selecionados
                                            </label>
                                        </div>
                                        <div className="col-sm-12 col-md-8 mb-1">
                                            <a
                                                rel="noreferrer"
                                                className="float-right pt-2"
                                                href={`${process.env.REACT_APP_API_URL}/v1/zap/exportar/csv?token=${token}`}
                                                target="_blank"
                                            >
                                                <AiOutlineCloudDownload className="mr-2" size={18} />
                                                Fazer download de todos os números
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {usuario.usaMassa && (
                                    <div className="card-content">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-3 mt-2 mb-1 ">
                                                <span>
                                                    Volume ideal para upload:
                                                    <br />
                                                    {etapa && (
                                                        <>
                                                            <label className="text-success">
                                                                {parseInt(etapa.enviosReais || 0)}
                                                            </label>
                                                        </>
                                                    )}
                                                </span>
                                            </div>

                                            <div className="col-sm-12 col-md-3 mt-2 mb-1">
                                                <span>
                                                    Maturados:
                                                    <br />
                                                    <label className="text-primary">
                                                        {etapa && parseInt(etapa.etapaMaturados || 0)}
                                                    </label>
                                                </span>
                                            </div>
                                            <div className="col-sm-12 col-md-3 mt-2 mb-1">
                                                <span>
                                                    Maturando:
                                                    <br />
                                                    <label className="text-primary">
                                                        {etapa && parseInt(etapa.etapaMaturando || 0)}
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="row" id="tabelaNumeros">
                                    <div className="col-sm-12 col-md-12 mt-2 mb-1 ">
                                        <div className="card-content table-responsive">
                                            <table className="table table-hover" id="lista">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">GRUPO</th>
                                                        <th scope="col">TELEFONE</th>
                                                        <th scope="col">ETAPA</th>
                                                        {!usuario.usaMassa && (
                                                            <th scope="col">LIMITE MENSAL</th>
                                                        )}
                                                        <th scope="col">ENVIOS</th>
                                                        <th scope="col">WEB</th>
                                                        <th scope="col">QRCode</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {lista.map(function (el, i) {
                                                        return (
                                                            <tr
                                                                onDoubleClick={(e) =>
                                                                    selecionadosControl(el._id, e)
                                                                }
                                                                key={i}
                                                            >
                                                                <td className="pointerTable">
                                                                    <div className="float-right">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={
                                                                                selecionados.indexOf(el._id) > -1
                                                                            }
                                                                            onChange={(e) =>
                                                                                selecionadosControl(el._id, e)
                                                                            }
                                                                            className="form-check-input"
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="pointerTable">
                                                                    {el.grupo ? el.grupo.nome : 'Sem Grupo'}
                                                                </td>
                                                                <td className="pointerTable">
                                                                    {el.telefone
                                                                        ? el.telefone
                                                                        : 'Sem telefone'}{' '}
                                                                    - {getInfoDate(el.data)}
                                                                </td>
                                                                <td className="pointerTable">
                                                                    {el.etapa && el.etapa.nome
                                                                        ? el.etapa.nome
                                                                        : ''}
                                                                </td>
                                                                {!usuario.usaMassa && (
                                                                    <td className="pointerTable">{el.limite} / {el.limiteMensal}</td>
                                                                )}
                                                                <td className="pointerTable">{el.envios}</td>
                                                                <td className="pointerTable">
                                                                    <b
                                                                        className={`badge text-light bg-${verdeOuVermelho(
                                                                            el.lastPingWeb
                                                                        )}`}
                                                                    >
                                                                        {verdeOuVermelho(el.lastPingWeb) ===
                                                                            'danger'
                                                                            ? 'OFFLINE'
                                                                            : 'ONLINE'}
                                                                    </b>
                                                                    <label className="ml-2">{el.lastPingWeb ? moment(el.lastPingWeb).format("DD/MM/YYYY HH:mm:ss") : ''}</label>
                                                                </td>
                                                                <td
                                                                    className="detalhes"
                                                                    onClick={() => {
                                                                        setmodalQRcodeVisible(true);
                                                                        setnumero(el);
                                                                    }}
                                                                >
                                                                    Visualizar QRCode
                                                                </td>
                                                                <td
                                                                    className="detalhes"
                                                                    onClick={() => numeroModal(el)}
                                                                >
                                                                    Editar
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            {loading && <Skeleton count={10} />}
                                            {lista.length > 0 && (
                                                <ReactPaginate
                                                    previousLabel="<"
                                                    nextLabel=">"
                                                    breakLabel="..."
                                                    breakClassName="page-item disabled"
                                                    breakLinkClassName="page-link"
                                                    disabledClassName="disabled"
                                                    pageCount={pageCount}
                                                    pageClassName="page-item"
                                                    previousClassName="page-item"
                                                    pageLinkClassName="page-link"
                                                    initialPage={parseInt(query.get('page'))}
                                                    previousLinkClassName="page-link"
                                                    nextLinkClassName="page-link"
                                                    marginPagesDisplayed={1}
                                                    pageRangeDisplayed={4}
                                                    onPageChange={fetchPagination}
                                                    containerClassName="pagination"
                                                    subContainerClassName="pages"
                                                    activeClassName="active"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
