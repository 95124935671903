import React, { useEffect, useState } from "react";
import "./../../style.css";
import "./../../pages/Campanhas/style.css";
import {
  AiOutlineCloudDownload,
  AiOutlineFieldTime,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";

export default function Status({
  icon,
  name,
  label,
  className,
  placeholder,
  ...rest
}) {

  const [styleComponent, setstyleComponent] = useState("");

  
  useEffect(() => {
      setstyleComponent(className);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ className]);

  return (
    <>
      <td  className={styleComponent}>
          {icon === 'AiOutlineCloudDownload' && (<> <AiOutlineCloudDownload className="icon" size={18} /> {label}</> )}
          {icon === 'AiOutlineFieldTime' && (<> <AiOutlineFieldTime className="icon" size={18} /> {label}</> )}
          {icon === 'AiOutlineCheck' && (<> <AiOutlineCheck className="icon" size={18} /> {label}</> )}
          {icon === 'AiOutlineClose' && (<> <AiOutlineClose className="icon" size={18} /> {label}</> )}
          
      </td>
    </>
  );
}
