import React, { useState } from "react";
import { BiHelpCircle } from "react-icons/bi";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import ModalZapsNoSync from "./ModalZapsNoSync";
import "./style.css";

export default function AreaTop(props) {
  const [run, setrun] = useState(false);
  const [stepIndex, setstepIndex] = useState(0);
  const [modalNoSync, setmodalNoSync] = useState(false);

  const init = {
    content: <h2>Vamos aprender mais sobre esta página!</h2>,
    locale: {
      skip: <strong aria-label='skip'>Sair</strong>,
      next: (
        <strong aria-label='next' className='nextHelper'>
          Continuar
        </strong>
      ),
    },
    placement: "center",
    target: "body",
  };

  const steps_ = {
    dash: [
      init,
      {
        target: "#cards",
        content: (
          <h4>Aqui algumas informações rapidas sobre o seu lembrazap.</h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#campanhasespecifico",
        content: (
          <h4>
            Informações mais especificas sobre suas campanhas mais recentes.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#mensagens",
        content: <h4>Veja e responda s mensagens sobre suas campanhas.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    respostas: [
      init,
      {
        target: "#buscas",
        content: (
          <h4>
            Opções para buscar respostas, procurar por contato ou um periodo de
            tempo especifico.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#tabela",
        content: <h4>Veja a lista de respostas de suas campanhas.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    campanhas: [
      init,
      {
        target: "#campanhaPesquisa",
        content: (
          <h4>
            Opções para filtrar suas campanhas, criar novas campanhas, importar,
            fazer download dos resultados ou remover elas da lista.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#campanhatabela",
        content: <h4>Sua lista de campanhas.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    "nova-campanha": [
      init,
      {
        target: "#info-campanha",
        content: (
          <h4>
            Primeiramente preencha os campos de informações gerais da campanha,
            para que a campanha sejá criada.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#file-campanha",
        content: (
          <h4>
            Caso queira enviar um arquivo ou documento, arraste o arquivo ou
            clique no campo acima para selecionar.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#contatos-campanha",
        content: (
          <h4>
            Nesta etapa selecione os contatos que irão receber a campanha e
            clique em "Adicionar contatos selecionados".
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#previa-campanha",
        content: (
          <h4>
            Aqui serão apresentados uma prévia da campanha para os contatos
            selecionados, tendo também a opção de remover a mensagem para um
            determinado contato.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#salvar-campanha",
        content: (
          <h4>
            Tendo concluido e verificado as etapas anteriores, clique em "Salvar
            Campanha" e sua campanha será criada.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    "importar-campanha": [
      init,
      {
        target: "#info-campanha",
        content: (
          <h4>
            Primeiramente preencha os campos de informações gerais da campanha,
            para que a campanha sejá criada.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#importa-campanha",
        content: (
          <h4>
            Para importar campanha é necessario utilizar o{" "} 
            <a href="https://dashboard.bestmessage.com.br/arquivo-modelo-lembrazap-campanha.xlsx">
              arquivo modelo importação
            </a> como template.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#previa-campanha",
        content: (
          <h4>
            Aqui serão apresentados uma prévia da campanha para os contatos
            listados no arquivo de importar campanha.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#salvar-campanha",
        content: (
          <h4>
            Tendo concluido e verificado as etapas anteriores, clique em "Salvar
            Campanha" e sua campanha será criada.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    financeiro: [
      init, 
      {
        target: "#boletos",
        content: (
          <h4>
            Para agendar o envio dos boletos, é necessario primeiramente que os arquivos de boletos sejam arrastados para o campo acima ou clique no campo para selecionar os arquivos.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#preview-boletos",
        content: (
          <h4>
            Aqui é apresentado um preview do boletos selecionados.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#boletos-agendados",
        content: (
          <h4>
            Aqui é apresentado os boletos que já foram agendados para o envio.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },

    ],
    contatos: [
      init,
      {
        target: "#busca",
        content: (
          <h4>
            Opções para buscar seus contatos, adicionar ou importar novos
            contatos, faça download deles ou remova eles da lista.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#tabelaContatos",
        content: <h4>Veja seus contatos, edite-os.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    numeros: [
      init,
      {
        target: "#buscaNumeros",
        content: (
          <h4>
            Pesquise seus numeros, adicione novos, faça download deles ou
            remova-os.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#tabelaNumeros",
        content: (
          <h4>
            Veja seus numeros, quantos envios já fizeram, o estado de maturação,
            se estão online ou edite-os.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    config: [
      init,
      {
        target: "#conta",
        content: <h4>Modifique suas informações pessoais.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#envio",
        content: (
          <h4>
            Ajuste suas opções de envio, conecte-se ao QRCode do whatsapp web.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#tags",
        content: <h4>Adicione e exclua suas próprias tags.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#opcoesBlack",
        content: (
          <h4>Opções para sua blacklist, remova numeros ou adicione-os.</h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#tabelaBlack",
        content: (
          <h4>
            Tabela de números proibidos, veja quais são, data da inclusão ou se
            precisar edite-os.
          </h4>
        ),
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    desenvolvedor: [
      init,
      {
        target: "#campanhastotal",
        content: <h4>botão para adicionar à lista!</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#campanhasespecifico",
        content: <h4>Aqui você vê a lista de todos os numeros proibidos!</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#mensagens",
        content: <h4>Aqui você adiciona um numero a lista!</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    relatorioEnvio: [
      init,
      {
        target: "#buscas",
        content: <h4>Métodos de buscas!</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#resultados",
        content: <h4>Resultados sobre as campanhas em uma data especifica!</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#tabelaEnv",
        content: <h4>Tabela com resultados sobre cada campanha!</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
    ],
    leads: [
      init,
      {
        target: "#nova-captura",
        content: <h4>Clicando neste botão, será aberto a área de criação da captura de leads.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#list-captura",
        content: <h4>Nesta tabela é listados as capturas de leads criadas.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      },
      {
        target: "#remove-captura",
        content: <h4>Essa opção remove as capturas de leads que estão selecionadas.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      }
    ],
    planos: [
      init,
      {
        target: "#plano-selecionado",
        content: <h4>Aqui é apresentado o plano selecionado.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      }
      ,
      {
        target: "#assinatura",
        content: <h4>Neste campo selecione o plano que mais atende suas necessidades.</h4>,
        locale: {
          skip: <strong aria-label='skip'>Sair</strong>,
          next: (
            <strong aria-label='next' className='nextHelper'>
              Continuar
            </strong>
          ),
          last: (
            <strong aria-label='last' className='nextHelper'>
              Fim
            </strong>
          ),
          back: <strong aria-label='back'>Voltar</strong>,
        },
      }
    ]
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if (props.title === "config") {
      if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
        if (index + (action === ACTIONS.PREV ? -1 : 1) === 4) props.setativa(1);
        setTimeout(() => {
          setstepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        }, 100);
      } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        props.setativa(null);
        setrun(false);
      }
    }
  };

  return (
    <>
      <Joyride
        steps={steps_[props.title]}
        continuous={true}
        beaconComponent={false}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        callback={props.title === "config" && handleJoyrideCallback}
        stepIndex={props.title === "config" && stepIndex}
        styles={{
          options: {
            primaryColor: "var(--cor-principal)",
            width: 500,
            arrowColor: "#ffffff",
            backgroundColor: "#ffffff",
            overlayColor: "#00000050",
            textColor: "#000000",
            borderRadius: 10,
          },
        }}
        run={run}
      />
      <ModalZapsNoSync
        closeModal={() => setmodalNoSync(false)}
        modalVisible={modalNoSync}
      />
      <div className='area_top'>
        <div className='row'>
          <div className='col-sm-12 col-md-8'>
            <h3>{props.texto}</h3>
          </div>
          <div className='col-sm-12 col-md-4'>
            <div className='float-right d-flex'>
              <div className='help' onClick={setrun}>
                <span>
                  <BiHelpCircle size={22} className='helpIcon' />
                  Ajuda
                </span>
              </div>
            </div>
            {props.context && props.context.usuario && !props.context.usuario.usaMassa && (
              <div className='float-right mr-2'>
                <label className="mt-3">LIMITE MENSAL: {props.context && props.context.limite} / {props.context && props.context.limiteMensal} </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
