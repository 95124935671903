import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import Skeleton from "react-loading-skeleton";
import api from "../../service/api";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";
import useQuery from "../../core/useQuery";
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';
import { confirmAlert } from "react-confirm-alert";
import ModalAddEditLead from "./ModalAddEditLead";

export default function Leads({ context, history }) {
  const [modalvisible, setmodalvisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [listLead, setListLead] = useState(null);
  const query = useQuery();
  const limit = 15;
  const skip = parseInt(query.get("page") || 0) * limit;
  const [pageCount, setPageCount] = useState(0);
  const [lista, setlista] = useState([]);
  const [selecionados, setselecionados] = useState([]);

  async function fetchData() {
    const response = await api.post(`/v1/lead/list/list`, {
      skip,
      limit,
    });
    setPageCount(response.data.total / limit);

    setlista(response.data.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  function fetchPagination(data) {
    history.push(`/leads?page=${data.selected || 0}`);
  }

  function selecionadosControl(id, e) {
    if (selecionados.indexOf(id) > -1 && !e.target.checked) {
      selecionados.splice(selecionados.indexOf(id), 1);
    } else {
      selecionados.push(id);
    }
    setselecionados([...selecionados]);
  }
  
  async function excluir() {
    confirmAlert({
      title: "Excluir Leads",
      message: "Excluir selecionados",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/lead/list/array", {
              listLeads: selecionados,
            });
            if (!response.data.success) {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              return;
            }
            toast.success("Sucesso ao desativar!", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            context.updateState();
            fetchData();
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  return (
    <>
      <ModalAddEditLead
        modalvisible={modalvisible}
        setmodalvisible={setmodalvisible}
        listLead={listLead}
        setListLead= {setListLead}
        fetchData={fetchData}
        context={context}
      />
      <div id="leads">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop
                texto="Captura de Leads"
                title="leads"
                context={context}
              />
              <div className="area_body">
                <div className="row">
                  <div className="col-sm-12 col-md-2 mt-2 mb-1">
                    <button id="nova-captura"
                      className="btn-main w-100"
                      onClick={() => setmodalvisible(true)}
                    >
                      Nova lista
                    </button>
                  </div>
                  <div className="col-sm-12 col-md-10 mt-2 mb-1">
                    <button
                      id="remove-captura"
                      className="btn-main float-right"
                      onClick={() => excluir()}
                    >
                      Remover Selecionados
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 mt-2 mb-1 ">
                    <div id="list-captura" className="card-content table-responsive">
                      <table className="table table-hover" id="lista">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col">NOME</th>
                            <th scope="col">URL</th>
                            <th scope="col">LEADS</th>
                            <th scope="col">OPÇÃO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lista.length > 0 && lista.map(function (el, i) {
                            return (
                              <tr
                                onDoubleClick={(e) =>
                                  selecionadosControl(el._id, e)
                                }
                                key={i}
                              >
                                <td className="pointerTable">
                                  <div className="float-right">
                                    <input
                                      type="checkbox"
                                      checked={
                                        selecionados.indexOf(el._id) > -1
                                      }
                                      onChange={(e) =>
                                        selecionadosControl(el._id, e)
                                      }
                                      className="form-check-input"
                                    />
                                  </div>
                                </td>
                                <td className="pointerTable">
                                  {el.nome ? el.nome : "Sem nome"}
                                </td>
                                <td className="pointerTable">
                                  <div className="row">
                                    <div className="col-sm-12 col-md-10">
                                      <input
                                        type="text"
                                        disabled
                                        value={el.link ?`https://b4c.site/z/${el.link.id}` : ""}
                                        className="input-form"
                                      />
                                    </div>
                                    <div className="col-sm-12 col-md-2">
                                      <button
                                        type="button"
                                        className="float-right btn btn-light mb-2 w-100"
                                        onClick={(e) => {
                                          navigator.clipboard.writeText(
                                            `https://b4c.site/z/${el.link.id}`
                                          );
                                          e.target.focus();
                                          toast.success("Copiado!", {
                                            position:
                                              toast.POSITION.BOTTOM_LEFT,
                                          });
                                        }}
                                      >
                                        copy
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td className="pointerTable mt-3">
                                 {el.qtdLead > 0 ? (<Link className='btn btn-primary' to={`/leads/list/${el._id}`}>{`${el.qtdLead} LEADS`}</Link>) : "0 LEADS"}          
                                </td>
                                <td
                                  className='detalhes'
                                  onClick={() => {
                                    setListLead(el);
                                    setmodalvisible(true);
                                  }}
                                >
                                  Editar
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {loading && <Skeleton count={10} />}
                      {lista.length > 0 && (
                        <ReactPaginate
                          previousLabel="<"
                          nextLabel=">"
                          breakLabel="..."
                          breakClassName="page-item disabled"
                          breakLinkClassName="page-link"
                          disabledClassName="disabled"
                          pageCount={pageCount}
                          pageClassName="page-item"
                          previousClassName="page-item"
                          pageLinkClassName="page-link"
                          initialPage={parseInt(query.get("page"))}
                          previousLinkClassName="page-link"
                          nextLinkClassName="page-link"
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={4}
                          onPageChange={fetchPagination}
                          containerClassName="pagination"
                          subContainerClassName="pages"
                          activeClassName="active"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
