/* eslint-disable no-sequences */
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-responsive-modal";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import { GlobalState } from "../../state/state";
import * as Yup from "yup";
import { Form } from "@unform/web";
import Input from "../../components/Input";
import InputMask from "../../components/InputMaks";
import api from "../../service/api";

export default function ModalAddEditContatos({
  modalVisibleAddEdit,
  contato,
  closeModal,
  context,
}) {
  const formRef = useRef(null);
  const [zaps, setzaps] = useState([]);
  const [zap, setzap] = GlobalState("zap");
  const [tags, settags] = useState([]);
  const [outrosTel, setoutrosTel] = useState([]);
  const [emails, setemails] = useState([{ value:"", index: 0}]);
  const [tagslist, settagslist] = useState([]);

  async function handleSalvar(data) {
    formRef.current.setErrors([]);
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required("Nome é obrigatório"),
        telefone: Yup.string().required("Telefone é obrigatório"),
      });
      await schema.validate(data, { abortEarly: false });

      if (!zap) {
        toast.error("Selecione um número", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }

      data.zap = zap._id;

      if (tags && tags.length > 0) data.tags = tags.map((el) => el.value);
      else data.tags = [];

      data.dataNascimento = moment(data.dataNascimento, "DD/MM/YYYY").toDate();
      if(outrosTel.length) data.outrosTel = outrosTel.map(el => el.value);
      if(emails.length) data.emails = emails.map(el => el.value);
      let response;
      if (contato) {
        data._id = contato._id;
        response = await api.put("/v1/contatos", data);
      } else {
        data.sincronizado = false;
        response = await api.post("/v1/contatos", data);
      }

      if (response.data.success) {
        toast.success("Contato salvo com sucesso!", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        fecharModal();
      } else {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function addOutroTel() {
    outrosTel.push({ value: "", index: outrosTel.length });
    setoutrosTel([...outrosTel]);
  }

  async function fetchData() {
    if (context && context.usuario && context.zaps) {
      const tags = context.usuario.tags.map((el, i) => {
        return (el = { value: el, label: el });
      });
      settagslist(tags);
      setzaps(context.zaps);
    }

    if (contato !== null) {
      setTimeout(function () {
        formRef.current.setData({
          nome: contato.nome,
          telefone: contato.telefone,
          empresa: contato.empresa,
          dataNascimento: contato.dataNascimento
            ? moment(contato.dataNascimento).format("DD/MM/YYYY")
            : "",
        });
      }, 300);
      if(contato.emails) setemails(contato.emails.map((el, i) => {return {value: el, index: i}}));
      if(contato.outrosTel) setoutrosTel(contato.outrosTel.map((el, i) => {return {value: el, index: i}}));
      settags(
        contato.tags.map((el, i) => {
          return { value: el, label: el };
        })
      );
    }
  }

  function fecharModal() {
    setoutrosTel([]);
    setemails([ {value: "", index: 0}]);
    closeModal();
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, contato]);

  return (
    <Modal
      open={modalVisibleAddEdit}
      onClose={fecharModal}
      styles={customStyles}
      center
    >
      <div className='container'>
        <div className='row'>
          <div className='col-9 col-md-9'>
            {contato && contato.telefone ? (
              <h3>Editar contato</h3>
            ) : (
              <h3>Adicionar novo contato</h3>
            )}
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-6'>
            <label>Número vinculado</label> <br />
            <label>
              {zap ? zap.telefone : "Sem número vinculado"}
            </label>
          </div>
          <div className='col-sm-12 col-md-6'>
            <label>Selecionar Zap</label>
            <Select
              placeholder='Selecione um número'
              value={zap}
              defaultValue={zap}
              onChange={(e) => setzap(e)}
              options={zaps}
            />
          </div>
        </div>
        <hr />
        <Form className='mt-3' ref={formRef} onSubmit={handleSalvar}>
          <div className='row'>
            <div className='col-sm-12 col-md-6 mt-2 mb-2'>
              <Input
                type='text'
                className='input-form'
                name='nome'
                placeholder='Digite o nome do contato'
                label='Nome do contato'
              />
            </div>
            <div className='col-sm-12 col-md-6 mt-2 mb-2'>
              <InputMask
                type='text'
                mask='99/99/9999'
                className='input-form'
                name='dataNascimento'
                placeholder='Digite a data de nascimento do contato'
                label='Data de Nascimento'
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-6 mt-2 mb-2'>
              <Input
                type='text'
                className='input-form'
                name='empresa'
                placeholder='Digite a empresa do contato'
                label='Empresa'
              />
            </div>
            <div className='col-sm-12 col-md-6 mt-2 mb-2'>
              <label>TAGS</label>
              <Select
                value={tags}
                placeholder='Selecione as tags'
                isMulti
                className='basic-multi-select'
                classNamePrefix='select'
                onChange={(tags) => settags(tags)}
                options={tagslist}
              />
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-sm-12 col-md-12 mt-2 mb-2'>
              <Input
                type='text'
                className='input-form'
                name='telefone'
                placeholder="ex: (nacional) 42999234180 ou (inter) +169743523434"
                label='Telefone (para números internacionais adicione o + codigo do país)'
              />
              {outrosTel &&
                outrosTel.length > 0 &&
                outrosTel.map((el, i) => {
                  return (
                    <input
                      key={i}
                      type='text'
                      placeholder="ex: (nacional) 42999234180 ou (inter) +169743523434"
                      className='input-form mt-1 mb-2'
                      value={el.value}
                      onChange={(e) => {
                        el.value = e.target.value;
                        setoutrosTel([...outrosTel]);
                      }}
                    />
                  );
                })}
              {outrosTel.length > 0 && (<label
                className='action float-left text-danger'
                role='button'
                onClick={() => {
                  outrosTel.pop();
                  setoutrosTel([...outrosTel]);
                } }
                >
                Remover telefone
                </label>)
              }
              <label
                className='action float-right'
                role='button'
                onClick={() => addOutroTel()}
              >
                Adicionar outro telefone
              </label>
            </div>
            <div className='col-sm-12 col-md-12 mt-2 mb-2'>
              <label>E-mail</label>
              {emails &&
                emails.map((el, i) => {
                  return (
                    <input
                      key={i}
                      type='email'
                      value={el.value}
                      className={i > 0 ? 'input-form mt-1 mb-2' : 'input-form mb-2' }
                      onChange={(e) => {
                        el.value = e.target.value;
                        setemails([...emails]);
                      }}
                    />
                  );
                })}
                {emails.length > 1 && (<label
                className='action float-left text-danger'
                role='button'
                onClick={() => {
                  emails.pop();
                  setemails([...emails]);
                } }
                >
                Remover e-mail
                </label>)
                }
                <label
                className='action float-right'
                role='button'
                onClick={() => {
                  emails.push({ value: "", index: emails.length});
                  setemails([...emails]);
                }}
                >
                Adicionar outro e-mail
                </label>
            </div>
          </div>
          <hr />
          <button type='submit' className='btn-main float-right'>
            Salvar
          </button>
        </Form>
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: "1%",
  },
};
