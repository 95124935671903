/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { GrClose } from "react-icons/gr";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import moment from "moment";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import api from "../../service/api";
import TextArea from "../../components/TextArea";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Input from "../../components/Input";
import InputMask from "../../components/InputMaks";
import CheckBox from "../../components/CheckBox";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { GlobalState } from "../../state/state";
import ModalTags from "./ModalTags";
import ModalAddContatos from "./ModalAddContatos";
import Preview from "../../components/Preview/index";

export default function AddEditCampanha({ context, match, history }) {
  const formRef = useRef(null);
  const scroll = useRef(null);

  const [zap, setzap] = GlobalState("zap");

  const [usuario, setusuario] = useState(null);
  const [id, setId] = useState("");
  const [busca, setbusca] = useState("");
  const [arquivo, setarquivo] = useState("");
  const [tags, settags] = useState("");
  const [msg, setmsg] = useState("");
  const [autoMsg, setAutoMsg] = useState("");
  const [autoFile, setAutoFile] = useState({});

  const [modalvisible, setmodalvisible] = useState(false);
  const [modalAddCvisible, setmodalAddCvisible] = useState(false);

  const [limit, setlimit] = useState(350);
  const [totalBusca, settotalBusca] = useState(0);

  const [viaBase, setviaBase] = useState(false);
  const [checkAutoMsg, setCheckAutoMsg] = useState(false);

  const [repetir, setrepetir] = useState(false);

  const [listcontatos, setlistcontatos] = useState([]);
  const [contatos, setcontatos] = useState([]);
  const [contatosCampanha, setcontatosCampanha] = useState([]);

  async function salvar(data) {
    formRef.current.setErrors([]);
    try {
      const schema = Yup.object().shape({
        nome: Yup.string().required("Nome é obrigatório"),
        dataDisparo: Yup.string().required("Data do disparo é obrigatório"),
        horaDisparo: Yup.string().required("Hora do disparo é obrigatório"),
        mensagem: Yup.string().required("Mensagem é obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!data.viaBase && contatosCampanha.length === 0) {
        toast.error("Selecione os contatos para envio", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        return;
      }

      const dataHoraDisparo = moment(
        `${data.dataDisparo} ${data.horaDisparo}`,
        "DD/MM/YYYY HH:mm"
      ).toDate();

      data.dataHoraDisparo = dataHoraDisparo;
      if (!data.viaBase) data.zap = zap._id;
      if((checkAutoMsg && autoFile) || (checkAutoMsg && autoMsg)){
        data.respostaAutomatica = {
          message: autoMsg,
          file: autoFile,
        }
      }
      data.enviaDomingo = !data.excetoDomingo;
      data.enviaSabado = !data.excetoSabado;
      data.msg = msg;
      data.ativo = true;
      data.contatos = contatosCampanha;
      data.filtroTags = tags ? tags.map((el) => el.value) : [];
      data.arquivo = arquivo;

      data._id = match.params.id;
      const response = await api.put(`/v1/campanhas`, data);

      if (!response.data.success) {
        toast.error(response.data.err, {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        return;
      }

      toast.success("Campanha salva com sucesso!", {
        position: toast.POSITION.BOTTOM_LEFT,
      });

      history.push("/campanhas");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          toast.error(error.message, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors([]);
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  async function fetchData() {
    if (context.usuario) setusuario(context.usuario);

    const response = await api.get(`/v1/campanhas/${match.params.id}`);
    setId(match.params.id);
    setrepetir(response.data.repetirAteFim);
    setmsg(response.data.msg);
    setviaBase(response.data.viaBase);
    setarquivo(response.data.arquivo);

    await getContatosCampanha();

    if (formRef.current && formRef.current.setData) {
      formRef.current.setData({
        nome: response.data.nome,
        mensagem: response.data.msg,
        repetirAteFim: response.data.repetirAteFim,
        excetoSabado: !response.data.enviaSabado,
        excetoDomingo: !response.data.enviaDomingo,
        viaBase: response.data.viaBase,
        dataDisparo: moment(response.data.dataHoraDisparo).format("DD/MM/YYYY"),
        horaDisparo: moment(response.data.dataHoraDisparo).format("HH:mm"),
        msg_return: response.data.msg_return,
      });
    }


    if((response.data.respostaAutomatica && response.data.respostaAutomatica.message) || (response.data.respostaAutomatica && response.data.respostaAutomatica.file && response.data.respostaAutomatica.file.filename) ){
      setCheckAutoMsg(true);
      setAutoMsg(response.data.respostaAutomatica.message);
      setAutoFile(response.data.respostaAutomatica.file && response.data.respostaAutomatica.file.filename ? response.data.respostaAutomatica.file : {});
    }

    if (response.data.filtroTags) {
      const tags = response.data.filtroTags.map((el, i) => {
        return (el = { value: el, label: el });
      });
      settags(tags);
    }

    if (response.data.zap) {
      response.data.zap.value = response.data.zap._id;
      response.data.zap.label = response.data.zap.telefone;
      setzap(response.data.zap);
    }
  }

  async function buscaContatos() {
    const responseList = await api.post(`/v1/contatos/list`, {
      campanha: true,
      busca,
      limit,
      tags: tags ? tags.map((el) => el.value) : [],
      zap: zap ? zap._id : null,
    });
    settotalBusca(responseList.data.total);
    setlistcontatos(responseList.data.data);
  }

  async function adicionarContatos() {
    if (!zap || !zap._id) {
      toast.error("Selecione um número para criar a campanha", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    const response = await api.post("/v1/campanhas/addContatos", {
      contatos,
      zap,
      mensagem: msg,
      campanha: id,
      arquivo,
    });

    if (response.data.success) {
      await getContatosCampanha();
    } else {
      toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  async function atualizarMsg() {
    const response = await api.post("/v1/campanhas/atualizarMSG", {
      mensagem: msg,
      campanha: id,
      arquivo,
    });
    if (response.data.success) {
      await getContatosCampanha();
    } else {
      toast.error(response.data.err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }

  async function removerMsg() {
    confirmAlert({
      title: "Remover Mensagens",
      message: `Remover mensagens e arquivos de todos os contatos da campanha ?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/campanhas/removerMsg", {
              campanha: id,
            });
            if (response.data.success) {
              await getContatosCampanha();
            } else {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function getContatosCampanha() {
    const responseContatos = await api.get(
      `/v1/campanhas/${match.params.id}/contatos`
    );
    setcontatosCampanha(responseContatos.data);
  }

  async function removerContato(id) {
    await api.delete(`/v1/campanhas/contato/${id}`);
    getContatosCampanha();
  }

  useEffect(() => {
    buscaContatos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, zap, busca, tags]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.usuario]);

  function selecionadosControl(el, e) {
    if (contatos.indexOf(el._id) > -1 && !e.target.checked)
      contatos.splice(contatos.indexOf(el._id), 1);
    else contatos.push(el._id);
    if (contatos.length > 149) {
      toast.error("Maximo 150 contatos", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    setcontatos([...contatos]);
  }

  useEffect(() => {
    if (id) {
      atualizarMsg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arquivo]);

  return (
    <>
      <ModalTags
        tags={tags}
        settags={settags}
        modalvisible={modalvisible}
        setmodalvisible={setmodalvisible}
        context={context}
      />
      <ModalAddContatos
        modalVisible={modalAddCvisible}
        setmodalVisible={setmodalAddCvisible}
        campanha={match.params.id}
        contatos={contatos}
        zap={zap}
        mensagem={msg}
        arquivo={arquivo}
        tags={tags}
        getContatosCampanha={getContatosCampanha}
      />
      <div id='campanhas'>
        <div className='container-fluid'>
          <div className='row mb-4'>
            <div className='col-sm-12 col-lg-2 mb-5'>
              <Menu className='positionSticky' context={context} />
            </div>
            <div className='col-sm-12 col-lg-10'>
              <AreaTop
                texto='Criar nova campanha'
                title='nova-campanha'
                context={context}
              />
              <Form ref={formRef} onSubmit={salvar}>
                <div className='row'>
                  <div className='col-sm-12 col-md-8'>
                    <div id='info-campanha' className='card-content h-full'>
                      <span>1 - INFORMAÇÕES GERAIS</span> <br /> <br />
                      <div className={!viaBase ? "mb-2" : "d-none"}>
                        <label>Número vinculado</label> <br />
                        <label>
                          {zap
                            ? zap.telefone
                            : "Sem número vinculado"}
                        </label>{" "}
                      </div>
                      <Input
                        type='text'
                        className='input-form mb-3'
                        name='nome'
                        placeholder='Dê um título para a campanha'
                        label='Título da campanhas'
                      />
                      <div className='row'>
                        <div className='col-sm-12 col-md-6'>
                          <InputMask
                            mask='99/99/9999'
                            className='input-form mb-3'
                            name='dataDisparo'
                            placeholder='01/01/2021'
                            label='Data de disparo'
                          />
                        </div>
                        <div className='col-sm-12 col-md-6'>
                          <InputMask
                            mask='99:99'
                            className='input-form mb-3'
                            name='horaDisparo'
                            placeholder='12:00'
                            label='Horário de disparo'
                          />
                        </div>
                      </div>
                      <TextArea
                        label='Mensagem'
                        value={msg}
                        onChange={(e) => setmsg(e.target.value)}
                        placeholder='Exemplo: Olá [nome] deseja aproveitar as novas ofertas da loja ?'
                        onBlur={atualizarMsg}
                        rows={5}
                        className='textarea-form mb-3'
                        name='mensagem'
                      />
                      <div className='form-check mt-1'>
                        <CheckBox
                          name='autoMessage'
                          className='form-check-input'
                          checked={checkAutoMsg}
                          onChange={() => setCheckAutoMsg(!checkAutoMsg)}
                          label='Resposta Automática'
                        />
                      </div>
                      <div className={checkAutoMsg ? "form-check mb-2" : "d-none"} >
                        <p>
                          Essa mensagem ou arquivo serão enviados após o contato(ou destinatario) dessa campanha responder.
                        </p>
                        <TextArea
                          value={autoMsg}
                          onChange={(e) => setAutoMsg(e.target.value)}
                          placeholder='Exemplo: Olá [nome] deseja aproveitar as novas ofertas da loja ?'
                          rows={2}
                          className='textarea-form mb-3'
                          name='mensagemAuto'
                        />
                        <Dropzone
                          onDrop={async (values) => {
                            let fd = new FormData();

                            fd.append("folder", "arquivosWhats");

                            values.map((file) => {
                              return fd.append("file", file);
                            });

                            const response = await api.post("/v1/upload", fd, {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            });
                            if (!response.data.success && !response.data.file) {
                              return toast.error(response.data.err, {
                                position: toast.POSITION.BOTTOM_LEFT,
                              });
                            }

                            setAutoFile(response.data.file);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section className='bordernone mt-3 mb-3'>
                              {autoFile && autoFile.mimetype  && (
                                <div className='arrasteAqui sucess-file text-center'>
                                  <GrClose
                                    className='float-right pr-2 pt-2 h4'
                                    onClick={() => setAutoFile({})}
                                  />
                                  {autoFile.mimetype === "image/jpeg" ||
                                  autoFile.mimetype === "image/png" ? (
                                    <img
                                      style={{ height: 200 }}
                                      alt='img'
                                      className='img-thumbnail rounded'
                                      src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${autoFile.mimetype}&filename=${autoFile.filename}&folder=arquivosWhats`}
                                    />
                                  ) : (
                                    <p>{autoFile.originalname}</p>
                                  )}
                                </div>
                              )}
                              {!autoFile.mimetype && (
                                <div
                                  className='bordernone mt-3 mb-3'
                                  {...getRootProps()}
                                >
                                  <input
                                    className='bordernone'
                                    {...getInputProps()}
                                  />
                                  <div className='arrasteAqui text-center'>
                                    <p>Arraste ou selecione arquivo</p>
                                  </div>
                                </div>
                              )}
                            </section>
                          )}
                        </Dropzone>
                      </div>
                      <div className={!viaBase ? "form-check mt-2" : "d-none"}>
                        <CheckBox
                          name='repetirAteFim'
                          className='form-check-input'
                          onChange={() => setrepetir(!repetir)}
                          label='Repetir diariamente até o fim'
                        />
                      </div>
                      <div
                        className={
                          repetir || viaBase ? "form-check mt-2" : "d-none"
                        }
                      >
                        <CheckBox
                          name='excetoSabado'
                          className='form-check-input'
                          label='Exceto aos Sábados'
                        />
                      </div>
                      <div
                        className={
                          repetir || viaBase ? "form-check mt-2" : "d-none"
                        }
                      >
                        <CheckBox
                          name='excetoDomingo'
                          className='form-check-input'
                          label='Exceto aos Domingos'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-4'>
                    <div id='file-campanha' className='card-content h-full'>
                      <span>2 - Enviar um anexo </span> <br />
                      <small>
                        Utilize essa opção quando precisar enviar uma imagem,
                        catalogo ou documento junto com a mensagem.
                      </small>
                      <br />
                      <Dropzone
                        onDrop={async (values) => {
                          let fd = new FormData();

                          fd.append("folder", "arquivosWhats");

                          values.map((file) => {
                            return fd.append("file", file);
                          });

                          const response = await api.post("/v1/upload", fd, {
                            headers: { "Content-Type": "multipart/form-data" },
                          });
                          if (!response.data.success && !response.data.file) {
                            return toast.error(response.data.err, {
                              position: toast.POSITION.BOTTOM_LEFT,
                            });
                          }

                          setarquivo(response.data.file);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section className='bordernone mt-3 mb-3'>
                            {arquivo && (
                              <div className='arrasteAqui sucess-file text-center'>
                                <GrClose
                                  className='float-right pr-2 pt-2 h4'
                                  onClick={() => setarquivo(null)}
                                />
                                {arquivo.mimetype === "image/jpeg" ||
                                arquivo.mimetype === "image/png" ? (
                                  <img
                                    style={{ height: 200 }}
                                    alt='img'
                                    className='img-thumbnail rounded'
                                    src={`${process.env.REACT_APP_API_URL}/upload?mimetype=${arquivo.mimetype}&filename=${arquivo.filename}&folder=arquivosWhats`}
                                  />
                                ) : (
                                  <p>{arquivo.originalname}</p>
                                )}
                              </div>
                            )}
                            {!arquivo && (
                              <div
                                className='bordernone mt-3 mb-3'
                                {...getRootProps()}
                              >
                                <input
                                  className='bordernone'
                                  {...getInputProps()}
                                />
                                <div className='arrasteAqui text-center'>
                                  <p>Arraste ou selecione arquivo</p>
                                </div>
                              </div>
                            )}
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-sm-12 col-md-12'>
                    <div className='row'>
                      <div className='col-sm-12 col-md-5'>
                        <div
                          id='contatos-campanha'
                          className='card-content h-full'
                        >
                          <span>3 - CONTATOS </span> <br /> <br />
                          <div className='form-check'>
                            <CheckBox
                              name='viaBase'
                              className='form-check-input'
                              onChange={() => setviaBase(!viaBase)}
                              label='Selecionar contatos automaticamente via Banco de Contatos'
                            />
                          </div>
                          <div className={!viaBase ? "" : "d-none"}>
                            <label>
                              {usuario && usuario.limitarCampanha
                                ? "Máximo de 150 contatos por campanha"
                                : "Campanha com contatos ilimitados"}
                            </label>{" "}
                            <br />
                            <div className='row mb-2'>
                              <div className='col-sm-12 col-md-9'>
                                <input
                                  className='inputPadrao'
                                  value={busca}
                                  onChange={(e) => setbusca(e.target.value)}
                                  placeholder='Pesquise um nome ou número'
                                />
                              </div>
                              <div className='col-sm-12 col-md-3 tagsresponsive'>
                                <button
                                  type='button'
                                  className='btn-secondary w-100 tagsresponsive'
                                  onClick={() => setmodalvisible(true)}
                                >
                                  Selecionar Tags
                                </button>
                              </div>
                            </div>
                            {(busca || (tags && tags.length > 0)) && (
                              <>
                                <small>
                                  Filtrando por {busca ? busca : ""}{" "}
                                  {tags && tags.length > 0 && (
                                    <>{tags.map((el) => `${el.label} `)}</>
                                  )}
                                </small>{" "}
                                <br />
                              </>
                            )}
                            <button
                              type='button'
                              className='btn btn-sm btn-info'
                              onClick={() => setmodalAddCvisible(true)}
                            >
                              Selecionar quantidade adicionar contatos
                            </button>
                            <div
                              className='list mt-1'
                              ref={scroll}
                              onScroll={() => {
                                if (
                                  scroll.current.scrollHeight -
                                    scroll.current.scrollTop <
                                    900 &&
                                  limit < totalBusca
                                ) {
                                  setlimit(limit + 150);
                                }
                              }}
                            >
                              {listcontatos.map((el, i) => {
                                return (
                                  <div key={i} className='form-check mt-1 mb-3'>
                                    <input
                                      className='form-check-input'
                                      checked={contatos.indexOf(el._id) > -1}
                                      onChange={(e) =>
                                        selecionadosControl(el, e)
                                      }
                                      type='checkbox'
                                      id={`check-contatos-${i}`}
                                    />
                                    <label
                                      className='form-check-label naoSelecionavel ml-2'
                                      htmlFor={`check-contatos-${i}`}
                                    >
                                      {el.seq} -{" "}
                                      {el.telefone
                                        ? el.telefone
                                        : "Sem telefone"}{" "}
                                      <small>{el.nome}</small>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                            <div className='space-final'>
                              <button
                                type='button'
                                className='btn-main w-100'
                                onClick={() => adicionarContatos()}
                              >
                                Adicionar{" "}
                                {contatos.length > 0 ? contatos.length : ""}{" "}
                                contatos selecionados
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id='previa-campanha' className='col-sm-12 col-md-7'>
                        <Preview
                          preview={contatosCampanha}
                          file={true}
                          remover={removerContato}
                          removerMsg={removerMsg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                  <div className='col-sm-12 col-md-12'>
                    <div className='float-right'>
                      <button
                        id='salvar-campanha'
                        type='submit'
                        className='btn-main mt-2'
                      >
                        Salvar Campanha
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
