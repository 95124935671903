/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiOutlineCloudDownload,
} from "react-icons/ai";
import {IoDocumentAttachOutline} from "react-icons/io5";
import { GiPadlock } from "react-icons/gi";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaSpinner } from "react-icons/fa";
import Menu from "../../components/Menu/index";
import AreaTop from "../../components/AreaTop/index";
import { toast } from "react-toastify";
import moment from "moment";
import api from "../../service/api";
import "./style.css";

export default function Resultado({ context, match, history }) {
  const [campanha, setcampanha] = useState([]);
  const [contatos, setcontatos] = useState([]);
  const [enviadosSucesso, setenviadosSucesso] = useState(0);
  const [enviadoFalha, setenviadoFalha] = useState(0);
  const [enviadosReais, setenviadosReais] = useState(0);
  const [total, settotal] = useState(0);

  async function reenviarCampanha(falhas) {
    confirmAlert({
      title: "Reenviar Campanha",
      message: `Reenviar ${
        falhas ? "apenas falhas dos contatos da" : "todo os contatos da"
      } Campanha`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/campanhas/reenviarCampanha", {
              campanha: match.params.id,
              falhas,
            });
            if (response.data.success) {
              toast.success("Sucesso ao renviar campanha para fila", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              fetchData();
            } else {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function dessincronizarContatos(){
    confirmAlert({
      title: "Dessincronizar",
      message: "Dessincronizar todos contatos da Campanha ?",
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            const response = await api.post("/v1/campanhas/dessincronizarContatos", {
              campanha: match.params.id,
            });
            if (response.data.success) {
              toast.success("Sucesso ao dessincronizar contatos", {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              fetchData();
            } else {
              toast.error(response.data.err, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
            }
          },
        },
        {
          label: "Não",
        },
      ],
    });
  }

  async function fetchData() {
    if (match.params.id) {
      const response = await api.get(`/v1/campanhas/${match.params.id}`);
      const responseContatos = await api.get(
        `/v1/campanhas/${match.params.id}/contatos`
      );

      settotal(response.data.total);

      setenviadosSucesso(response.data.totalEnviado);
      setenviadoFalha(response.data.totalFalhas);
      setenviadosReais(response.data.totalReal);

      setcampanha(response.data);
      setcontatos(responseContatos.data);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id]);

  return (
    <>
      <div id="campanhas">
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-sm-12 col-lg-2 mb-5">
              <Menu context={context} />
            </div>
            <div className="col-sm-12 col-lg-10">
              <AreaTop
                texto={`${
                  campanha && campanha.nome
                    ? `Resultado: ${campanha.nome}`
                    : "Resultado"
                }`}
                context={context}
              />
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="card-content h-full">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <label>
                          Data disparo:{" "}
                          {campanha && campanha.dataHoraDisparo
                            ? moment(campanha.dataHoraDisparo).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : ""}
                        </label>

                        {campanha.msg && (
                          <>
                            <br />
                            <label>{campanha.msg}</label>
                          </>
                        )}
                        {campanha.arquivo && campanha.arquivo.mimetype && (
                          <>
                            <br />
                            <a
                              href={`${process.env.REACT_APP_API_URL}/upload?mimetype=${campanha.arquivo.mimetype}&filename=${campanha.arquivo.filename}&folder=${campanha.arquivo.folder ? campanha.arquivo.folder : 'arquivosWhats'}`}
                              target="_blank"
                              alt="file"
                            >
                              <AiOutlineCloudDownload
                                className="mr-1"
                                size={18}
                              />
                              Arquivo
                            </a>
                          </>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <label className="float-right">
                          <label className="text-success">
                            {enviadosSucesso} Enviados
                          </label>
                          ,{" "}
                          <label className="text-danger">
                            {enviadoFalha} Falha ao enviar
                          </label>
                          ,{" "}
                          <label className="text-info">
                            {enviadosReais} Contatos reais
                          </label>
                          , do total de {total} contatos
                        </label>
                        <br />
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-2">
                      <div className="col-sm-12 col-md-12 table-responsive">
                        <table className="table table-hover" id="lista">
                          <thead>
                            <tr>
                              <th scope="col">CONTATO</th>
                              <th scope="col">HORÁRIO ENVIO</th>
                              <th scope="col">HORÁRIO FILA</th>
                              <th scope="col">HORÁRIO ENVIADO</th>
                              <th scope="col">NÚMERO EXISTE</th>
                              <th scope="col">ENVIADO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {contatos.map(
                              (el) =>
                                el.contato && (
                                  <tr key={el._id}>
                                    <td
                                      className="pointerTable"
                                      title={el.mensagem}
                                    >
                                       
                                      {el.contato.seq} -{" "}
                                      {el.contato && el.contato.telefone}{" "}
                                      -{" "}
                                      {el.mensagem
                                        ? `${el.mensagem.substr(0, 50)}${
                                            el.mensagem.length > 50 ? "..." : ""
                                          }`
                                        : ""}
                                      {el.arquivo && el.arquivo.mimetype ? (
                                        <>
                                        arquivo
                                        <span style={{position: 'relative', top: '3px'}} className="click-icon" onClick={() => window.open(`${process.env.REACT_APP_API_URL}/upload?mimetype=${el.arquivo.mimetype}&filename=${el.arquivo.filename}&folder=${campanha.arquivo && campanha.arquivo.folder ? campanha.arquivo.folder : 'arquivosWhats'}`)}>
                                        <IoDocumentAttachOutline className="pt-1" size="30" />
                                        </span>
                                        </>
                                       ) : ""}
                                    </td>
                                    <td className="pointerTable">
                                      {el.dataHoraDisparo
                                        ? moment(el.dataHoraDisparo).format(
                                            "DD/MM/YYYY HH:mm:ss"
                                          )
                                        : ""}
                                    </td>
                                    {!el.bloqueado ? (
                                      <td className="pointerTable">
                                        {el.dataFila
                                          ? moment(el.dataFila).format(
                                              "DD/MM/YYYY HH:mm:ss"
                                            )
                                          : ""}
                                      </td>
                                    ) : (
                                      <td className="pointerTable text-danger">
                                        BLOQUEADO
                                      </td>
                                    )}
                                    <td className="pointerTable">
                                      {el.data
                                        ? moment(el.data).format(
                                            "DD/MM/YYYY HH:mm"
                                          )
                                        : ""}
                                    </td>
                                    <td>
                                      {!el.data && !el.bloqueado && (
                                        <FaSpinner
                                          className="text-primary"
                                          size={20}
                                        />
                                      )}
                                      {el.data && !el.bloqueado && (
                                        <>
                                          {el.temzap ? (
                                            <AiFillCheckCircle
                                              className="text-success"
                                              size={20}
                                            />
                                          ) : (
                                            <AiFillCloseCircle
                                              className="text-danger"
                                              size={20}
                                            />
                                          )}
                                        </>
                                      )}
                                      {el.bloqueado && (
                                        <GiPadlock
                                          className="text-danger"
                                          size={20}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {!el.data && !el.bloqueado && (
                                        <FaSpinner
                                          className="text-primary"
                                          size={20}
                                        />
                                      )}
                                      {el.data && !el.bloqueado && (
                                        <>
                                          {el.enviou ? (
                                            <AiFillCheckCircle
                                              className="text-success"
                                              size={20}
                                            />
                                          ) : (
                                            <AiFillCloseCircle
                                              className="text-danger"
                                              size={20}
                                            />
                                          )}
                                        </>
                                      )}
                                      {el.bloqueado && (
                                        <GiPadlock
                                          className="text-danger"
                                          size={20}
                                        />
                                      )}
                                    </td>
                                  </tr>
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 col-md-12">
                  <button
                    className="btn btn-danger float-right"
                    onClick={() => dessincronizarContatos()}
                  >
                    Marcar contatos como não sincronizados
                  </button>

                  <div className="float-right ">
                      <button
                        className="btn btn-warning mr-2 mb-1"
                        onClick={() => reenviarCampanha(true)}
                      >
                        Reenviar Apenas Falhas
                      </button>
                      <button
                        className="btn btn-danger mb-1"
                        onClick={() => reenviarCampanha(false)}
                      >
                        Reenviar Campanha
                      </button>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
