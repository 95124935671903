import React, {useEffect, useState} from 'react';
import {Modal} from 'react-responsive-modal';
import Select from 'react-select';
import 'react-responsive-modal/styles.css';
import './style.css';

export default function ModalTags({
  modalvisible,
  setmodalvisible,
  tags,
  settags,
  context,
}) {
  const [tagslist, settagslist] = useState([]);

  async function fetchData() {
    if (context.usuario && context.usuario.tags) {
      const tags = context.usuario.tags.map((el, i) => {
        return el = {value: el, label: el};
      });
      settagslist(tags);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  function closeModal() {
    setmodalvisible(false);
  }

  return (
    <Modal
      open={modalvisible}
      onClose={closeModal}
      styles={customStyles}
      center
    >
      <div id="config">
        <div className="mb-3">
          <h3 className="colorBackground mb-3">Selecionar Tags</h3>
        </div>
        <div className="container-fluid mb-3 mt-4">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <Select
                value={tags}
                placeholder="Selecione as tags"
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(tags) => settags(tags)}
                options={tagslist}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    </Modal>
  );
}

const customStyles = {
  modal: {
    borderRadius: '1%',
    minWidth: '100px',
    width: '500px',
  },
};
